<template>
  <FieldItemTypeTelephone v-slot="{ label }" v-bind="phone" class="button">
    <SpriteSymbol name="phone" />
    {{ label }}
  </FieldItemTypeTelephone>
</template>

<script lang="ts" setup>
import type { ParagraphPhoneNumberFragment } from '#build/graphql-operations'

defineBlokkli({
  bundle: 'phone_number',
  editor: {
    editTitle: (el) => el.textContent,
  },
})

defineProps<{
  phone?: ParagraphPhoneNumberFragment['phone']
}>()
</script>
