export function buildEmbedUrl(url: string) {
  const matches = url.match(/\/(\d+)/)
  const id = matches?.[1] || ''
  if (!id) {
    return
  }
  const params = new URLSearchParams({
    byline: 'false',
    portrait: 'false',
    title: 'false',
    fun: 'false',
    dnt: 'true',
  })

  return `https://player.vimeo.com/video/${id}?${params.toString()}`
}
