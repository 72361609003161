<template>
  <div class="col-start-1 col-end-[-1]">
    <MediaVideo v-bind="video" :autoplay="!isEditing" />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphVideoFragment } from '#graphql-operations'

const { isEditing } = defineBlokkli({
  bundle: 'video',
})

defineProps<{
  video?: ParagraphVideoFragment['video']
}>()
</script>
