<template>
  <div class="hypo-sustainability mt-15">
    <div class="hypo-sustainability-inner">
      <div :style="style" class="hypo-sustainability-dot">
        <span>{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatInterest } from '~/helpers/numbers'

const props = defineProps<{
  value: number
}>()

const label = computed(() => formatInterest(props.value))

const style = computed(() => {
  const x = Math.min(100, Math.max(0, props.value))

  return {
    marginLeft: x + '%',
  }
})

defineOptions({
  name: 'MortgageCalculatorSustainability',
})
</script>

<style lang="postcss">
.hypo-sustainability {
  height: 1rem;
  width: 100%;
  background: linear-gradient(
    to right,
    #81c149 0%,
    #b6d547 35%,
    #ffdd3c 50%,
    #ff3c00 100%
  );
  border-radius: 1rem;
  padding: 2px;
  margin-bottom: 3.5rem;
}

.hypo-sustainability-inner {
  position: relative;
  height: 100%;
  width: calc(100% - 12px);
}

.hypo-sustainability-dot {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: white;
  left: 0;
  top: 0;

  span {
    @apply bg-gray-100 text-sm font-bold;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.25rem 1rem;
    white-space: nowrap;
    margin-top: 1rem;
    width: 5rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent theme('colors.gray.100') transparent;
    }
  }
}
</style>
