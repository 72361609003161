<template>
  <div
    itemscope
    itemtype="https://schema.org/Person"
    :class="{
      'bg-white md:flex md:flex-col': isBoxed,
    }"
  >
    <MediaImage
      v-if="image"
      v-bind="image"
      :image-style="imageStyle"
      class="w-128 float-right md:float-none md:w-full"
      :class="{
        'mb-16': !isBoxed,
      }"
    />
    <div :class="{ 'p-16 md:flex md:flex-col flex-1': isBoxed }">
      <h3 itemprop="name" class="typo-h3">
        {{ firstName }}
        {{ lastName }}
      </h3>
      <div
        v-if="details?.length"
        itemprop="jobTitle"
        class="typo-lead-3 text-gray-500 min-h-56"
      >
        <div v-for="(detail, index) in details" :key="index">
          {{ detail }}
        </div>
      </div>
      <div class="grid mt-24 mb-auto">
        <div>
          <FieldItemTypeTelephone
            v-slot="{ label }"
            v-bind="phone"
            class="link"
          >
            <SpriteSymbol name="phone" />
            <span>{{ label }}</span>
          </FieldItemTypeTelephone>
        </div>
        <div>
          <a
            v-if="emailLink"
            itemprop="email"
            :href="emailLink"
            class="link is-grey"
            rel="noopener"
          >
            <SpriteSymbol name="email" />
            <span>{{ email }}</span>
          </a>
        </div>
      </div>

      <slot>
        <NuxtLink
          v-if="consultationLink"
          :to="consultationLink"
          class="button is-dark w-full mt-28 print:hidden"
        >
          <SpriteSymbol name="contactperson" />
          <span>Beratungstermin vereinbaren</span>
        </NuxtLink>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NodePersonTeaserFragment } from '#graphql-operations'

const props = defineProps<{
  nid?: string
  firstName?: string
  lastName?: string
  url?: NodePersonTeaserFragment['url']
  email?: string
  phone?: NodePersonTeaserFragment['phone']
  details?: string[]
  image?: NodePersonTeaserFragment['image']
  showConsultancy?: boolean
  isBoxed?: boolean
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    sm: 416,
  },
})

const emailLink = computed(() => (props.email ? `mailto:${props.email}` : null))

const consultationLink = computed(() => {
  if (props.showConsultancy) {
    return {
      name: 'wizard-consultation-form',
      params: {
        id: props.nid,
      },
    }
  }
  return undefined
})
</script>
