<template>
  <section
    class="layout-grid section-padding"
    :class="{ 'border-b border-b-gray-200': border }"
  >
    <header
      v-if="title"
      class="col-start-2 col-end-[-2] md:col-end-8 section-title"
    >
      <div class="flex relative items-center gap-8">
        <h2 class="typo-h2" v-html="$sup(title)" />
        <slot name="title-suffix" />
      </div>

      <p v-if="lead" class="typo-lead-2 text-gray-400" v-html="$sup(lead)" />
    </header>

    <div class="col-start-2 col-end-[-2]">
      <slot />
    </div>
  </section>
</template>

<script lang="ts" setup>
defineProps<{
  title?: string
  lead?: string
  border?: boolean
}>()

const { $sup } = useNuxtApp()
</script>
