<template>
  <div class="flex-1 bg-gray-200 p-10 text-black">
    <BlokkliEditable
      v-if="title"
      v-slot="{ value }"
      :value="title"
      name="field_title"
      class="font-bold"
    >
      <div v-html="$sup(value)" />
    </BlokkliEditable>
    <BlokkliEditable
      v-if="subtitle"
      v-slot="{ value }"
      :value="subtitle"
      name="field_subtitle"
    >
      <div v-html="$sup(value)" />
    </BlokkliEditable>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphSlideFragment } from '#graphql-operations'

defineProps<{
  image?: ParagraphSlideFragment['image']
  title?: string
  subtitle?: string
  link?: ParagraphSlideFragment['link']
}>()

defineBlokkli({
  bundle: 'slide',
  options: {
    inverted: {
      type: 'checkbox',
      label: 'Invertiert',
      default: false,
    },
    hasGradient: {
      type: 'checkbox',
      label: 'Verlauf anzeigen',
      default: false,
    },
    gradientColor: {
      type: 'color',
      label: 'Verlauffarbe',
      default: '#000000',
    },
  },
  editor: {
    determineVisibleOptions(ctx) {
      if (ctx.options.hasGradient) {
        return ['inverted', 'hasGradient', 'gradientColor']
      }

      return ['inverted', 'hasGradient']
    },
  },
})

defineOptions({
  name: 'ParagraphSlide',
})

const { $sup } = useNuxtApp()
</script>
