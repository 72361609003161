<template>
  <div class="layout-grid py-32 border-b border-b-gray-200">
    <div class="col-start-2 col-end-[-2]">
      <Quicksearch :persist-key="uuid" mobile-inline />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { uuid } = defineBlokkliFragment({
  name: 'quicksearch',
  label: 'Quicksearch',
  description: 'Zeigt die Quicksearch an.',
  editor: {
    noPreview: true,
  },
})
</script>
