<template>
  <div>
    <button
      :id="id"
      ref="elButton"
      class="flex gap-8 md:gap-16 items-center text-left w-full group relative z-20 focusable scroll-mt-32"
      @click.prevent="isOpen = !isOpen"
    >
      <div
        class="w-16 md:w-24 aspect-square rounded-full flex items-center justify-center shrink-0"
        :class="
          isRenderedOpen ? 'bg-gray-700' : 'bg-gray-200 group-hover:bg-gray-300'
        "
      >
        <SpriteSymbol
          name="plus"
          class="size-2/3 transition-transform duration-300 group-hover:"
          :class="[
            { 'rotate-45': isRenderedOpen },
            isRenderedOpen ? 'text-white' : 'text-gray-900',
          ]"
        />
      </div>
      <div
        v-blokkli-editable:field_title
        class="font-bold text-base md:text-xl text-gray-600 group-hover:text-black"
        :class="{ '!text-black': isRenderedOpen }"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </div>
    </button>

    <VuepalTransitionHeight opacity :duration="300">
      <div v-show="isRenderedOpen" class="relative pl-24 md:pl-40 print:!block">
        <div class="pb-24 pt-12">
          <slot />
        </div>
      </div>
    </VuepalTransitionHeight>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  id?: string
  title?: string
  alwaysOpen?: boolean
}>()

const route = useRoute()
const elButton = ref<HTMLButtonElement>()

const isOpen = ref(false)

const isRenderedOpen = computed(() => isOpen.value || props.alwaysOpen)

function shouldBeOpen() {
  return props.id && route.hash && route.hash === '#' + props.id
}

const activeHash = computed(() => route.hash.replace('#', ''))

watch(activeHash, function () {
  if (shouldBeOpen()) {
    isOpen.value = true
  }
})

onMounted(() => {
  if (shouldBeOpen()) {
    isOpen.value = true

    if (elButton.value) {
      elButton.value.scrollIntoView({
        block: 'start',
      })
    }
  }
})

defineOptions({
  name: 'Accordion',
})
</script>
