<template>
  <div
    :id="options.anchorId"
    class="col-start-1 col-end-[-1] layout-grid"
    :class="classes"
  >
    <div class="col-start-2 col-end-[-2] md:col-end-9">
      <h2 class="typo-h2" :class="textClass" v-html="$sup(title)" />
      <p class="typo-lead-2" :class="textMutedClass" v-html="$sup(lead)" />
      <ul v-if="included" class="grid grid-cols-2 md:grid-cols-3 gap-20 mt-24">
        <li
          v-for="(item, i) in included"
          :key="i"
          class="flex gap-8 items-center"
        >
          <div
            class="bg-white rounded-full flex items-center justify-center w-40 xl:w-50 shrink-0 aspect-square"
          >
            <TaxonomyTermIcons
              v-bind="item.icon"
              class="w-[70%] aspect-square xl:w-3/5"
            />
          </div>
          <p class="font-bold xl:text-lg" v-html="$sup(item.label)" />
        </li>
      </ul>
      <VuepalLink :to="product?.url?.path" class="button is-primary mt-28">
        Mehr erfahren
      </VuepalLink>
    </div>
    <div
      v-if="product"
      class="col-start-2 md:col-start-10 col-end-[-2] mt-28 md:mt-0"
    >
      <TeaserProduct
        v-if="product.title"
        :title="product.title"
        :subtitle="product.price"
        :text="product.features"
        :url="product.url?.path"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type { ParagraphProductBundleNodeFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'product_bundle',
  globalOptions: ['sectionBackground', 'anchorId'],
  editor: {
    editTitle: (el) => el.textContent,
  },
})

const props = defineProps<{
  title?: string
  lead?: string
  product?: ParagraphProductBundleNodeFragment
}>()

const { classes, textClass, textMutedClass } = useParagraphContext(options)
const { $sup } = useNuxtApp()

const included = computed(() =>
  props.product?.contents
    ?.map((v) => {
      if (v.icon && v.label) {
        return {
          label: v.label,
          icon: v.icon,
        }
      }
      return null
    })
    .filter(notNullish),
)
</script>
