<template>
  <div :class="classes" class="layout-grid col-start-1 col-end-[-1]">
    <BlokkliField
      :list="paragraphsHeader"
      name="field_paragraphs_header"
      list-class="col-start-2 col-end-[-2] section-title"
    />
    <div ref="gallery" class="col-start-2 col-end-[-2]">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="grid gap-24 grid-cols-1 md:grid-cols-2"
        :class="{ 'lg:grid-cols-3': options.columns === 'three' }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Lightbox from 'photoswipe/lightbox'
import type { ParagraphGridFragment } from '#graphql-operations'
import 'photoswipe/dist/photoswipe.css'
import { buildEmbedUrl } from '~/helpers/vimeo'

const gallery = ref<HTMLDivElement | null>(null)

defineProps<{
  paragraphsHeader?: ParagraphGridFragment['paragraphsHeader']
  paragraphs?: ParagraphGridFragment['paragraphs']
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'gallery',
  globalOptions: ['sectionBackground'],
  options: {
    columns: {
      type: 'radios',
      label: 'Spalten',
      default: 'two',
      displayAs: 'grid',
      options: {
        two: { label: 'Zwei', columns: [1, 1] },
        three: { label: 'Drei', columns: [1, 1, 1] },
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

const { classes } = useParagraphContext(options)

let lightbox: Lightbox | null = null

onMounted(() => {
  if (isEditing) {
    return
  }
  if (!gallery.value) {
    return
  }
  lightbox = new Lightbox({
    gallery: gallery.value,
    children: 'a',
    pswpModule: () => import('photoswipe'),
    showHideAnimationType: 'zoom',
  })
  // parse data-google-map-url attribute
  lightbox.addFilter('itemData', (itemData) => {
    const vimeoUrl = itemData.element?.getAttribute('href')
    if (vimeoUrl) {
      itemData.vimeoUrl = vimeoUrl
    }
    return itemData
  })

  // override slide content
  lightbox.on('contentLoad', (e) => {
    const { content } = e
    if (content.type === 'vimeo') {
      // prevent the deafult behavior
      e.preventDefault()

      // Create a container for iframe
      // and assign it to the `content.element` property
      content.element = document.createElement('div')
      content.element.className = 'pswp__vimeo'

      const iframe = document.createElement('iframe')
      iframe.setAttribute('allowfullscreen', '')
      iframe.src = buildEmbedUrl(content.data.vimeoUrl) || ''
      content.element.appendChild(iframe)
    }
  })
  lightbox.init()
})

onBeforeUnmount(() => {
  if (lightbox) {
    lightbox.destroy()
  }
})
</script>

<style lang="postcss">
.pswp__vimeo {
  @apply w-full;
  iframe {
    @apply w-full h-full object-contain;
  }
}
</style>
