<template>
  <div
    v-if="footnotes?.length || isEditing"
    class="not-last:layout-grid last:bg-gray-50 last:pt-24 not-last:my-section"
  >
    <Footnotes
      v-if="footnotes?.length"
      :footnotes="footnotes"
      class="col-start-2 col-end-[-2]"
    />
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  footnotes?: string[]
}>()

const { isEditing } = defineBlokkli({
  bundle: 'footnotes',
})
</script>
