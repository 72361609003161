<template>
  <section
    :id="id"
    class="layout-grid bg-white section-padding border-b border-b-gray-200 col-start-1 col-end-[-1]"
  >
    <div class="col-start-2 col-end-[-2]">
      <h2 class="text-xl lg:text-4xl font-bold section-title">
        {{ onlineService?.title }}
      </h2>
    </div>
    <div
      class="col-start-2 col-end-[-2] grid lg:grid-cols-2 gap-24 md:gap-y-36"
    >
      <TaxonomyTermOnlineService
        v-for="(service, i) in services"
        :key="i"
        v-bind="service"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphOnlineServicesFragment } from '#graphql-operations'

const props = defineProps<{
  onlineService?: ParagraphOnlineServicesFragment['onlineService']
}>()

defineBlokkli({
  bundle: 'online_services',
  editor: {
    editTitle: (el) => el.textContent,
  },
})

const services = computed(() => props.onlineService?.children || [])

const id = computed(() => slugify(props.onlineService?.title))
</script>
