<template>
  <Accordion
    v-if="options.display === 'accordion' && title"
    :id="id"
    :always-open="isEditing"
    :class="{
      'col-start-2 col-end-[-2] bg-white mb-16 paragraph-wrapper-transparent':
        !parentType,
      'mb-24 mt-16': parentType !== 'grid',
    }"
  >
    <template #title>
      <BlokkliEditable
        v-slot="{ value }"
        :value="title || ''"
        tag="div"
        name="field_title"
      >
        <p v-html="$sup(value)" />
      </BlokkliEditable>
    </template>
    <BlokkliField :list="paragraphs" name="field_paragraphs" />
  </Accordion>

  <div
    v-else-if="options.display === 'twoColumns' && title"
    :id="id"
    class="layout-grid col-start-1 col-end-[-1] mt-32"
  >
    <div class="col-start-1 col-end-5">
      <h3 v-if="title" class="typo-h3" v-html="$sup(title)" />
    </div>
    <BlokkliField
      :list="paragraphs"
      name="field_paragraphs"
      class="col-start-6 col-end-[-1]"
    />
  </div>

  <div v-else :id="id" class="col-start-2 col-end-[-2] flex gap-24 mt-32">
    <div class="shrink-0">
      <div
        class="aspect-square rounded-full flex items-center justify-center w-[1em]"
        :class="{
          'text-[64px]': options.iconSize === 'large',
          'text-[32px]': options.iconSize === 'normal',
          'bg-gray-200': parentBackground === 'white',
          'bg-white':
            parentBackground === 'grayLight' || parentBackground === 'grayDark',
          'bg-primary-900 text-primary-500': parentBackground === 'yellow',
        }"
      >
        <TaxonomyTermIcons
          v-if="icon && options.listicleStyle === 'icon'"
          v-bind="icon"
          class="size-1/2"
        />
        <div v-else class="font-bold text-[0.5em] listicle-counter" />
      </div>
    </div>
    <div>
      <div v-if="title">
        <h3 class="typo-h4" v-html="$sup(title)" />
      </div>
      <BlokkliField :list="paragraphs" name="field_paragraphs" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphListItemFragment } from '#graphql-operations'
import { slugify } from '#vuepal/helpers/url'

const { parentType, isEditing, options } = defineBlokkli({
  bundle: 'list_item',
  globalOptions: ['anchorId'],
  options: {
    display: {
      type: 'radios',
      label: 'Darstellung',
      default: 'accordion',
      options: {
        accordion: 'Akkordeon',
        twoColumns: '2 Spalten',
        listicle: 'Listicle',
      },
    },

    listicleStyle: {
      type: 'radios',
      label: 'Liste',
      default: 'icon',
      options: {
        number: 'Zahl',
        icon: 'Icon',
      },
    },

    iconSize: {
      type: 'radios',
      label: 'Icon Grösse',
      default: 'large',
      options: {
        normal: 'Normal',
        large: 'Gross',
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
    determineVisibleOptions: (ctx) => {
      if (ctx.options.display === 'listicle' || !ctx.props.title) {
        if (!ctx.props.title) {
          return ['iconSize', 'listicleStyle', 'anchorId']
        }
        return ['display', 'iconSize', 'listicleStyle', 'anchorId']
      }

      return ['display', 'anchorId']
    },
  },
})

defineProps<{
  title?: string
  paragraphs?: ParagraphListItemFragment['paragraphs']
  icon?: ParagraphListItemFragment['icon']
}>()

const { parentBackground } = useParagraphContext()
const { $sup } = useNuxtApp()

const id = computed(() => {
  if (options.value.anchorId) {
    return slugify(options.value.anchorId)
  }
  return ''
})
</script>

<style lang="postcss">
.listicle-counter {
  counter-increment: listicle-counter;
  &:before {
    content: counter(listicle-counter);
  }
}
</style>
