<template>
  <div
    :id="options.anchorId"
    class="paragraph-text text-pretty hyphens-auto lg:hyphens-none not-first:mt-16"
  >
    <BlokkliEditable
      v-slot="{ value }"
      name="field_body"
      :value="text"
      tag="div"
      :class="{
        'md:columns-2 md:gap-24': options.twoColumns,
      }"
    >
      <div
        :class="{
          'bg-primary-50 text-primary-800 p-16 border border-primary-300 flex gap-16':
            options.infoBox,
        }"
      >
        <SpriteSymbol
          v-if="options.infoBox"
          name="info"
          class="size-32 fill-primary-800 shrink-0"
        />
        <div
          class="ck-content md:text-lg leading-relaxed text-pretty"
          :class="{
            'text-gray-200': parentBackground === 'black',
          }"
          @click="onClick"
          v-html="value"
        />
      </div>
    </BlokkliEditable>
  </div>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{ text?: string }>(), {
  text: '',
})

const { options } = defineBlokkli({
  bundle: 'text',
  globalOptions: ['anchorId'],
  options: {
    twoColumns: {
      type: 'checkbox',
      label: 'Zwei Spalten',
      default: false,
    },

    infoBox: {
      type: 'checkbox',
      label: 'Als Box darstellen',
      default: false,
    },
  },
  editor: {
    addBehaviour: 'no-form',
    editTitle: (el) => el.textContent,
  },
})

const { parentBackground } = useParagraphContext()
const { onClick } = useClickTriggerProxy()
</script>
