<template>
  <BasicSection
    :title="$texts('mortgage_results_title', 'Kosten im Detail')"
    border
  >
    <div class="grid md:grid-cols-3 gap-24">
      <div class="field col-start-1 row-start-1">
        <label class="label" for="rate">{{
          $texts('mortgage_label_finance_variant', 'Finanzierungsvariante')
        }}</label>
        <div class="control">
          <div v-if="rateOptions.length" class="select-container">
            <select
              id="rate"
              class="input w-full"
              :value="selectedRate"
              @input="onRateSelect"
            >
              <option
                v-for="option in rateOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.label }}
              </option>
            </select>
            <SpriteSymbol name="arrow-down" />
          </div>
          <div v-else class="message is-warning is-small">
            <div class="message-body">
              {{
                $texts(
                  'mortgage_missing_rates',
                  'Zurzeit stehen keine aktuellen Zinssätze zur Verfügung.',
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <MortgageInput
        id="rateInput"
        v-model="rateInput"
        class="col-start-1 row-start-2"
        :min="rateMin"
        :max="rateMax"
        :step="0.01"
        size="text-base"
        :label="$texts('mortgage_interest_rate', 'Zinssatz')"
        :help="
          $texts(
            'mortgage_interest_rate_help',
            'Die Zinsen basieren auf dem aktuellen Richtsatz. Sie können den Zinssatz im Rechner anpassen. Gerne berechnen wir Ihre individuellen Hypothekarzinsen. Unsere Spezialistinnen und Spezialisten für Finanzierungen sind gerne für Sie da.',
          )
        "
        :error="rateInputError"
      />
      <div
        v-for="calculation in calculations"
        :key="calculation.id"
        class="relative"
      >
        <MortgageInfo
          :label="calculation.label"
          :for-id="calculation.id"
          :tooltip="calculation.tooltip"
        />
        <div class="text-2xl xl:text-4xl font-bold mt-10">
          {{ calculation.value }}
        </div>
      </div>
    </div>
  </BasicSection>

  <BasicSection
    :title="$texts('mortgage_tragbarkeit_title', 'Tragbarkeit')"
    border
  >
    <template #title-suffix>
      <ButtonTooltip
        id="tragbarkeit"
        :text="
          $texts(
            'mortgage_tragbarkeit_title_help',
            'Damit die Immobilie für Sie finanziell tragbar ist, sollte das Total aus Hypothekarzins, Amortisation sowie Neben und Unterhaltskosten rund 33% Ihres Nettoeinkommens betragen.',
          )
        "
      />
    </template>
    <div class="hypo-sustainability-text">
      <span>{{ sustainabilityLongtermText }}</span>
    </div>
    <MortgageSustainability :value="sustainabilityLongterm" />
  </BasicSection>

  <BasicSection
    :title="$texts('mortgage_calculation_details', 'Details zur Berechnung')"
  >
    <div class="grid md:grid-cols-3 gap-24">
      <div class="md:col-span-2">
        <div class="table-container w-full">
          <table class="w-full mortgage-results-table">
            <tbody>
              <tr>
                <th>
                  {{
                    $texts(
                      'mortgage_finanzierungsbedarf',
                      'Finanzierungsbedarf',
                    )
                  }}
                </th>
                <td colspan="3">
                  {{ formatCurrency(value.finanzierungsbedarf) }}
                </td>
              </tr>

              <tr>
                <th>
                  {{
                    $texts(
                      'mortgage_yearly_interest',
                      'Jährliche Hypothekarzinsen',
                    )
                  }}
                </th>
                <td>
                  {{ formatCurrency(value.finanzierungsbedarf) }}
                </td>
                <td>
                  {{ formatInterest(value.hypothekZinssatz) }}
                </td>
                <td>
                  {{ formatCurrency(value.hypothekTotalZinsenJahr) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3">
                  {{
                    $texts(
                      'mortgage_monthly_interest_label',
                      'Monatliche Hypothekarzinsen',
                    )
                  }}
                </th>
                <th>
                  {{ formatCurrency(value.hypothekTotalZinsenJahr / 12) }}
                </th>
              </tr>
            </tfoot>
          </table>
          <table class="table is-fullwidth mortgage-results-table">
            <tbody>
              <tr>
                <th>
                  {{
                    $texts(
                      'mortgage_yearly_amortisation',
                      'Jährliche Amortisation',
                    )
                  }}
                </th>
                <td>
                  {{ formatCurrency(value.amortisationBetrag) }}
                </td>
                <td>
                  {{ formatInterest(value.amortisationZinssatz) }}
                </td>
                <td>
                  {{ formatCurrency(value.amortisationZinsen) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3">
                  {{
                    $texts(
                      'mortgage_monthly_amortisation',
                      'Monatliche Amortisation',
                    )
                  }}
                </th>
                <th>
                  {{ formatCurrency(value.amortisationZinsen / 12) }}
                </th>
              </tr>
            </tfoot>
          </table>
          <table class="table is-fullwidth mortgage-results-table">
            <tbody>
              <tr>
                <th>
                  {{
                    $texts(
                      'mortgage_yearly_nebenkosten',
                      'Jährliche Nebenkosten',
                    )
                  }}
                </th>
                <td>
                  {{ formatCurrency(purchasePrice) }}
                </td>
                <td>
                  {{ formatInterest(value.nebenkostenZinssatz) }}
                </td>
                <td>
                  {{ formatCurrency(value.nebenkosten) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3">
                  {{
                    $texts(
                      'mortgage_monthly_nebenkosten',
                      'Monatliche Nebenkosten',
                    )
                  }}
                </th>
                <th>
                  {{ formatCurrency(value.nebenkosten / 12) }}
                </th>
              </tr>
            </tfoot>
          </table>
          <table class="table is-fullwidth mortgage-results-table">
            <tbody>
              <tr>
                <th colspan="3">
                  {{
                    $texts('mortgage_yearly_belastung', 'Jährliche Belastung')
                  }}
                </th>
                <td>
                  {{ formatCurrency(value.belastung) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3">
                  {{ $texts('mortgage_belastung', 'Monatliche Belastung') }}
                </th>
                <th>
                  {{ formatCurrency(value.belastung / 12) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="md:col-span-1">
        <div class="section-title">
          <h2 class="title typo-h2">
            {{
              $texts(
                'mortgage_calculation_description_title',
                'Berechnungsgrundlagen',
              )
            }}
          </h2>
        </div>

        <div
          class="content is-size-6"
          v-html="
            $texts(
              'mortgage_calculation_description_body',
              '<p>Als Faustregel gilt</p>',
            )
          "
        />
      </div>
    </div>
  </BasicSection>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue'
import { formatCurrency, formatInterest } from '~/helpers/numbers'
import MortgageInput from './../Input/index.vue'
import MortgageInfo from './../Info/index.vue'
import MortgageSustainability from './../Sustainability/index.vue'

interface MortgageUserInput {
  purchasePrice: number
  ownFunds: number
  income: number
}

function calculate(input: MortgageUserInput, zinssatz1: number) {
  const kaufpreis = input.purchasePrice
  const eigenmittel = input.ownFunds
  const einkommen = input.income

  const hypothek1Zinssatz = zinssatz1 || 0
  const amortisationZinssatz = 6.6
  const nebenkostenZinssatz = 0.8

  const finanzierungsbedarf = kaufpreis - eigenmittel
  const hypothek1Betrag = kaufpreis * 0.66
  const hypothek2Betrag =
    finanzierungsbedarf > hypothek1Betrag
      ? finanzierungsbedarf - hypothek1Betrag
      : 0
  const hypothekTotalZinsenJahr =
    finanzierungsbedarf * (hypothek1Zinssatz / 100)
  const nebenkosten = kaufpreis * (nebenkostenZinssatz / 100)

  // Amortisation
  const amortisationBetrag = hypothek2Betrag
  const amortisationZinsen = amortisationBetrag * (amortisationZinssatz / 100)

  const belastung = hypothekTotalZinsenJahr + amortisationZinsen + nebenkosten
  const tragbarkeit = (belastung * 100) / einkommen

  return {
    finanzierungsbedarf,
    hypothekTotalZinsenJahr,
    hypothekZinssatz: zinssatz1,
    belastung,
    tragbarkeit,
    nebenkostenBetrag: kaufpreis,
    nebenkosten,
    nebenkostenZinssatz,
    amortisationBetrag,
    amortisationZinsen,
    amortisationZinssatz,
  }
}

const FINANCE_VARIANT: Record<string, string> = {
  rate_2years: 'Fest-Hypothek 2 Jahre',
  rate_3years: 'Fest-Hypothek 3 Jahre',
  rate_4years: 'Fest-Hypothek 4 Jahre',
  rate_5years: 'Fest-Hypothek 5 Jahre',
  rate_6years: 'Fest-Hypothek 6 Jahre',
  rate_7years: 'Fest-Hypothek 7 Jahre',
  rate_8years: 'Fest-Hypothek 8 Jahre',
  rate_9years: 'Fest-Hypothek 9 Jahre',
  rate_10years: 'Fest-Hypothek 10 Jahre',
}

const MORTGAGE_DEFAULT_RATE = 1.2

const { $texts } = useEasyTexts()

const props = defineProps<{
  isValid: boolean
  purchasePrice: number
  ownFunds: number
  income: number
  rates: { key: string; value: number }[]
}>()

const validPurchasePrice = ref(0)
const validOwnFunds = ref(0)
const validIncome = ref(0)

const selectedRate = ref('rate_5years')
const rate = ref(MORTGAGE_DEFAULT_RATE)
const rateInput = ref(0)
const rateMin = 0.1
const rateMax = 6
const rateInputError = ref('')

const userInput = computed(() => ({
  purchasePrice: validPurchasePrice.value,
  ownFunds: validOwnFunds.value,
  income: validIncome.value,
}))

const rateOptions = computed(() => {
  return Object.keys(FINANCE_VARIANT)
    .map((id) => {
      return {
        id,
        label: FINANCE_VARIANT[id],
        rate: props.rates.find((v) => v.key === id)?.value,
      }
    })
    .filter((v) => v.rate)
})

const value = computed(() => calculate(userInput.value, rateInput.value))

const sustainabilityLongterm = computed(() => {
  const value = calculate(userInput.value, 4.5)
  return value.tragbarkeit
})

const sustainabilityLongtermText = computed(() => {
  const index = getSustainabilityTextIndex(sustainabilityLongterm.value) + 1
  if (index === 1) {
    return $texts(
      'mortgage_sustainability_longterm_1',
      'Die finanzielle Belastung ist für Sie auch langfristig, bei einem kalkulatorischen Zinssatz von 4.5%, tragbar.',
    )
  } else if (index === 2) {
    return $texts(
      'mortgage_sustainability_longterm_2',
      'Die finanzielle Belastung ist für Sie langfristig, bei einem kalkulatorischen Zinssatz von 4.5%, nur bedingt tragbar.',
    )
  }
  return $texts(
    'mortgage_sustainability_longterm_3',
    'Die finanzielle Belastung ist für Sie langfristig, bei einem kalkulatorischen Zinssatz von 4.5%, noch nicht tragbar.',
  )
})

watch(
  () => props.purchasePrice,
  (value) => {
    if (props.isValid) {
      validPurchasePrice.value = value
    }
  },
)

watch(
  () => props.ownFunds,
  (value) => {
    if (props.isValid) {
      validOwnFunds.value = value
    }
  },
)

watch(
  () => props.income,
  (value) => {
    if (props.isValid) {
      validIncome.value = value
    }
  },
)

watch(
  () => props.isValid,
  () => {
    setLocalState()
  },
)

function setLocalState() {
  if (props.isValid) {
    validPurchasePrice.value = props.purchasePrice
    validOwnFunds.value = props.ownFunds
    validIncome.value = props.income
  }
}

function getSustainabilityTextIndex(sustainability: number) {
  if (sustainability < 33) {
    return 0
  } else if (sustainability >= 33 && sustainability <= 40) {
    return 1
  }

  return 2
}

function getInterestRate(id: string) {
  const rate = props.rates.find((v) => v.key === id)
  if (rate) {
    const value = rate.value

    if (!Number.isNaN(value)) {
      return value
    }
  }

  return MORTGAGE_DEFAULT_RATE
}

function onRateSelect(e: Event) {
  if (!(e.target instanceof HTMLSelectElement)) {
    return
  }
  const id = e.target.value
  selectedRate.value = id
  const rateValue = getInterestRate(id)

  if (rateValue) {
    rate.value = rateValue
    rateInput.value = rateValue
  }
  rateInputError.value = ''
}

function buildCalculation(
  id: string,
  label: string,
  tooltip: string,
  value: number,
) {
  return {
    id,
    label,
    tooltip,
    value: formatCurrency(value),
  }
}

const calculations = computed(() => {
  return [
    buildCalculation(
      'finanzierungsbedarf',
      $texts('mortgage_finanzierungsbedarf', 'Finanzierungsbedarf'),
      $texts(
        'mortgage_finanzierungsbedarf_help',
        'Der Finanzierungsbedarf entspricht der Höhe der benötigten Hypothek, um die Immobilie finanzieren zu können.',
      ),
      value.value.finanzierungsbedarf,
    ),
    buildCalculation(
      'interest_per_month',
      $texts('mortgage_interest_per_month', 'Hypothekarzins pro Monat'),
      $texts(
        'mortgage_interest_per_month_help',
        'Der Hypothekarzins berechnet sich aus dem Finanzierungsbedarf und dem aktuellen Zinssatz der gewählten Finanzierungsvariante.',
      ),
      value.value.hypothekTotalZinsenJahr / 12,
    ),

    buildCalculation(
      'monatliche_belastung',
      $texts('mortgage_belastung', 'Monatliche Belastung'),
      $texts(
        'mortgage_belastung_help',
        'Die monatliche Belastung setzt sich aus dem Hypothekarzins, der Amortisation und den Nebenkosten zusammen.',
      ),
      value.value.belastung / 12,
    ),

    buildCalculation(
      'nebenkosten',
      $texts('mortgage_nebenkosten', 'Nebenkosten pro Monat'),
      $texts(
        'mortgage_nebenkosten_help',
        'Die Nebenkosten belaufen sich erfahrungsgemäss auf etwa 0.8% des Kaufpreises der Immobilie pro Jahr',
      ),
      value.value.nebenkosten / 12,
    ),
  ]
})

function mounted() {
  setLocalState()
  rate.value = getInterestRate('rate_5years')
  rateInput.value = rate.value
}

onMounted(mounted)

defineOptions({
  name: 'MortgageCalculatorResults',
})
</script>

<style lang="postcss">
.mortgage-results-table {
  @apply overflow-auto;
  &:not(:last-child) {
    @apply mb-40;
  }
  tbody tr:first-child {
    td,
    th {
      @apply border-t border-t-gray-300;
    }
  }

  td,
  th {
    &:nth-child(n + 2) {
      text-align: right;
      width: 20%;
    }
  }
}
</style>
