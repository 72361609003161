<template>
  <button
    ref="el"
    v-click-away="close"
    class="button size-24 p-0 leading-none min-h-0 items-center justify-center rounded-full button-tooltip ml-8 relative !overflow-visible"
    :class="{ 'is-active': isActive }"
    @click.prevent.stop="toggle"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="relative">i</div>
    <Teleport to="body">
      <ButtonTooltipOverlay v-if="isActive && el" :el="el" :text="text" />
    </Teleport>
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  id: string
  text: string
}>()

const el = ref<HTMLButtonElement | null>(null)

const { isDebug } = useEasyTexts()

const activeId = useState('button_tooltip_active_id', () => '')

const isActive = computed(() => isDebug.value || props.id === activeId.value)

const activeBy = ref<'mouse' | 'click' | null>(null)

function close() {
  if (isActive.value) {
    activeId.value = ''
  }
}

function toggle() {
  if (isActive.value && activeBy.value === 'mouse') {
    activeBy.value = 'click'
    return
  }
  activeBy.value = 'click'
  activeId.value = isActive.value ? '' : props.id
}

function onMouseEnter() {
  activeBy.value = 'mouse'
  activeId.value = props.id
}

function onMouseLeave() {
  if (activeBy.value === 'click') {
    return
  }
  if (activeId.value === props.id) {
    activeId.value = ''
  }
}
</script>

<style lang="postcss">
.button-tooltip.is-active {
  @apply bg-gray-100;
}
</style>
