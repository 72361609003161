export function formatCurrency(value: number, round?: boolean) {
  const rounded = round !== false ? Math.round(value) : value

  if (round === false) {
    return value
      .toLocaleString('de-CH', {
        currency: 'CHF',
        style: 'currency',
      })
      .replace(/’/gm, "'")
  }
  return 'CHF ' + formatSwissNumber(rounded)
}

/**
 * Special number formatting.
 *
 * As requested in https://jira.liip.ch/browse/SHKB-931
 */
export function formatSwissNumber(value: number) {
  return value.toLocaleString('de-CH').replace(/’/gm, "'")
}

export function formatInterest(value: number) {
  return (Math.round(value * 100) / 100).toLocaleString('de-CH') + ' %'
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]
}

export function modulo(n: number, m: number) {
  return ((n % m) + m) % m
}
