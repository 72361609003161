<template>
  <VuepalLink
    :to="url"
    class="aspect-[3/2] relative bg-gray-200 overflow-hidden group block w-full teaser-recommendation focusable"
  >
    <MediaImage
      v-if="image"
      v-bind="image"
      :image-style="imageStyle"
      class="group-hover:scale-105 transition ease-swing duration-300"
    />
    <div
      v-else-if="icon"
      class="absolute w-4/5 aspect-square right-0 top-1/2 -translate-y-1/2 pointer-events-none"
    >
      <TaxonomyTermIcons
        v-bind="icon"
        class="size-full text-gray-400 transition ease-swing duration-300 group-hover:scale-105"
      />
    </div>
    <div
      class="absolute left-0 max-w-[340px] transition duration-300 ease-swing translate-x-[-0.5em] group-hover:translate-x-[-0.1em] will-change-transform"
      :class="isTop ? 'top-16' : 'bottom-16'"
    >
      <div class="brand-bar">
        <h3 class="brand-bar-inner" v-html="$sup(title)" />
      </div>
    </div>
  </VuepalLink>
</template>

<script lang="ts" setup>
import type {
  MediaImageFragment,
  TaxonomyTermIconsFragment,
} from '#graphql-operations'

const props = defineProps<{
  title: string
  image?: MediaImageFragment
  icon?: TaxonomyTermIconsFragment
  url: string
  textPosition?: string
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 3 / 2,
  sizes: {
    sm: 240,
    md: 224,
    lg: 294,
    xl: 330,
    '2xl': 384,
    '3xl': 416,
  },
})

defineOptions({
  name: 'TeaserRecommendation',
})

const isTop = computed(() => props.textPosition === 'top-left')
const { $sup } = useNuxtApp()
</script>
