<template>
  <div :id="id" class="layout-grid my-section">
    <ProductComparison
      v-if="nids && nids.length > 1"
      :namespace="uuid"
      :nids="nids"
      class="col-start-2 col-end-[-2]"
    />
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphProductComparisonFragment } from '#graphql-operations'

const props = defineProps<{
  productsRef?: ParagraphProductComparisonFragment['productsRef']
}>()

const { uuid, options } = defineBlokkli({
  bundle: 'product_comparison',
  globalOptions: ['anchorId'],
})

const nids = computed(() =>
  props.productsRef?.list?.map((v) => String(v.targetId)).filter(notNullish),
)

const id = computed(() => slugify(options.value.anchorId))
</script>
