
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_follow_menu_item_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/FollowMenuItem/index.vue'
import BlokkliComponent_from_library_index from '/builds/bazinga/shkb.next/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_app_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/App/index.vue'
import BlokkliComponent_cta_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/CTA/index.vue'
import BlokkliComponent_card_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Card/index.vue'
import BlokkliComponent_contact_teaser_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/ContactTeaser/index.vue'
import BlokkliComponent_container_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Container/index.vue'
import BlokkliComponent_eyecatcher_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Eyecatcher/index.vue'
import BlokkliComponent_file_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/File/index.vue'
import BlokkliComponent_footnotes_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Footnotes/index.vue'
import BlokkliComponent_gallery_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Gallery/index.vue'
import BlokkliComponent_grid_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Grid/index.vue'
import BlokkliComponent_image_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_link_InsideFollowMenuItem from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Link/InsideFollowMenuItem.vue'
import BlokkliComponent_link_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Link/index.vue'
import BlokkliComponent_links_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Links/index.vue'
import BlokkliComponent_list_item_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/ListItem/index.vue'
import BlokkliComponent_online_services_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/OnlineServices/index.vue'
import BlokkliComponent_person_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Person/index.vue'
import BlokkliComponent_phone_number_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/PhoneNumber/index.vue'
import BlokkliComponent_product_bundle_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/ProductBundle/index.vue'
import BlokkliComponent_product_comparison_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/ProductComparison/index.vue'
import BlokkliComponent_quote_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_remote_video_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/RemoteVideo/index.vue'
import BlokkliComponent_slide_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Slide/index.vue'
import BlokkliComponent_steps_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Steps/index.vue'
import BlokkliComponent_table_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Table/index.vue'
import BlokkliComponent_teaser_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Teaser/index.vue'
import BlokkliComponent_text_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_three_columns_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/ThreeColumns/index.vue'
import BlokkliComponent_title_lead_link_InFollowMenuItem from '/builds/bazinga/shkb.next/frontend/components/Paragraph/TitleLeadLink/InFollowMenuItem.vue'
import BlokkliComponent_title_lead_link_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/TitleLeadLink/index.vue'
import BlokkliComponent_two_columns_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/TwoColumns/index.vue'
import BlokkliComponent_video_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Video/index.vue'
import BlokkliComponent_webform_index from '/builds/bazinga/shkb.next/frontend/components/Paragraph/Webform/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/builds/bazinga/shkb.next/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_follow_menu_item: BlokkliComponent_follow_menu_item_index,
  block_from_library: BlokkliComponent_from_library_index,
  block_app: BlokkliComponent_app_index,
  block_cta: BlokkliComponent_cta_index,
  block_card: BlokkliComponent_card_index,
  block_contact_teaser: BlokkliComponent_contact_teaser_index,
  block_container: BlokkliComponent_container_index,
  block_eyecatcher: BlokkliComponent_eyecatcher_index,
  block_file: BlokkliComponent_file_index,
  block_footnotes: BlokkliComponent_footnotes_index,
  block_gallery: BlokkliComponent_gallery_index,
  block_grid: BlokkliComponent_grid_index,
  block_image: BlokkliComponent_image_index,
  block_link__parent_block_follow_menu_item: BlokkliComponent_link_InsideFollowMenuItem,
  block_link: BlokkliComponent_link_index,
  block_links: BlokkliComponent_links_index,
  block_list_item: BlokkliComponent_list_item_index,
  block_online_services: BlokkliComponent_online_services_index,
  block_person: BlokkliComponent_person_index,
  block_phone_number: BlokkliComponent_phone_number_index,
  block_product_bundle: BlokkliComponent_product_bundle_index,
  block_product_comparison: BlokkliComponent_product_comparison_index,
  block_quote: BlokkliComponent_quote_index,
  block_remote_video: BlokkliComponent_remote_video_index,
  block_slide: BlokkliComponent_slide_index,
  block_steps: BlokkliComponent_steps_index,
  block_table: BlokkliComponent_table_index,
  block_teaser: BlokkliComponent_teaser_index,
  block_text: BlokkliComponent_text_index,
  block_three_columns: BlokkliComponent_three_columns_index,
  block_title_lead_link__parent_block_follow_menu_item: BlokkliComponent_title_lead_link_InFollowMenuItem,
  block_title_lead_link: BlokkliComponent_title_lead_link_index,
  block_two_columns: BlokkliComponent_two_columns_index,
  block_video: BlokkliComponent_video_index,
  block_webform: BlokkliComponent_webform_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index
}

    
import BlokkliFragmentComponent_agencies from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/Agencies/index.vue'
import BlokkliFragmentComponent_bancomat from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/Bancomat/index.vue'
import BlokkliFragmentComponent_events from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/Events/index.vue'
import BlokkliFragmentComponent_management from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/Management/index.vue'
import BlokkliFragmentComponent_media from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/Media/index.vue'
import BlokkliFragmentComponent_mortgage_calculator from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/MortgageCalculator/index.vue'
import BlokkliFragmentComponent_news from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/News/index.vue'
import BlokkliFragmentComponent_online_services_overview from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/OnlineServicesOverview/index.vue'
import BlokkliFragmentComponent_people from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/People/index.vue'
import BlokkliFragmentComponent_quicksearch from '/builds/bazinga/shkb.next/frontend/components/Paragraph/BlokkliFragment/Quicksearch/index.vue'

const globalFragments: Record<string, any> = {
  fragment_agencies: BlokkliFragmentComponent_agencies,
  fragment_bancomat: BlokkliFragmentComponent_bancomat,
  fragment_events: BlokkliFragmentComponent_events,
  fragment_management: BlokkliFragmentComponent_management,
  fragment_media: BlokkliFragmentComponent_media,
  fragment_mortgage_calculator: BlokkliFragmentComponent_mortgage_calculator,
  fragment_news: BlokkliFragmentComponent_news,
  fragment_online_services_overview: BlokkliFragmentComponent_online_services_overview,
  fragment_people: BlokkliFragmentComponent_people,
  fragment_quicksearch: BlokkliFragmentComponent_quicksearch
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
