<template>
  <iframe
    v-if="src"
    :src="src"
    allow="autoplay; encrypted-media; picture-in-picture"
    allowfullscreen
  />
</template>

<script lang="ts" setup>
import { buildEmbedUrl } from '~/helpers/vimeo'

const props = defineProps<{
  url: string
}>()

const src = computed(() => buildEmbedUrl(props.url))
</script>
