<template>
  <a
    v-if="parentType === 'gallery'"
    class="flex gap-16"
    data-pswp-type="vimeo"
    :href="videoUrl"
  >
    <div class="w-170 shrink-0">
      <img
        :src="remoteVideo?.thumbnail?.small?.urlPath"
        class="shadow h-auto aspect-video"
      />
    </div>
    <div>
      <h3 class="typo-h4" v-html="$sup(remoteVideo?.name)" />
      <p
        class="text-base text-gray-700 font-bold"
        v-html="$sup(remoteVideo?.subtitle)"
      />
    </div>
  </a>
  <div
    v-else
    class="paragraph-wrapper-background"
    :class="{
      'col-start-2 col-end-[-2]': !parentType && !options.isHero,
      'col-start-1 col-end-[-1]': !parentType && options.isHero,
    }"
  >
    <div v-if="videoUrl">
      <figure :class="{ 'pointer-events-none': isEditing }">
        <VimeoVideo
          :url="videoUrl"
          class="w-full"
          :class="
            options.aspectRatio === 'portrait' && parentType
              ? 'aspect-[9/16]'
              : 'aspect-video'
          "
        />
      </figure>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphRemoteVideoFragment } from '#graphql-operations'

const { options, parentType, isEditing } = defineBlokkli({
  bundle: 'remote_video',
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
    determineVisibleOptions: function (ctx) {
      // Nested videos can define their aspect ratio.
      if (ctx.parentType) {
        return ['aspectRatio']
      }

      return ['isHero']
    },
  },
  options: {
    isHero: {
      type: 'checkbox',
      label: 'Als Hero anzeigen',
      default: false,
    },
    aspectRatio: {
      type: 'radios',
      label: 'Bildverhältnis',
      default: 'landscape',
      options: {
        landscape: 'Wide (16/9)',
        portrait: 'TikTok (9/16)',
      },
    },
  },
})

const props = defineProps<{
  remoteVideo?: ParagraphRemoteVideoFragment['remoteVideo']
}>()

const videoUrl = computed(() => props.remoteVideo?.url || '')
const { $sup } = useNuxtApp()
</script>
