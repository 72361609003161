<template>
  <div
    v-if="parentType === 'steps' && fieldListType !== 'header'"
    :id="options.anchorId"
    class="md:flex-1 p-15 md:p-20 paragraph-steps-item relative md:flex md:gap-10 shrink-0 snap-center bg-white shadow md:shadow-none"
    :class="{
      'md:bg-gray-50': parentBackground === 'white',
    }"
  >
    <div
      class="text-3xl size-44 bg-primary-100 rounded-full text-primary-800 flex items-center justify-center font-heavy shrink-0 float-right"
    >
      {{ index + 1 }}
    </div>
    <div class="md:flex md:flex-col">
      <h3
        v-blokkli-editable:field_title
        class="lg:text-xl font-bold text-balance"
        v-html="$sup(title)"
      />
      <p v-blokkli-editable:field_lead v-html="$sup(lead)" />
      <div v-if="link?.uri?.path" class="mt-auto">
        <VuepalLink
          :to="link.uri.path"
          class="button is-small is-primary mt-16"
        >
          {{ link.title }}
        </VuepalLink>
      </div>
    </div>
  </div>
  <div
    v-else
    :id="options.anchorId"
    class="max-w-screen-lg"
    :class="{
      'section-title': fieldListType !== 'header' && parentType === 'container',
    }"
  >
    <BlokkliEditable
      v-if="title"
      v-slot="{ value }"
      :tag="options.headingLevel === 'h2' ? 'h2' : 'h3'"
      :value="title"
      name="field_title"
      class="text-balance"
      :class="[
        textClass,
        options.headingLevel === 'h2' ? 'typo-h2' : 'typo-h3',
      ]"
    >
      <div v-html="$sup(value)" />
    </BlokkliEditable>

    <BlokkliEditable
      v-if="lead"
      v-slot="{ value }"
      :value="lead"
      name="field_lead"
      class="text-balance"
      :class="[
        textMutedClass,
        options.headingLevel === 'h2' ? 'typo-lead-2' : 'typo-lead-3',
      ]"
    >
      <p v-html="$sup(value)" />
    </BlokkliEditable>
    <VuepalLink v-if="link?.uri?.path" :to="link.uri.path" class="button mt-16">
      {{ link.title }}
    </VuepalLink>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTitleLeadLinkFragment } from '#graphql-operations'

const { options, parentType, index, fieldListType } = defineBlokkli({
  bundle: 'title_lead_link',
  globalOptions: ['anchorId'],
  options: {
    headingLevel: {
      type: 'radios',
      label: 'Level',
      default: 'h2',
      displayAs: 'icons',
      options: {
        h2: { label: 'H2', icon: 'icon-blokkli-option-h2' },
        h3: { label: 'H3', icon: 'icon-blokkli-option-h3' },
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

defineProps<{
  title?: string
  lead?: string
  link?: ParagraphTitleLeadLinkFragment['link']
}>()

const { textClass, textMutedClass, parentBackground } = useParagraphContext()

const { $sup } = useNuxtApp()
</script>
