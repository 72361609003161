<template>
  <div
    class="layout-grid pt-48 pb-24 md:py-64 xl:py-112 items-center print:py-10"
  >
    <div class="col-start-2 col-end-[-2] md:flex md:items-center md:gap-24">
      <slot />
      <div class="">
        <template v-if="blokkli">
          <BlokkliEditable
            v-if="title"
            v-slot="{ value }"
            :value="title"
            tag="h1"
            name="title"
            class="font-bold typo-h1 text-balance hyphens-auto break-word"
          >
            <div v-html="$sup(value)" />
          </BlokkliEditable>
          <BlokkliEditable
            v-if="lead"
            v-slot="{ value }"
            :value="lead"
            tag="div"
            name="field_subtitle"
            class="text-gray-400 font-bold mt-10 typo-lead-1 md:text-balance hyphens-auto"
          >
            <p v-html="$sup(value)" />
          </BlokkliEditable>
        </template>
        <template v-else>
          <h1 class="font-bold typo-h1 text-balance" v-html="$sup(title)" />
          <p
            v-if="lead"
            class="text-gray-400 font-bold mt-10 typo-lead-1 text-balance"
            v-html="$sup(lead)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  title?: string
  lead?: string
  blokkli?: boolean
}>()

const { $sup } = useNuxtApp()
</script>
