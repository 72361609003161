import { notNullish } from '@vueuse/core'

export type BackgroundColor =
  | 'white'
  | 'grayLight'
  | 'grayDark'
  | 'yellow'
  | 'black'
type SectionPadding = 'both' | 'top' | 'bottom' | 'none'

type BlokkliGlobalClassOptions = {
  sectionBackground?: BackgroundColor
  sectionPadding?: SectionPadding
  hasBorder?: boolean
}

function getBackgroundColorClass(color: BackgroundColor = 'white'): string {
  switch (color) {
    case 'grayLight':
      return 'bg-gray-100'
    case 'grayDark':
      return 'bg-gray-200'
    case 'yellow':
      return 'bg-primary-500'
    case 'black':
      return 'bg-primary-950'
  }

  return 'bg-white'
}

function getBorderClass(
  color: BackgroundColor = 'white',
  hasBorder?: boolean,
): string | undefined {
  if (!hasBorder) {
    return
  }
  switch (color) {
    case 'grayLight':
      return 'border-b border-b-gray-200'
    case 'grayDark':
      return 'border-b border-b-gray-50'
    case 'yellow':
      return 'border-b border-b-primary-700'
    case 'black':
      return 'border-b border-b-black'
  }

  return 'border-b border-b-gray-200'
}

function getPaddingClasses(
  padding: SectionPadding = 'both',
  background: BackgroundColor = 'white',
  hasBorder?: boolean,
): string {
  const classes: string[] = []

  // Uses margins.
  if (background === 'white' && !hasBorder) {
    classes.push('my-section')
    if (padding === 'top' || padding === 'none') {
      classes.push('!mb-0')
    }

    if (padding === 'bottom' || padding === 'none') {
      classes.push('!mt-0')
    }
  } else {
    classes.push('py-section')
    if (padding === 'top' || padding === 'none') {
      classes.push('!pb-0')
    }

    if (padding === 'bottom' || padding === 'none') {
      classes.push('!pt-0')
    }
  }

  return classes.join(' ')
}

type UseParagraphContext = {
  parentBackground: ComputedRef<BackgroundColor>
  classes: ComputedRef<string[]>
  textClass: ComputedRef<string>
  textMutedClass: ComputedRef<string>
}

export default function (
  options?: ComputedRef<BlokkliGlobalClassOptions>,
): UseParagraphContext {
  const providedParentBackground = inject<ComputedRef<BackgroundColor> | null>(
    'paragraph_parent_background',
    null,
  )

  const parentBackground = computed<BackgroundColor>(
    () =>
      options?.value?.sectionBackground ||
      providedParentBackground?.value ||
      'white',
  )

  provide('paragraph_parent_background', parentBackground)

  const classes = computed(() => {
    if (!options) {
      return []
    }
    const hasBackground =
      (options.value.sectionBackground || 'white') !== 'white'
    return [
      getBackgroundColorClass(options.value.sectionBackground),
      getPaddingClasses(
        options.value.sectionPadding,
        options.value.sectionBackground,
        !!options.value.hasBorder,
      ),
      getBorderClass(options.value.sectionBackground, options.value.hasBorder),
      hasBackground || options.value.hasBorder
        ? 'paragraph-wrapper-background'
        : 'paragraph-wrapper-transparent',
    ].filter(notNullish)
  })

  const textClass = computed(() => {
    if (parentBackground.value === 'black') {
      return 'text-white'
    }

    return 'text-black'
  })

  const textMutedClass = computed(() => {
    if (parentBackground.value === 'black') {
      return 'text-gray-700'
    } else if (parentBackground.value === 'yellow') {
      return 'text-primary-800'
    } else if (
      parentBackground.value === 'grayDark' ||
      parentBackground.value === 'grayLight'
    ) {
      return 'text-gray-500'
    }

    return 'text-gray-500'
  })

  return {
    classes,
    parentBackground,
    textClass,
    textMutedClass,
  }
}
