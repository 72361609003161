// The regex pattern. Make sure this is in sync with the pattern defined in /drupal/docroot/modules/custom/shkb_backend/src/Plugin/Filter/ProductNameFilter.php
export const SUP_RGX =
  /(^|\s|>)(KB|INVEST|DEPOT|BILDUNG|JUGEND|BUSINESS|ANLAGEBERATUNG)(PLUS ESG|PLUS|INDEX|PREMIUM|BERATUNG|EXKLUSIV|BASIS|NACHHALTIGKEIT|DIGITAL|PARTNER|AUTONOM)/gm

/**
 * Wrap the second part of SHKB product names in a <sup> tag.
 * Example DEPOTPREMIUM => DEPOT<sup>PREMIUM</sup>
 */
export type SupFn = (value: string) => string

export default function (value?: string): string {
  if (!value) {
    return ''
  }
  const supped = value.replace(
    SUP_RGX,
    '$1<span class="brand-product-name">$2<sup>$3</sup></span>',
  )

  // String contains HTML.
  if (supped.includes('<')) {
    return supped
  }

  // String is not HTML, so we can freely alter some things.
  return (
    supped
      // Allow line breaks after a slash.
      .replaceAll('/', '/&#8203;')
      // Allow line breaks before any of these words.
      .replace(/(anfrage|termin)/gm, '&shy;$1')
  )
}
