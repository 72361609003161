<template>
  <TeaserBanner
    v-if="!parentType && title && url"
    :title="title"
    :lead="options.showText ? teaserText || lead : undefined"
    :url="url"
    :link-text="linkText"
    :background="options.sectionBackground"
    v-bind="attributes"
  />
  <TeaserProduct
    v-else-if="options.style === 'product' && title"
    :title="title"
    :price="options.showPrice ? subtitle : undefined"
    :text="options.showFeatures ? features : lead"
    :icon="icon"
    :url="url"
    :parent-background="parentBackground"
    :show-cta="options.showCta"
    :cta-text="link?.title"
    v-bind="attributes"
  />
  <TeaserRecommendation
    v-else-if="options.style === 'recommendation' && title && url"
    :title="teaserText || lead || title"
    :image="image"
    :icon="icon"
    :url="url"
    :text-position="textPosition"
    v-bind="attributes"
  />
  <TeaserDefault
    v-else-if="options.style === 'teaser' && title && url"
    :title="title"
    :text="lead"
    :url="url"
    :icon="options.showIcon ? icon : undefined"
    :parent-background="parentBackground"
    v-bind="attributes"
  />
  <div v-else-if="isEditing">Teaser hat ungültigte Daten.</div>
</template>

<script lang="ts" setup>
/**
 * This paragraph has a link field that allows both internal and external links.
 * In case of external links, the title, text and media fields are required. For
 * internal links, they are optional.
 *
 * The title, text and media fields should always be used as the primary source.
 * If the link is internal we can fallback to the linked entity's teaser data.
 */
import type {
  MediaImageFragment,
  ParagraphTeaserEntityFragment,
  ParagraphTeaserFragment,
  TaxonomyTermIconsFragment,
} from '#graphql-operations'

const props = defineProps<{
  overrideTitle?: ParagraphTeaserFragment['overrideTitle']
  overrideSubtitle?: ParagraphTeaserFragment['overrideSubtitle']
  overrideLead?: ParagraphTeaserFragment['overrideLead']
  overrideIcon?: ParagraphTeaserFragment['overrideIcon']
  overrideImage?: ParagraphTeaserFragment['overrideImage']
  overrideEntity?: ParagraphTeaserFragment['overrideEntity']
  overrideTextPosition?: ParagraphTeaserFragment['overrideTextPosition']
  link?: ParagraphTeaserFragment['link']
}>()

const { options, parentType, isEditing } = defineBlokkli({
  bundle: 'teaser',
  globalOptions: ['sectionBackground'],
  options: {
    style: {
      type: 'radios',
      label: 'Darstellung',
      default: 'teaser',
      options: {
        teaser: 'Teaser',
        recommendation: 'Empfehlung',
        product: 'Produkt',
      },
    },
    showText: {
      type: 'checkbox',
      label: 'Text anzeigen',
      default: false,
    },
    showPrice: {
      type: 'checkbox',
      label: 'Preise anzeigen',
      default: false,
    },
    showFeatures: {
      type: 'checkbox',
      label: 'Features anzeigen',
      default: false,
    },
    showCta: {
      type: 'checkbox',
      label: 'CTA anzeigen',
      default: false,
    },
    showIcon: {
      type: 'checkbox',
      label: 'Icon anzeigen',
      default: false,
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
    mockProps: (text) => {
      return {
        overrideTitle: text || 'Teaser Titel',
        link: {
          uri: {
            path: '/',
          },
        },
      }
    },

    determineVisibleOptions(ctx) {
      if (!ctx.parentType) {
        return ['sectionBackground', 'showText']
      }

      if (ctx.options.style === 'product') {
        return ['style', 'showCta', 'showPrice', 'showFeatures']
      } else if (ctx.options.style === 'teaser') {
        return ['style', 'showIcon']
      }

      return ['style']
    },
  },
})

const { parentBackground } = useParagraphContext()

const entity = computed<ParagraphTeaserEntityFragment | undefined>(() => {
  if (props.overrideEntity && 'label' in props.overrideEntity) {
    return props.overrideEntity
  }
  if (
    props.link?.uri &&
    'entity' in props.link.uri &&
    props.link.uri.entity?.label
  ) {
    return props.link.uri.entity
  }
  return undefined
})

const title = computed<string | undefined>(() => {
  if (props.overrideTitle) {
    return props.overrideTitle
  } else if (entity.value?.label) {
    return entity.value.label
  }
  return undefined
})

const lead = computed<string | undefined>(() => {
  if (props.overrideLead) {
    return props.overrideLead
  }
  if (entity.value && 'lead' in entity.value) {
    return entity.value.lead
  }
  return undefined
})

const teaserText = computed<string | undefined>(() => {
  if (entity.value && 'teaserText' in entity.value) {
    return entity.value.teaserText
  }
  return undefined
})

const features = computed<string[] | undefined>(() => {
  if (entity.value && 'features' in entity.value) {
    return entity.value.features
  }
  return undefined
})

const icon = computed<TaxonomyTermIconsFragment | undefined>(() => {
  if (props.overrideIcon) {
    return props.overrideIcon
  } else if (entity.value && 'icon' in entity.value) {
    return entity.value.icon
  }
  return undefined
})

const image = computed<MediaImageFragment | undefined>(() => {
  if (props.overrideImage) {
    return props.overrideImage
  } else if (entity.value && 'image' in entity.value) {
    return entity.value.image
  }
  return undefined
})

const subtitle = computed<string | undefined>(() => {
  if (props.overrideSubtitle) {
    return props.overrideSubtitle
  } else if (entity.value && 'price' in entity.value) {
    return entity.value.price
  }
  return undefined
})

const textPosition = computed(() => {
  if (props.overrideTextPosition) {
    return props.overrideTextPosition
  }
  if (entity.value && 'textPosition' in entity.value) {
    return entity.value.textPosition
  }
  return undefined
})

const url = computed(() => {
  if (
    entity.value &&
    'mediaFileUrl' in entity.value &&
    entity.value.mediaFileUrl?.path
  ) {
    return entity.value.mediaFileUrl.path
  }
  return props.link?.uri?.path
})

const linkText = computed(() => {
  return props.link?.title || entity.value?.label || 'Mehr erfahren'
})

const attributes = computed(() => {
  if (url.value && url.value.startsWith('http')) {
    return {
      rel: 'noindex',
      target: '_blank',
    }
  }

  return {}
})

defineOptions({
  name: 'ParagraphTeaser',
})
</script>
