<template>
  <div style="flex: 1 0 0%">
    <FieldItemTypeLink v-slot="{ url, label, entityIcon }" v-bind="link">
      <IconTile
        v-if="label"
        :url="url"
        :title="label"
        :icon="icon || entityIcon"
      />
    </FieldItemTypeLink>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphLinkFragment } from '#graphql-operations'

defineProps<{
  link?: ParagraphLinkFragment['link']
  icon?: ParagraphLinkFragment['icon']
}>()

defineBlokkli({
  bundle: 'link',
  renderFor: [
    {
      parentBundle: 'follow_menu_item',
    },
  ],
})
</script>
