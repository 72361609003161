<template>
  <VuepalLink
    v-if="parentType && (url || isEditing)"
    :to="url"
    class="group print:hidden"
    rel="nofollow"
    :class="{
      'mt-16': needsMargin,
      'button w-full': options.showAsButton,
      block: !options.showAsButton,
    }"
  >
    <template v-if="options.showAsButton">
      <SpriteSymbol name="pdf" />
      <div v-html="$sup(title)" />
    </template>
    <div v-else v-blokkli-droppable:field_media_file class="flex gap-16 my-24">
      <div class="document w-[100px] shrink-0">
        <RokkaImage
          v-if="fileEntity?.rokkaMetadata?.hash"
          :config="imageStyleSmall"
          :hash="fileEntity?.rokkaMetadata?.hash"
        />
      </div>
      <div class="flex flex-col">
        <h3
          v-blokkli-editable:field_title
          class="text-xl font-bold"
          :class="textClass"
          v-html="$sup(title)"
        />
        <p
          v-if="subtitle"
          v-blokkli-editable:field_subtitle
          class="font-bold text-lg"
          :class="textMutedClass"
          v-html="$sup(subtitle)"
        />
        <div class="link mt-auto">
          <SpriteSymbol name="pdf" />
          PDF herunterladen
        </div>
      </div>
    </div>
  </VuepalLink>
  <div
    v-else-if="!parentType && (url || isEditing)"
    class="layout-grid bg-white border-b border-b-gray-200 section-padding col-start-1 col-end-[-1] print:hidden"
  >
    <div class="col-start-2 col-span-2">
      <div v-blokkli-droppable:field_media_file class="document max-w-[180px]">
        <RokkaImage
          v-if="fileEntity?.rokkaMetadata?.hash"
          :config="imageStyleLarge"
          :hash="fileEntity?.rokkaMetadata?.hash"
        />
      </div>
    </div>
    <div class="col-start-4 col-span-10 flex flex-col">
      <h3
        v-blokkli-editable:field_title
        class="text-2xl xl:text-5xl font-bold"
        v-html="$sup(title)"
      />
      <p
        v-if="subtitle"
        v-blokkli-editable:field_subtitle
        class="font-bold text-gray-700 text-xl"
        v-html="$sup(subtitle)"
      />

      <div
        v-if="lead"
        v-blokkli-editable:field_lead
        class="mt-20 max-w-screen-md"
        v-html="$sup(lead)"
      />

      <div class="mt-auto">
        <VuepalLink :to="url" class="button" rel="nofollow">
          <SpriteSymbol name="pdf" />
          PDF herunterladen
        </VuepalLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaFileFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphFile',
})

const props = defineProps<{
  file?: MediaFileFragment
  overrideTitle?: string
  overrideSubtitle?: string
  overrideLead?: string
}>()

const { parentType, options, isEditing, index } = defineBlokkli({
  bundle: 'file',
  options: {
    showAsButton: {
      type: 'checkbox',
      label: 'Als Button anzeigen',
      default: false,
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
  },
})

const { textClass, textMutedClass } = useParagraphContext()

const needsMargin = computed(
  () => parentType.value === 'two_columns' && index.value,
)

const imageStyleLarge = defineImageStyle({
  type: 'sizes',
  aspectRatio: 10000 / 14142,
  sizes: {
    sm: 400,
  },
})

const imageStyleSmall = defineImageStyle({
  type: 'sizes',
  aspectRatio: 10000 / 14142,
  sizes: {
    sm: 100,
  },
})

const fileEntity = computed(() => props.file?.file?.entity)

const title = computed(() => props.overrideTitle || props.file?.name)
const subtitle = computed(() => props.overrideSubtitle || props.file?.subtitle)
const lead = computed(() => props.overrideLead || props.file?.file?.description)

const url = computed(() => props.file?.url?.path)

const { $sup } = useNuxtApp()
</script>
