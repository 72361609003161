import type { BlockOptionDefinition } from '#blokkli/types/blokkOptions'

type GlobalOptionsDefaults = {
  type: BlockOptionDefinition['type']
  default: any
}

export const bundlesWithVisibleLanguage: string[] = []
export const bundlesWithHiddenGlobally: string[] = []

export const globalOptionsDefaults: Record<string, GlobalOptionsDefaults> = {
  "bkVisibleLanguages": {
    "default": [],
    "type": "checkboxes"
  },
  "sectionBackground": {
    "default": "white",
    "type": "radios"
  },
  "buttonBackground": {
    "default": "white",
    "type": "radios"
  },
  "verticalAlign": {
    "default": "top",
    "type": "radios"
  },
  "sectionPadding": {
    "default": "both",
    "type": "radios"
  }
} as const