/**
 * Simple LRU (least recently used) cache.
 *
 * Keeps the amount of cached items limited to the given max size.
 * Once that number is reached, the cache item used the least is removed.
 */
class LruCache {
  cache: Record<string, unknown>
  keys: string[]
  maxSize: number

  constructor(maxSize: number = 100) {
    this.cache = {}
    this.keys = []
    this.maxSize = maxSize
  }

  set(key: string, value: unknown): void {
    if (Object.prototype.hasOwnProperty.call(this.cache, key)) {
      // Key already exists, remove it from the current position
      // because it will be pushed to the end of the array below.
      const index = this.keys.indexOf(key)
      if (index > -1) {
        this.keys.splice(index, 1)
      }
    } else if (this.keys.length >= this.maxSize) {
      // If we've reached the limit of our cache, remove the oldest entry.
      const oldestKey = this.keys.shift()
      if (oldestKey !== undefined) {
        // TODO: Disables warning because no idea how to fix it.
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.cache[oldestKey]
      }
    }

    // Add the cache entry.
    this.cache[key] = value

    // Add the key to the end to mark it as the most recently used.
    this.keys.push(key)
  }

  get<T>(key: string): T | undefined {
    const value = this.cache[key]
    if (value !== undefined) {
      // Move the key to the end to mark it as the most recently used.
      const index = this.keys.indexOf(key)
      if (index > -1) {
        // Remove the key from its current position.
        this.keys.splice(index, 1)

        // Push it to the end.
        this.keys.push(key)
      }
      return value as T
    }

    return undefined
  }
}

/**
 * Provides a simple LRU cache for client side use.
 */
export default defineNuxtPlugin({
  name: 'client-cache',
  setup() {
    // On the server side we add a dummy implementation, because we don't
    // cache anything this way on the server side, as this would result in
    // memory leaks.
    if (import.meta.server) {
      const mock: LruCache = {
        cache: {},
        keys: [],
        maxSize: 0,
        get(_key) {
          return undefined
        },
        set(_key, _value) {},
      }
      return {
        provide: {
          clientCache: mock,
        },
      }
    }
    const clientCache = new LruCache()
    return {
      provide: {
        clientCache,
      },
    }
  },
})

declare module '#app' {
  interface NuxtApp {
    $clientCache: LruCache
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $clientCache: LruCache
  }
}
