<template>
  <div class="flex flex-col">
    <aside v-if="image" class="max-w-screen-sm">
      <MediaImage
        v-bind="image"
        :image-style="imageStyle"
        class="payment-card overflow-hidden shadow-xl"
      />
    </aside>
    <h3
      v-blokkli-editable:field_title
      class="text-xl xl:text-2xl font-bold leading-none mt-20"
      :class="textClass"
      v-html="$sup(title)"
    />
    <p
      v-blokkli-editable:field_lead
      class="text-lg font-bold"
      :class="textMutedClass"
      v-html="$sup(lead)"
    />
    <FieldItemTypeLink v-slot="{ url, label }" v-bind="link">
      <div class="mt-auto">
        <VuepalLink
          :to="url"
          class="button mt-24 lg:mt-32"
          :class="{ 'is-dark': parentBackground === 'yellow' }"
        >
          {{ label }}
        </VuepalLink>
      </div>
    </FieldItemTypeLink>
  </div>
</template>

<script setup lang="ts">
import type { ParagraphCardFragment } from '#graphql-operations'

defineProps<{
  title?: string
  lead?: string
  link?: ParagraphCardFragment['link']
  image?: ParagraphCardFragment['image']
  textClass: string
  textMutedClass: string
  parentBackground: BackgroundColor
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 8560 / 5398,
  sizes: {
    sm: 500,
  },
})

const { $sup } = useNuxtApp()
</script>
