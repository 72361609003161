<template>
  <div
    v-click-away="closeFollowMenu"
    class="relative md:pr-96 lg:pr-0 lg:fixed z-30 lg:z-50 lg:left-auto lg:right-0 lg:bottom-[15vh] lg:bg-white lg:shadow-xl group flex flex-col lg:flex-row print:hidden"
    :class="{
      '!right-50': preview,
    }"
  >
    <div
      v-if="activeItem"
      class="absolute bottom-full left-0 lg:left-auto w-full lg:w-auto lg:bottom-auto lg:relative z-30 bg-white shadow-invert lg:shadow-none"
    >
      <BlokkliField name="field_paragraphs" :list="[activeItem]" />
    </div>
    <div
      v-if="items"
      class="follow-menu-grid overflow-hidden flex lg:block relative z-40"
    >
      <NuxtLink
        v-if="onlineServices"
        :to="onlineServices"
        class="flex flex-col justify-center items-center lg:aspect-square lg:not-last:border-b lg:not-last:border-b-gray-300 flex-1 lg:w-80 py-10 group-hover:opacity-50 hover:!opacity-100"
        :class="{
          'opacity-50 bg-gray-200': active,
        }"
      >
        <div
          class="mb-5 bg-primary-200 text-primary-950 rounded-full size-40 flex items-center justify-center"
        >
          <SpriteSymbol
            name="cog"
            class="size-24 animate-spin"
            style="animation-duration: 18s"
          />
        </div>
        <span class="font-bold text-sm">{{
          $texts('follow_menu_service_title', 'Service')
        }}</span>
      </NuxtLink>
      <button
        v-for="item in items"
        :key="item.uuid"
        class="flex flex-col justify-center items-center lg:aspect-square lg:not-last:border-b lg:not-last:border-b-gray-300 flex-1 lg:w-80 py-10 group-hover:opacity-50 hover:!opacity-100 shrink-0"
        :class="{
          'bg-white !opacity-100 !border-b-white shadow-lg':
            active === item.uuid,
          'opacity-50 bg-gray-200': active && active !== item.uuid,
          'hidden md:flex': item.isContact,
        }"
        @click="onClick(item.uuid)"
      >
        <div class="size-40 mb-5 flex items-center justify-center">
          <TaxonomyTermIcons v-bind="item.icon" class="size-24" />
        </div>
        <span class="font-bold text-sm">{{ item.title }}</span>
      </button>
    </div>
  </div>

  <Teleport to="body">
    <Transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="active"
        class="fixed bg-gray-50/80 top-0 left-0 size-full z-20"
        @click="closeFollowMenu"
      />
    </Transition>
    <Transition :name="getTransition()">
      <div
        v-if="contactItem && openMobilePane === 'contact'"
        class="fixed top-0 left-0 size-full z-[190] bg-white"
      >
        <BlokkliField name="field_paragraphs" :list="[contactItem]" />
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type { FieldListItem } from '#blokkli/types'
import type { ParagraphFollowMenuItemFragment } from '#graphql-operations'

const props = defineProps<{
  paragraphs: FieldListItem[]
  preview?: boolean
}>()

const data = await useInitData()
const { $texts } = useEasyTexts()

const onlineServices = computed(
  () => data.value.globalConfig.pageOnlineServices,
)

const active = ref('')

function closeFollowMenu() {
  active.value = ''
}

onRouteChange(closeFollowMenu)

const { openMobilePane, hasTransition } = useMenu()

function getTransition() {
  if (hasTransition.value) {
    return 'mobile-menu-pane'
  }
  return undefined
}

const items = computed(() => {
  return props.paragraphs
    ?.map((v) => {
      const props = v.props as ParagraphFollowMenuItemFragment | undefined
      if (props && 'title' in props && props.icon && props.menuTitle) {
        return {
          uuid: v.uuid,
          icon: props.icon,
          title: props.menuTitle,
          isContact: v.options?.type === 'contact',
        }
      }
      return null
    })
    .filter(notNullish)
})

const activeItem = computed(() => {
  if (!active.value) {
    return
  }
  return props.paragraphs.find((v) => v.uuid === active.value)
})

function onClick(uuid: string) {
  if (active.value === uuid) {
    active.value = ''
  } else {
    active.value = uuid
  }
}

const contactItem = computed(() =>
  props.paragraphs.find((v) => v.options?.type === 'contact'),
)
</script>
