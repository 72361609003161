<template>
  <div class="webform-container relative">
    <Loading v-if="isLoading" />
    <div class="container" :style="{ height: height + 'px' }">
      <div class="bg-white" />
    </div>
    <client-only v-if="isMounted">
      <iframe
        id="webform_iframe"
        ref="iframe"
        class="absolute top-0 left-0 size-full"
        width="100%"
        :src="src"
        scrolling="no"
        :class="{ 'is-hidden': !visible }"
        @load="onLoad"
      />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
const iframe = ref<HTMLIFrameElement | null>(null)

const route = useRoute()
const router = useRouter()

const props = defineProps<{
  webformUrl: string
  sourceEntityId?: string
  editing?: boolean
}>()

const visible = ref(false)
const isLoading = ref(true)
const height = ref(200)
let timeout: any = null
const isMounted = ref(false)

const src = computed(() => {
  const query = Object.keys(route.query).map((key) => {
    return key + '=' + route.query[key]
  })
  if (props.sourceEntityId) {
    query.push(
      'source_entity_type=node&source_entity_id=' + props.sourceEntityId,
    )
  }
  const query_string = query.join('&')

  const path = props.webformUrl

  if (query_string) {
    return [path, query_string].join('?')
  }

  return path
})

function onRedirect(path: string) {
  router.push(path)
}

function onLoad(e: Event) {
  if (e.target instanceof HTMLIFrameElement) {
    const pathname = e.target.contentDocument?.location.pathname
    const search = e.target.contentDocument?.location.search || ''
    if (pathname && pathname.includes('confirmation')) {
      e.preventDefault()
      e.stopPropagation()
      visible.value = false
      e.target.src = 'about:blank'
      onRedirect(pathname + search)
      return
    }
  }
  isLoading.value = false
  visible.value = true
}

function onIframeMessage(e: MessageEvent) {
  console.log('onIframeMessage', e.data)
  if (e.data.event === 'height') {
    height.value = e.data.data

    // Remove this event listener because messaging between iframe and main
    // frame works.
    window.removeEventListener('resize', onWindowResize)
  } else if (e.data.event === 'unload') {
    visible.value = false
  } else if (e.data.event === 'webformRedirect' && !props.editing) {
    onRedirect(e.data.data)
  }
}

function onWindowResize() {
  window.clearTimeout(timeout)
  timeout = window.setTimeout(() => {
    if (!iframe.value) {
      return
    }
    const newHeight = iframe.value.contentDocument?.body?.scrollHeight
    if (newHeight) {
      height.value = newHeight
    }
  }, 400)
}

let loadingTimeout: number | null = null

onMounted(() => {
  window.addEventListener('message', onIframeMessage)
  window.addEventListener('resize', onWindowResize)
  isMounted.value = true

  loadingTimeout = window.setTimeout(() => {
    isLoading.value = false
  }, 4000)
})

onBeforeUnmount(() => {
  window.removeEventListener('message', onIframeMessage)
  window.removeEventListener('resize', onWindowResize)
  window.clearTimeout(timeout)
  if (loadingTimeout) {
    window.clearTimeout(loadingTimeout)
  }
})
</script>
