<template>
  <SearchProvider
    :index="SearchIndex.People"
    :facet-fields="{ locality: 'Standort' }"
    :per-page="1000"
    group-by="category"
    sort-by="last_name"
    default-all
    :map-groups="mapGroups"
    :search-white="index !== 0"
  >
    <template #item="{ item }">
      <div
        :id="(item.url || '').split('#')[1]"
        class="md:flex py-15 md:py-20 border-b border-b-gray-300 gap-20 scroll-mt-112 target:bg-primary-50"
      >
        <div class="float-right md:float-none ml-5 md:ml-0">
          <RokkaImage
            v-if="item.image"
            :hash="item.image"
            :config="imageStyle"
            class="w-[100px] md:w-[280px] aspect-[4/3]"
            loading="lazy"
          />
        </div>
        <div class="flex flex-col">
          <h3 class="typo-h3">{{ item.first_name }} {{ item.last_name }}</h3>
          <ul
            v-if="item.details?.length"
            class="text-gray-500 font-bold typo-lead-3 mb-8"
          >
            <li v-for="detail in item.details" :key="detail">{{ detail }}</li>
          </ul>

          <div class="mt-auto">
            <div v-if="item.phone">
              <a :href="getPhoneLink(item.phone)" class="link">
                <SpriteSymbol name="phone" />
                {{ item.phone }}
              </a>
            </div>

            <div v-if="item.email">
              <a :href="getMailLink(item.email)" class="link">
                <SpriteSymbol name="email" />
                {{ item.email }}
              </a>
            </div>

            <div v-if="item.show_consultancy && item.nid">
              <NuxtLink
                :to="{
                  name: 'wizard-consultation-form',
                  params: { id: item.nid },
                }"
                class="link"
              >
                <SpriteSymbol name="contactperson" />
                {{
                  $texts(
                    'person_teaser_create_consultation',
                    'Beratungstermin vereinbaren',
                  )
                }}
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SearchProvider>
</template>

<script lang="ts" setup>
import { SearchIndex } from '#graphql-operations'
import type { ResultGroup } from '~/components/Search/Provider.vue'

const { $texts } = useEasyTexts()

const { index } = defineBlokkliFragment({
  name: 'people',
  label: 'Menschen',
  description: 'Listet die Berater und Beraterinnen auf',
  editor: {
    noPreview: true,
  },
})

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    sm: 100,
    md: 280,
  },
})

const { data: weights } = await useAsyncGraphqlQuery('categoryWeights', null, {
  graphqlCaching: {
    client: true,
  },
  transform: function (data) {
    return (data.data.termWeights || []).reduce<Record<string, number>>(
      (acc, v) => {
        if (v.label && typeof v.weight === 'number') {
          acc[v.label] = v.weight
        }
        return acc
      },
      {},
    )
  },
})

function mapGroups(groups: ResultGroup<any>[]) {
  if (!weights.value) {
    return groups
  }
  return [...groups].sort((a, b) => {
    const wa = weights.value![a.name]
    const wb = weights.value![b.name]

    return wa - wb
  })
}

function getPhoneLink(phone: string) {
  return 'tel:' + phone.replaceAll(' ', '')
}

function getMailLink(mail: string) {
  return 'mailto:' + mail
}
</script>
