<template>
  <div class="min-h-44 mt-16">
    <BlokkliField
      :list="paragraphs"
      name="field_paragraphs"
      class="flex gap-16 flex-wrap items-center"
      :class="{
        'col-start-2 col-end-[-2]': !parentType,
        'flex-col': options.alignment === 'vertical',
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphLinksFragment } from '#graphql-operations'

defineProps<{
  paragraphs?: ParagraphLinksFragment['paragraphs']
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'links',
  options: {
    alignment: {
      type: 'radios',
      label: 'Anordnung',
      default: 'horizontal',
      options: {
        horizontal: 'Horizontal',
        vertical: 'Vertikal',
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})
</script>
