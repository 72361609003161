<template>
  <div class="absolute top-0 left-0 w-full">
    <header
      class="layout-grid relative z-[150] md:mb-64 xl:mb-96 bg-white md:bg-transparent border-b-4 border-b-primary-500 md:border-b-0 py-16 md:py-0"
    >
      <div
        class="col-start-1 col-end-[-1] md:pt-20 lg:pt-28 xl:pt-[34px] layout-grid"
      >
        <Logo
          class="transition col-start-2 xl:col-start-1 col-span-5 md:col-span-3 md:col-start-2 xl:col-span-4 2xl:col-span-3 xl:ml-[39px] focusable flex items-end xl:pb-4"
          :class="textClass"
        />
        <Transition :name="getTransition()">
          <div
            v-show="menuOpen"
            class="gap-40 md:!flex bg-white md:bg-transparent fixed md:static w-full md:w-auto inset-0 md:inset-auto print:hidden col-end-[-2] col-start-5 items-end lg:col-start-5 xl:col-start-5 2xl:col-start-4 xl:col-end-[-1] lg:mt-[22px] xl:mt-[26px]"
          >
            <ul class="flex gap-20 items-end justify-between md:justify-start">
              <li v-for="(link, i) in links" :key="i" class="block">
                <button
                  class="leading-none font-bold text-lg lg:text-2xl block p-16 md:p-0 transition relative focusable"
                  :class="[
                    {
                      'opacity-50 hover:opacity-80': activeIndex !== i,
                      'nav-caret-bottom': activeIndex === i,
                    },
                    textClass,
                  ]"
                  @click="setActiveFirstLevel(i)"
                >
                  {{ link.label }}
                </button>
                <AppHeaderFirstLevel
                  v-show="activeIndex === i && (!searchOpen || isMobile)"
                  :links="link.links"
                  v-model="activeId"
                />
              </li>
            </ul>

            <a
              href="https://wwwsec.shkb.ch/authen/login"
              class="leading-none font-bold text-lg lg:text-2xl items-baseline ml-auto hidden md:flex transition focusable"
              :class="textClass"
              target="_blank"
              rel="nofollow"
            >
              <SpriteSymbol name="lock" class="size-[1em] mr-5" />
              <span>E-Banking</span>
            </a>

            <button
              class="leading-none font-bold text-lg lg:text-2xl items-baseline hidden md:flex transition xl:mr-32 lg:-translate-y-4 focusable"
              :class="textClass"
              @click="searchOpenOnDesktop = !searchOpenOnDesktop"
            >
              <SpriteSymbol name="search" class="size-[1em] mr-5 block" />
              <span class="sr-only">Suche öffnen</span>
            </button>
          </div>
        </Transition>
        <Transition :name="getTransition()">
          <AppHeaderSearch v-if="searchOpen" />
        </Transition>
      </div>

      <Teleport to="body">
        <Transition
          enter-active-class="duration-500 ease-out"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-500 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="activeId || searchOpen"
            class="fixed top-0 left-0 size-full bg-gray-100/85 z-[100]"
            @click="onClickOverlay"
          />
        </Transition>
      </Teleport>
    </header>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'

const data = await useInitData()

const links = computed(() => data.value.mainMenuLinks)
const breadcrumbLinks = useBreadcrumbLinks()

const { openMobilePane, closeMobilePane, hasTransition } = useMenu()

const route = useRoute()

watch(
  () => route.path,
  () => {
    searchOpenOnDesktop.value = false
    activeId.value = 0
    // Reset the active index override, so that we will be using the index
    // determined from menu links / breadcrumb of the current page.
    activeIndexOverride.value = -1
    closeMobilePane()
  },
)

const searchOpenOnDesktop = ref(false)

function getTransition() {
  if (hasTransition.value) {
    return 'mobile-menu-pane'
  }
  return undefined
}

const searchOpen = computed(() => {
  if (isMobile.value) {
    return openMobilePane.value === 'search'
  }

  return searchOpenOnDesktop.value
})

const textClass = computed(() => {
  if (!activeId.value && !searchOpen.value) {
    return 'text-header-text'
  }

  return 'text-black'
})

// The override for the active index. Set when the user clicks on "Privatkunden
// " or "Firmenkunden", which do not actually change the route.
const activeIndexOverride = ref(-1)

const activeIndexInMenu = computed<number>(() => {
  return links.value.findIndex((link) => {
    if (link.url === route.path) {
      return true
    }

    return link.links.some((sublink) => {
      if (sublink.url === route.path) {
        return true
      }

      if (sublink.links.length) {
        return sublink.links.some((subsublink) => {
          if (subsublink.url === route.path) {
            return true
          }

          if (subsublink.links.length) {
            return subsublink.links.some((v) => v.url === route.path)
          }

          return false
        })
      }

      return false
    })
  })
})

const activeIndexInBreadcrumb = computed<number>(() => {
  const fromBreadcrumb = breadcrumbLinks.value[1]
  if (fromBreadcrumb) {
    return links.value.findIndex((v) => v.label === fromBreadcrumb.title)
  }
  return -1
})

// The currently active first level index.
const activeIndex = computed(() => {
  // Override is set when the user clicks on one of the first level links (Privatkunden, Firmenkunden, Info).
  if (activeIndexOverride.value >= 0) {
    return activeIndexOverride.value
  }

  // If we found an item in the index, use that.
  if (activeIndexInMenu.value >= 0) {
    return activeIndexInMenu.value
  }

  // Fallback to using an index determined by traversing up the breadcrumb to fin "Privatkunden", "Firmenkunden" or "Info".
  // If it doesn't exist, fallback to index 0 which is "Privatkunden".
  return Math.max(activeIndexInBreadcrumb.value, 0)
})

const activeId = useState('navbar_active_second_level', () => 0)
const { isMobile } = useViewport()

const menuOpen = computed(
  () => !isMobile.value || openMobilePane.value === 'menu',
)

function onClickOverlay() {
  activeId.value = 0
  searchOpenOnDesktop.value = false
}

function setActiveFirstLevel(index: number) {
  activeId.value = 0
  activeIndexOverride.value = index
}

onKeyStroke('Escape', function () {
  searchOpenOnDesktop.value = false
})
</script>

<style lang="postcss">
.nav-caret-bottom {
  &:after {
    content: '';
    @apply absolute bottom-0 left-1/2 -translate-x-1/2 md:-bottom-32;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent theme('colors.primary.500')
      transparent;
  }
}

.text-header-text {
  @apply text-black;

  html.has-header-inverted & {
    @apply md:text-white;
  }
}
</style>
