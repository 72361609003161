<template>
  <table v-if="showOpeningHours" class="opening-hours-table w-full">
    <tbody>
      <tr v-for="row in table" :key="row.day">
        <th>{{ row.name }}</th>
        <template v-if="row.slots.length === 0">
          <td colspan="2">Geschlossen</td>
        </template>
        <template v-else-if="row.slots.length === 1">
          <td colspan="2">{{ row.slots[0].start }} - {{ row.slots[0].end }}</td>
        </template>
        <template v-else>
          <td v-for="(slot, index) in row.slots" :key="row.name + index">
            {{ slot.start }} - {{ slot.end }}
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'

interface OpeningHoursDay {
  day?: number
  start?: number
  end?: number
  comment?: string
}

const WEEKDAYS = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
]

interface WeekdaySlot {
  start: string
  end: string
}

interface WeekdayTableRow {
  day: number
  name: string
  slots: WeekdaySlot[]
}

const props = defineProps<{
  days: OpeningHoursDay[]
}>()

const table = computed(() => {
  // Move the first item (sunday) to the end, so that the week starts on
  // monday.
  const weekdaysSorted = [...WEEKDAYS.slice(1), WEEKDAYS[0]]

  // Create a map of every weekday.
  const weekdays = weekdaysSorted.reduce<Record<string, WeekdayTableRow>>(
    (acc, v, index) => {
      acc[v] = {
        day: index,
        name: v,
        slots: [],
      }

      return acc
    },
    {},
  )
  const days = props.days || []

  const map = days.reduce((acc, day) => {
    if (notNullish(day.day)) {
      const weekday = WEEKDAYS[day.day]
      if (acc[weekday]) {
        const mapped = mapDay(day)
        if (mapped) {
          acc[weekday].slots.push(mapped)
        }
      }
    }
    return acc
  }, weekdays)

  return Object.keys(map)
    .map((key) => map[key])
    .sort((a, b) => (a.day < b.day ? -1 : 1))
})

const showOpeningHours = computed(() => props.days.length > 1)

function mapDay(day: OpeningHoursDay): WeekdaySlot | undefined {
  if (!notNullish(day.start) || !notNullish(day.end)) {
    return
  }
  const start = formatTime(day.start)
  let end = formatTime(day.end)

  if (end === '00:00') {
    end = '24:00'
  }

  return {
    start,
    end,
  }
}

function formatTime(time: number) {
  if (!Number.isInteger(time)) {
    return ''
  }
  const str = time.toString().padStart(4, '0')
  return [str.slice(0, 2), str.slice(2)].join(':')
}
</script>

<style lang="postcss">
.opening-hours-table {
  td,
  th {
    @apply border-b border-b-gray-300 py-4 text-left;
  }
}
</style>
