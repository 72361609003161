<template>
  <VuepalLink
    v-if="title"
    :to="link?.uri?.path"
    class="block rounded-full relative max-w-[300px] will-change-transform mx-auto transition ease-swing duration-300 hover:-rotate-2 group eyecatcher"
  >
    <BlokkliEditable
      v-slot="{ value }"
      name="field_title"
      :value="title"
      class="w-full aspect-square relative z-20"
    >
      <Eyecatcher
        :text="value"
        :font-size="options.fontSize"
        :inverted="parentBackground === 'yellow'"
      />
    </BlokkliEditable>
  </VuepalLink>
</template>

<script lang="ts" setup>
import type { ParagraphEyecatcherFragment } from '#graphql-operations'

defineProps<{
  title?: string
  link?: ParagraphEyecatcherFragment['link']
}>()

const { options } = defineBlokkli({
  bundle: 'eyecatcher',
  options: {
    fontSize: {
      type: 'radios',
      label: 'Schriftgrösse',
      default: 'm',
      options: {
        xs: 'XS',
        s: 'S',
        m: 'M',
        l: 'L',
        xl: 'XL',
      },
    },
  },
})

const { parentBackground } = useParagraphContext()
</script>

<style lang="postcss">
.eyecatcher {
  &:before {
    content: '';
    @apply absolute inset-0 transition ease-swing duration-300 scale-75 z-0 opacity-0;
    box-shadow: 0 0 28px 3px theme('colors.gray.300');
    border-radius: inherit;
  }

  &:hover:before {
    @apply opacity-100 scale-100;
  }
  &:hover {
    @apply scale-105;
  }
}
</style>
