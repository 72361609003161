<template>
  <VuepalLink
    v-slot="{ isExternal }"
    :to="to"
    class="flex gap-8 md:gap-20 xl:gap-24 items-center flex-row-reverse md:flex-row focusable online-service"
    :class="{ group: to }"
  >
    <div
      class="w-56 lg:w-80 xl:w-[92px] aspect-square bg-gray-100 text-gray-800 flex items-center justify-center rounded-full shrink-0"
      :class="{
        'group-hover:bg-primary-300 group-hover:text-primary-900 group-focus:bg-primary-300 group-focus:text-primary-900':
          to,
      }"
    >
      <TaxonomyTermIcons v-bind="icon" class="size-1/2 fill-current" />
    </div>
    <div class="flex-1">
      <div class="font-bold text-base md:text-xl text-balance">
        <span
          class="group-hover:underline underline-offset-4 decoration-gray-300"
          >{{ title }}</span
        >&nbsp;<SpriteSymbol
          v-if="to"
          name="arrow-right"
          class="inline-block size-[0.6em] align-baseline"
        />
      </div>
      <div
        class="text-gray-800 text-sm md:text-base lg:text-lg"
        :class="{ 'group-hover:text-gray-950': to }"
        v-html="$sup(description)"
      />
      <span
        v-if="isExternal"
        class="text-xs uppercase font-heavy bg-gray-100 inline-block px-5 py-3 rounded-full text-gray-700"
      >
        Externe Seite
      </span>
    </div>
  </VuepalLink>
</template>

<script lang="ts" setup>
import type { TaxonomyTermOnlineServiceFragment } from '#build/graphql-operations'

const props = defineProps<{
  title?: string
  description?: string
  icon?: TaxonomyTermOnlineServiceFragment['icon']
  link?: TaxonomyTermOnlineServiceFragment['link']
}>()

const to = computed(() => props.link?.uri?.path)
const { $sup } = useNuxtApp()
</script>
