<template>
  <SearchProvider
    :index="SearchIndex.Publications"
    :facet-fields="{
      category: 'Kategorie',
      year: 'Jahr',
    }"
    group-by="year"
    sort-by="date"
    :sort-order="SearchSortOrder.DESC"
    :per-page="1000"
    :map-groups="mapGroups"
    :filters="{
      publication_type: 'in_the_media',
    }"
    default-all
    :search-white="index !== 0"
  >
    <template #item="{ item }">
      <VuepalLink
        :to="item.url"
        class="flex gap-24 py-24 border-b border-b-gray-300 hover:bg-gray-50 pr-10 group"
      >
        <div class="typo-h4 text-gray-500 w-[150px] shrink-0">
          {{ item.date?.formatted }}
        </div>
        <div>
          <h3 class="typo-h4 font-bold" v-html="$sup(item.title)" />
          <div class="typo-lead-3 text-gray-500" v-html="$sup(item.teaser)" />
          <div class="link mt-32">Als PDF herunterladen</div>
        </div>
      </VuepalLink>
    </template>
  </SearchProvider>
</template>

<script lang="ts" setup>
import { SearchIndex, SearchSortOrder } from '#graphql-operations'
import type { ResultGroup } from '~/components/Search/Provider.vue'

const { index } = defineBlokkliFragment({
  name: 'media',
  label: 'In den Medien',
  description: 'Listet die «in den Medien» Publikationen auf',
  editor: {
    noPreview: true,
  },
})

const { $sup } = useNuxtApp()

function mapGroups(groups: ResultGroup<any>[]) {
  return groups.sort((a, b) => b.name.localeCompare(a.name))
}
</script>
