import { defineNuxtPlugin } from '#app'

function getScript(piwikKey: string) {
  return `
(function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="https://shkb.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', '${piwikKey}');
`
}

export default defineNuxtPlugin({
  name: 'shkb-analytics',
  setup(nuxtApp) {
    const config = useRuntimeConfig().public
    const piwikKey = config.piwikKey
    const cookieLawDomain = config.cookieLawDomain
    useHead({
      script: [
        {
          key: 'cookielaw-first',
          type: 'text/javascript',
          src: `https://cdn.cookielaw.org/consent/${cookieLawDomain}/OtAutoBlock.js`,
          tagPriority: 9000,
        },
        {
          key: 'cookielaw-second',
          type: 'text/javascript',
          src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          'data-document-language': 'true',
          'data-domain-script': cookieLawDomain,
          tagPriority: 9001,
        },
        {
          key: 'cookielawInit',
          innerHTML: `function OptanonWrapper() { }`,
          tagPriority: 9002,
        },
        {
          key: 'analytics-load',
          innerHTML: getScript(piwikKey),
          tagPriority: 9003,
        },
        {
          key: 'aiaibot',
          type: 'text/javascript',
          src: 'https://chat.aiaibot.com/bootstrap.js',
          defer: true,
          'data-aiaibot-key': 'bb00ca74-efc1-4739-902d-e99a80572b4c',
          tagPriority: 9004,
          tagPosition: 'bodyClose',
        },
      ],
    })

    // Where the dataLayer stuff is stored during SSR. We attach it to the
    // head after the page was rendered.
    const ssrDataLayer = useState<any[]>('ssrDataLayer', () => [])

    if (import.meta.server) {
      nuxtApp.hook('app:rendered', (ctx) => {
        ctx.ssrContext?.head.push({
          script: [
            {
              children: `window.dataLayer = ${JSON.stringify(
                ssrDataLayer.value,
              )}`,
              tagPriority: -100,
            },
          ],
        })
        ssrDataLayer.value = []
      })
    }

    const router = useRouter()
    const isInitial = useState<boolean>('_is_ssr', () => true)
    const { trackSwitchPage } = useAnalytics()

    // The event is emitted by useDrupalRoute().
    nuxtApp.hook('drupal-route', (ctx) => {
      // Don't do anything on the server.
      if (import.meta.server) {
        return
      }

      // This was explicitly desired by analytics: The event should *not*
      // trigger on initial page load.
      if (isInitial.value) {
        isInitial.value = false
        return
      }
      if (!ctx.drupalRoute) {
        return
      }

      trackSwitchPage(
        router.currentRoute.value,
        router.options.history.state.back?.toString(),
        {
          pageTitle: ctx.metatags.title || ('' as any),
          entityType: ctx.drupalRoute.entityType || '',
          entityBundle: ctx.drupalRoute.entityBundle || '',
          entityId: ctx.drupalRoute.entityId || '',
          nodeId: ctx.drupalRoute.entityId || '',
        },
      )
    })

    router.afterEach((to, from) => {
      if (to.meta.drupalRoute || to.meta.drupalFrontendRoute) {
        return
      }
      setTimeout(() => {
        trackSwitchPage(to, from.fullPath, {
          pageTitle: import.meta.client ? document.title : undefined,
        })
      }, 500)
    })
  },
})
