import { defineEasyTextsLoader } from '#nuxt-easy-texts/types'

export default defineEasyTextsLoader(() => {
  return {
    async load() {
      const data = await useInitData()
      return data.value.translations
    },
    reloadTrigger() {
      return computed(() => 'de')
    },
  }
})
