<template>
  <div class="bg-gray-50 layout-grid">
    <div class="col-start-2 col-end-[-2]">
      <OnlineServicesSearch />
      <ul class="flex gap-24 md:gap-10 justify-between overflow-auto">
        <li v-for="(link, i) in links" :key="i">
          <a :href="link.href" class="font-bold link py-20 whitespace-nowrap">
            {{ link.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphOnlineServicesFragment } from '#graphql-operations'
import OnlineServicesSearch from './Search.vue'

const { rootBlocks } = defineBlokkliFragment({
  name: 'online_services_overview',
  label: 'Online Services Overview',
  description: 'Zeigt das Suchfeld für Online Services an',
  editor: {
    noPreview: true,
  },
})

const links = computed(() => {
  return rootBlocks.value
    .map((v) => {
      if (v.bundle !== 'online_services') {
        return null
      }
      const props = v.props as ParagraphOnlineServicesFragment
      const service = props.onlineService
      if (!service) {
        return null
      }

      return {
        label: service.title,
        href: '#' + slugify(service.title),
      }
    })
    .filter(notNullish)
})
</script>
