<template>
  <VuepalLink
    :to="url"
    class="tile flex flex-col items-center font-bold h-full text-center focusable justify-center min-h-[120px] md:min-h-[150px] relative"
    :title="title"
  >
    <TaxonomyTermIcons
      v-if="icon"
      :id="icon?.id"
      class="size-36 md:size-44 mb-36 md:mb-40"
    />
    <div
      class="!leading-[1.1] line-clamp-2 hyphens-auto text-sm xl:text-base text-balance absolute left-0 w-full bottom-0 pb-12 px-8 lg:pb-15"
      v-html="$sup(title)"
    />
  </VuepalLink>
</template>

<script setup lang="ts">
import type { TaxonomyTermIconsFragment } from '#build/graphql-operations'

defineProps<{
  icon?: TaxonomyTermIconsFragment
  title: string
  url: string
}>()

const { $sup } = useNuxtApp()
</script>
