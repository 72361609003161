<template>
  <div v-if="items">
    <VuepalLink
      v-for="item in items"
      :key="item.id"
      :to="item.url?.path"
      class="block py-8 border-b border-b-gray-300 hover:bg-gray-50 text-sm md:text-base"
    >
      <div>{{ item.date?.formatted }}</div>
      <div class="font-bold" v-html="$sup(item.title)" />
    </VuepalLink>

    <VuepalLink
      v-if="newsUrl"
      :to="newsUrl"
      class="button is-primary is-small mt-16"
    >
      Alle News anzeigen
    </VuepalLink>
  </div>
</template>

<script setup lang="ts">
import { notNullish } from '@vueuse/core'

const data = await useInitData()

const newsUrl = computed(() => data.value.globalConfig.pageNews)

const { data: items } = await useAsyncData('followMenuNews', () =>
  useCachedGraphqlQuery('followMenuNews').then((v) => {
    return v.data.entityQuery.items
      ?.map((v) => {
        if ('date' in v) {
          return v
        }
        return null
      })
      .filter(notNullish)
  }),
)

const { $sup } = useNuxtApp()
</script>
