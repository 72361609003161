<template>
  <div class="overflow-auto">
    <table class="comparison-table w-full">
      <thead>
        <tr>
          <th />
          <th v-for="node in productData" :key="node.id">
            <div class="text-xl">{{ node.title }}</div>
            <div v-if="typeof node.price === 'number'">
              {{ formatCurrency(node.price) }}/Monat
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- eslint-disable vue/require-v-for-key, vue/valid-v-for -->
        <template v-for="table in tables.groups">
          <tr class="comparison-table-header">
            <th>
              {{ table.label }}
            </th>
            <td />
            <td />
          </tr>
          <tr v-for="term in table.children" :key="term.label">
            <th>{{ term.label }}</th>
            <td
              v-for="(row, i) in term.rows"
              :key="table.label + term.label + i"
              v-html="$sup(row)"
            />
          </tr>
        </template>
      </tbody>
    </table>

    <Footnotes
      v-if="tables.footnotes"
      :footnotes="tables.footnotes"
      :namespace="namespace"
      class="mt-24 md:mt-36 lg:mt-40"
    />
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import { formatCurrency } from '~/helpers/numbers'

type FootnoteContext = {
  footnotes: Record<string, string>
  mapping: Record<string, { rowIndex: number; index: number; original: number }>
  index: number
}

const props = defineProps<{
  nids: string[]
  namespace: string
}>()

const { $sup } = useNuxtApp()

function extractFootnotes(
  v: string,
  rowIndex: number,
  context: FootnoteContext,
) {
  function replace(numbers: string): string {
    return numbers
      .split(',')
      .map((num: string): { index: number; text: string } | null => {
        const id = num.trim()
        // Build the key.
        const key = rowIndex + ':' + id

        // Get the text for the footnote.
        const text = context.footnotes[key]

        // If no text exists, we have to skip the footnote.
        if (!text) {
          return null
        }

        // See if there is an existing index.
        let index = context.mapping[text]?.index

        // No index: Increment the index to get the current index.
        if (!index) {
          context.index++
          index = context.index
        }

        // Store the index.
        context.mapping[text] = { rowIndex, index, original: parseInt(id) }
        return {
          index,
          text: `<a class="foot-note-link" href="#${props.namespace}-${index}" title="Zur Fussnote Nummer ${index}">${index}</a>`,
        }
      })
      .filter(notNullish) // Remove unmapped footnotes.
      .sort((a, b) => a.index - b.index) // Sort by index so that the footnotes are always in the right order.
      .map((v) => v.text)
      .join('')
  }
  return v.replace(/\{(\d+(?:,\s*\d+)*)\}/g, (_match, numbers) => {
    return replace(numbers)
  })
}

const { data: terms } = await useAsyncGraphqlQuery(
  'productAttributeTerms',
  null,
  {
    graphqlCaching: {
      client: true,
    },
    transform: function (data) {
      return (
        data.data.terms.items
          ?.map((v) => (v && 'id' in v ? v : null))
          .filter(notNullish) || []
      )
    },
  },
)

const { data: productData } = await useAsyncGraphqlQuery(
  'productComparison',
  {
    nids: props.nids,
  },
  {
    graphqlCaching: {
      client: true,
    },
    transform: function (data) {
      return (
        data.data.entityQuery.items
          ?.map((v) => (v && 'attributes' in v ? v : null))
          .filter(notNullish) || []
      ).sort((a, b) => {
        const indexA = props.nids.indexOf(a.id!)
        const indexB = props.nids.indexOf(b.id!)
        return indexA - indexB
      })
    },
  },
)

const productsMapped = computed<Record<string, string[]>>(() => {
  return (productData.value || []).reduce<Record<string, string[]>>(
    (acc, node) => {
      node.attributes?.list?.forEach((item) => {
        if (item.id && item.text && node.id) {
          if (!acc[item.id]) {
            acc[item.id] = []
          }

          acc[item.id].push(item.text)
        }
      })
      return acc
    },
    {},
  )
})

function getFootnoteMapping(): Record<string, string> {
  return (productData.value || []).reduce<Record<string, string>>(
    (acc, node, index) => {
      node.footnotes?.forEach((note, noteIndex) => {
        const key = `${index}:${noteIndex + 1}`
        acc[key] = note
      })
      return acc
    },
    {},
  )
}

const tables = computed(() => {
  const context: FootnoteContext = {
    footnotes: getFootnoteMapping(),
    mapping: {},
    index: 0,
  }
  const groups = terms.value
    ?.map((term) => {
      if (term.label && term.children && term.id) {
        const children = term.children
          .map((child) => {
            if (child?.id) {
              const rows = productsMapped.value[child.id]
              // Only if at least two products share the same attribute term do we want to add the rows.
              if (rows && rows.length > 1) {
                return {
                  label: child.label,
                  rows,
                  weight: child.weight || 0,
                }
              }
            }
            return null
          })
          .filter(notNullish)
          .sort((a, b) => {
            return a.weight - b.weight
          })

        // Only show group if there are children.
        if (children.length) {
          return {
            weight: term.weight || 0,
            label: term.label,
            children,
          }
        }
      }

      return null
    })
    .filter(notNullish)
    .sort((a, b) => {
      return a.weight - b.weight
    })
    .map((group) => {
      return {
        label: group.label,
        children: group.children.map((child) => {
          return {
            label: child.label,
            rows: child.rows.map((row, index) => {
              return extractFootnotes(row, index, context)
            }),
          }
        }),
      }
    })

  const footnotes = Object.values(context.mapping)
    .sort((a, b) => a.index - b.index)
    .map((data) => {
      return productData.value?.[data.rowIndex]?.footnotes?.[data.original - 1]
    })
    .filter(notNullish)

  return { groups, footnotes }
})
</script>

<style lang="postcss">
.comparison-table {
  td,
  th {
    @apply text-left py-10;
  }

  tbody {
    td,
    th {
      @apply border-b border-b-gray-300 lg:text-lg;
      @apply lg:w-1/3;
    }

    th {
      @apply pr-16;
    }
  }

  .comparison-table-header {
    th,
    td {
      @apply border-b border-b-black pt-24 text-xl lg:pt-32;
    }

    &:first-child th {
      @apply pt-0;
    }
  }

  td,
  th {
    &:nth-child(2),
    &:nth-child(3) {
      @apply min-w-[180px] pl-16;
    }
    &:nth-child(2) {
      @apply bg-gray-100 px-16;
    }
  }
}
</style>
