<template>
  <VuepalLink
    :to="url"
    class="flex flex-col bg-white border relative teaser-product overflow-hidden hover:border-gray-400 transition hover:scale-105 will-change-transform ease-swing duration-500 hover:shadow-xl hover:-translate-y-8 focusable"
    :class="{
      'border-primary-700 before:!bg-gray-400 after:!bg-primary-700':
        parentBackground === 'yellow',
      'border-gray-300':
        parentBackground === 'white' ||
        parentBackground === 'grayLight' ||
        parentBackground === 'grayDark',
    }"
  >
    <div class="p-16 lg:p-20 flex-1">
      <TaxonomyTermIcons
        v-if="icon"
        v-bind="icon"
        class="float-right size-40 ml-8"
      />
      <h3 class="text-lg lg:text-xl font-bold" v-html="$sup(title)" />
      <p v-if="price" class="font-bold text-gray-500" v-html="$sup(price)" />
      <div v-if="text?.length" class="mt-15">
        <div
          v-if="typeof text === 'string'"
          class="lg:text-lg"
          v-html="$sup(text)"
        />
        <FeatureList v-else :features="text" />
      </div>
    </div>
    <div v-if="showCta" class="button is-primary">
      <div v-html="$sup(buttonTitle)" />
      <SpriteSymbol name="cta-circle" class="ml-auto" />
    </div>
  </VuepalLink>
</template>

<script setup lang="ts">
import type { TaxonomyTermIconsFragment } from '#graphql-operations'

const props = withDefaults(
  defineProps<{
    title: string
    price?: string
    icon?: TaxonomyTermIconsFragment
    url?: string
    text?: string | string[]
    parentBackground?: BackgroundColor
    showCta?: boolean
    ctaText?: string
  }>(),
  {
    parentBackground: 'white',
    price: '',
    icon: undefined,
    url: '',
    text: '',
    ctaText: '',
  },
)

defineOptions({
  name: 'TeaserProduct',
})

const { $texts } = useEasyTexts()
const { $sup } = useNuxtApp()

const buttonTitle = computed(
  () => props.ctaText || $texts('product_teaser_default_cta', 'Mehr erfahren'),
)
</script>

<style lang="postcss">
.teaser-product {
  &:before,
  &:after {
    content: '';
    @apply absolute bottom-0 h-4 left-0 w-full;
  }
  &:before {
    @apply bg-gray-200;
  }
  &:after {
    @apply bg-primary-500;
    @apply transition ease-swing duration-500;
    @apply origin-left;
    @apply scale-x-50 opacity-0 will-change-transform;
  }
  &:hover:after {
    @apply scale-100 opacity-100;
  }
}
</style>
