<template>
  <FollowMenu v-if="data?.paragraphs" :paragraphs="data.paragraphs" />
</template>

<script setup lang="ts">
import {
  INJECT_ENTITY_CONTEXT,
  INJECT_PROVIDER_CONTEXT,
} from '#blokkli/helpers/symbols'
import type { BlokkliProviderEntityContext } from '#blokkli/types'

const { data } = await useLazyAsyncData(() =>
  useCachedGraphqlQuery('followMenu').then((v) => v.data.followMenuConfig),
)

const blokkliProviderEntityContext = computed<BlokkliProviderEntityContext>(
  () => {
    return {
      uuid: data.value?.uuid || '',
      type: 'config_pages',
      bundle: 'follow_menu',
      language: 'de',
    }
  },
)

provide(INJECT_PROVIDER_CONTEXT, blokkliProviderEntityContext)
provide(INJECT_ENTITY_CONTEXT, {
  uuid: data.value?.uuid,
  type: 'config_pages',
  bundle: 'follow_menu',
})
</script>
