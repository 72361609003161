<template>
  <div class="bk-main-canvas">
    <ClientOnly>
      <div class="hidden lg:block">
        <LazyVuepalAdminToolbar v-if="drupalUser.accessToolbar && !isEditing" />
        <LazyAdminBar v-if="drupalUser.accessToolbar && !isEditing" />
      </div>
    </ClientOnly>

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <div
      v-if="drupalUser.accessToolbar"
      id="shkb-debug-wrapper"
      class="fixed left-0 top-0 h-full bg-white max-w-[500px] z-[999999999] overflow-auto"
    />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = useDrupalUser()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)
</script>

<style lang="postcss">
html.bk-is-artboard .bk-main-canvas {
  max-width: none !important;
  width: calc(100vw - 20rem) !important;
  @apply bg-gray-50;
}

.mobile-menu-pane-enter-active,
.mobile-menu-pane-leave-active {
  @apply transition origin-top duration-300;
}
.mobile-menu-pane-enter-from,
.mobile-menu-pane-leave-to {
  @apply translate-y-full md:-translate-y-full md:opacity-0;
}

.layout-grid > .bk-field-list-proxy {
  grid-column: 1 / -1;
}
</style>
