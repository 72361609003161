import type {
  FieldItemTypeLinkWithEntityFragment,
  FieldItemTypeLinkWithEntityLinkedEntityFragment,
  TaxonomyTermIconsFragment,
} from '#build/graphql-operations'

export type LinkedEntityData = {
  title?: string
  icon?: TaxonomyTermIconsFragment
}

function getLinkedEntity(
  uri: FieldItemTypeLinkWithEntityFragment['uri'],
): FieldItemTypeLinkWithEntityLinkedEntityFragment | undefined {
  if (uri && 'entity' in uri) {
    return uri.entity
  }
}

export function getLinkedEntityData(
  data: FieldItemTypeLinkWithEntityFragment,
): LinkedEntityData {
  const entity = getLinkedEntity(data.uri)
  const title = data.title || entity?.label
  const icon = entity && 'icon' in entity ? entity.icon : undefined
  return { title, icon }
}
