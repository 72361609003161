import sup from '~/helpers/sup'

export default defineNuxtPlugin({
  name: 'shkb-sup',
  setup() {
    return {
      provide: {
        sup(v: string | undefined | null): string {
          if (!v) {
            return ''
          }
          return sup(v)
        },
      },
    }
  },
})

declare module '#app' {
  interface NuxtApp {
    $sup: (v: string | undefined | null) => string
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $sup: (v: string | undefined | null) => string
  }
}
