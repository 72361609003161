<template>
  <div>
    <div
      v-if="!hideSearch"
      class="layout-grid"
      :class="searchWhite ? 'bg-white' : 'bg-gray-50 py-44'"
    >
      <div class="col-start-2 col-end-[-2]">
        <SearchInput v-model.lazy="text" />
      </div>
    </div>
    <div
      v-if="defaultAll || items.length || text.length"
      class="layout-grid bg-white section-padding"
    >
      <div
        v-if="facets.length"
        class="col-start-2 lg:col-start-11 col-end-[-2] lg:row-start-1"
      >
        <button
          class="button w-full lg:hidden"
          @click="filterVisible = !filterVisible"
        >
          Filter anzeigen
        </button>
        <div v-show="filterVisible" class="lg:sticky lg:top-16 mt-16 lg:!block">
          <SearchFacet
            v-for="facet in facets"
            :key="facet.name"
            v-bind="facet"
            :label="(facetFields || {})[facet.name]"
            :selected="selected"
          />
        </div>
      </div>
      <div class="col-start-2 col-end-[-2] lg:col-end-10 lg:row-start-1">
        <div
          :class="{
            'opacity-50': pending,
          }"
        >
          <div v-if="!items.length && !pending">
            {{
              $texts(
                'search_no_results',
                'Es konnten keine Suchergebnisse gefunden werden.',
              )
            }}
          </div>
          <div v-if="groups">
            <div v-for="(group, i) in groups" :key="group.name">
              <h3
                class="text-3xl font-bold border-b border-b-gray-300 bg-white sticky top-0 py-20 z-50"
                :class="{
                  'mt-32': i !== 0,
                }"
              >
                {{ group.name }}
              </h3>
              <ul>
                <li v-for="item in group.items" :key="item.uuid">
                  <slot name="item" :item="item" />
                </li>
              </ul>
            </div>
          </div>
          <ul v-else>
            <li v-for="item in items" :key="item.uuid">
              <slot name="item" :item="item" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type {
  SearchFacetFragment,
  SearchItemFulltextFragment,
  SearchItemPeopleFragment,
  SearchItemNewsFragment,
  SearchItemEventFragment,
  SearchItemBancomatFragment,
  SearchItemPublicationFragment,
  SearchResponseFragment,
  SearchIndex,
  SearchSortOrder,
} from '#graphql-operations'
export type ResultGroup<T> = {
  name: string
  items: T[]
}
</script>

<script setup lang="ts" generic="T extends SearchIndex">
const filterVisible = ref(false)
const text = useQueryString('text')
const selectedString = useQueryString('f')
const selected = computed(() =>
  selectedString.value.split('--').filter(Boolean),
)

type ItemTypeMap = {
  [SearchIndex.People]: SearchItemPeopleFragment
  [SearchIndex.Fulltext_index]: SearchItemFulltextFragment
  [SearchIndex.News]: SearchItemNewsFragment
  [SearchIndex.Events]: SearchItemEventFragment
  [SearchIndex.Bancomat]: SearchItemBancomatFragment
  [SearchIndex.Publications]: SearchItemPublicationFragment
}

type ItemType = ItemTypeMap[T]
type PossibleKey = keyof ItemType

const props = defineProps<{
  index: T
  facetFields?: Record<string, string>
  defaultAll?: boolean
  perPage?: number
  groupBy?: PossibleKey
  sortBy?: PossibleKey
  sortOrder?: SearchSortOrder
  filters?: Record<string, string>
  mapGroups?: (groups: ResultGroup<ItemType>[]) => ResultGroup<ItemType>[]
  hideSearch?: boolean
  searchWhite?: boolean
}>()

const { $texts } = useEasyTexts()

const query = computed(() => {
  if (!text.value && !props.defaultAll) {
    return
  }

  return {
    i: props.index,
    t: text.value || undefined,
    f: props.facetFields
      ? Object.keys(props.facetFields).join('___') || undefined
      : undefined,
    s: selected.value.join('___') || undefined,
    p: props.perPage,
    sf: props.sortBy,
    so: props.sortOrder,
    fi: props.filters
      ? Object.entries(props.filters)
          .map(([field, value]) => {
            return `${field}:::${value}`
          })
          .join('___')
      : undefined,
  }
})

const { data, pending } = await useAsyncData<SearchResponseFragment>(
  () => {
    if (!query.value) {
      return Promise.resolve({ total: 0, items: [], facets: [] })
    }

    return $fetch<SearchResponseFragment>('/api/search', {
      query: query.value,
    })
  },
  {
    watch: [query],
  },
)

const items = computed<ItemType[]>(() => data.value?.items || [])
const facets = computed<SearchFacetFragment[]>(() => data.value?.facets || [])

const groups = computed<ResultGroup<ItemType>[] | undefined>(() => {
  if (!props.groupBy) {
    return
  }

  const groupBy = props.groupBy

  const grouped = items.value.reduce<Record<string, ItemType[]>>(
    (acc, item) => {
      if (groupBy in item) {
        const value = item[groupBy] as string | undefined
        if (value) {
          if (!acc[value]) {
            acc[value] = []
          }
          acc[value].push(item)
        }
      }
      return acc
    },
    {},
  )

  const groupedItems = Object.entries(grouped).map(([name, items]) => {
    return {
      name,
      items,
    }
  })

  if (props.mapGroups) {
    return props.mapGroups(groupedItems)
  }

  return groupedItems
})
</script>
