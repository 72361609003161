<template>
  <NuxtLink to="/">
    <SpriteSymbol
      width="153"
      height="33"
      name="logo-large"
      class="block lg:hidden"
    />
    <SpriteSymbol
      width="242"
      height="52"
      name="logo-large"
      class="hidden lg:block w-[180px] lg:w-[210px] xl:w-[242px]"
    />
  </NuxtLink>
</template>
