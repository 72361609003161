<template>
  <div class="layout-grid" :class="classes">
    <div
      class="md:layout-grid paragraph-quote col-start-2 col-end-[-2] md:col-start-1 md:col-end-[-1]"
      :class="{
        'is-reversed': options.alignment === 'right',
      }"
    >
      <aside
        v-if="isEditing || image"
        v-blokkli-droppable:field_media_image
        class="grid-area-r float-right w-96 md:w-full ml-16 mb-8 md:m-0"
      >
        <MediaImage v-if="image" v-bind="image" :image-style="imageStyle" />
      </aside>
      <figure
        class="grid-area-l"
        :class="
          options.alignment === 'right' ? 'md:text-right lg:pr-32' : 'lg:pl-32'
        "
      >
        <blockquote
          v-blokkli-editable:field_lead
          class="text-lg lg:text-2xl 2xl:text-3xl before:content-['«'] after:content-['»'] font-bold text-balance hyphens-auto md:hyphens-none"
          v-text="quote"
        />
        <hr class="w-96 inline-block border-primary-500 border-t-3" />
        <figcaption class="mt-8">
          <p v-blokkli-editable:field_title class="font-bold">
            {{ name }}
          </p>
          <div v-if="details">
            <div v-for="(detail, i) in details" :key="i" v-html="detail" />
          </div>
        </figcaption>
      </figure>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphQuoteFragment } from '#graphql-operations'

const props = defineProps<{
  quote?: string
  person?: ParagraphQuoteFragment['person']
  overrideName?: string
  overrideDetails?: string
  overrideImage?: ParagraphQuoteFragment['overrideImage']
}>()

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 96,
      aspectRatio: 1,
    },
    md: {
      aspectRatio: 3 / 2,
      width: 320,
    },
    xl: {
      aspectRatio: 3 / 2,
      width: 416,
    },
  },
})

const { options, isEditing } = defineBlokkli({
  bundle: 'quote',
  globalOptions: ['sectionBackground', 'sectionPadding'],
  editor: {
    previewWidth: 600,
    editTitle: (el) => el.querySelector('figcaption')?.textContent,
    addBehaviour: 'no-form',
  },
  options: {
    alignment: {
      type: 'radios',
      label: 'Ausrichtung',
      default: 'left',
      displayAs: 'icons',
      options: {
        left: { label: 'left', icon: 'icon-blokkli-option-left' },
        right: { label: 'right', icon: 'icon-blokkli-option-right' },
      },
    },
  },
})

const name = computed(() => {
  if (props.overrideName) {
    return props.overrideName
  }
  if (props.person && props.person.lastName && props.person.firstName) {
    return `${props.person.firstName} ${props.person.lastName}`
  }
  return undefined
})

const details = computed(() => {
  if (props.overrideDetails) {
    return [props.overrideDetails]
  }
  return props.person?.details
})
const image = computed(() => props.overrideImage || props.person?.image)

const { classes } = useParagraphContext(options)
</script>

<style lang="postcss">
.paragraph-quote {
  @screen md {
    @apply items-center;
    grid-template-areas: '. R R R R L L L L L L L L .';
    &.is-reversed {
      grid-template-areas: '. L L L L L L L L R R R R .';
    }
  }
}
</style>
