<template>
  <VuepalTransitionHeight
    :opacity="isUntilDesktop"
    :duration="duration"
    easing-enter="cubic-bezier(0.56, 0.04, 0.25, 1)"
    easing-leave="cubic-bezier(0.56, 0.04, 0.25, 1)"
  >
    <div
      v-show="isOpen"
      class="md:absolute md:top-full md:z-50 md:left-0 w-full md:border-t md:border-t-gray-200 md:layout-grid md:bg-white md:shadow-xl"
      :data-page-header-second-level="id"
    >
      <div class="col-start-2 col-end-[-2] md:pb-80 !pt-0">
        <p
          class="hidden md:block font-bold text-2xl lg:text-3xl xl:text-4xl py-24 lg:py-48 border-b border-b-gray-300"
          v-html="$sup(label)"
        />
        <ul
          class="md:grid md:mt-24 xl:mt-40 gap-32 md:gap-48 xl:gap-56 grid-cols-3"
        >
          <li
            v-for="(link, i) in links"
            :key="i"
            class="not-first:mt-20 md:!mt-0"
          >
            <TaxonomyTermIcons
              v-if="link.icon"
              v-bind="link.icon"
              class="md:size-32 lg:size-40 float-right hidden md:block"
            />
            <VuepalLink
              :to="link.url"
              class="font-bold text-xl lg:text-3xl hover:underline underline-offset-4 decoration-gray-400 focusable inline-block"
            >
              <div v-html="$sup(link.label)" />
            </VuepalLink>

            <ul v-if="link.links.length" class="mt-10 grid gap-3 lg:gap-8">
              <li v-for="(subLink, j) in link.links" :key="i + '_' + j">
                <a
                  :href="subLink.url"
                  class="text-lg xl:text-xl hover:underline underline-offset-4 decoration-gray-400 focusable"
                  v-bind="subLink.attributes"
                  @click="onClick($event, subLink.url)"
                  v-html="subLink.label"
                />
              </li>
            </ul>
          </li>
          <li
            v-if="paragraph"
            class="not-first:mt-20 md:!mt-0 bg-gray-100 p-16 -mx-16 md:p-0 md:mx-0 md:bg-transparent"
          >
            <div class="flex flex-col h-full">
              <div class="mb-auto">
                <p
                  class="font-bold text-xl lg:text-3xl"
                  v-html="$sup(paragraph.title)"
                />
                <p
                  v-if="paragraph.lead"
                  class="mt-8 lg:text-lg"
                  v-html="$sup(paragraph.lead)"
                />
              </div>
              <div>
                <VuepalLink
                  v-if="paragraph.link?.title && paragraph.link.uri?.path"
                  :to="paragraph.link.uri.path"
                  class="button is-primary mt-8 until-md:is-small"
                >
                  {{ paragraph.link.title }}
                </VuepalLink>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </VuepalTransitionHeight>
</template>

<script lang="ts" setup>
import type { ParagraphTitleLeadLinkFragment } from '#graphql-operations'
import type { MappedMenuLink } from '~/composables/useInitData'

const props = defineProps<{
  label: string
  id: number
  index: number
  total: number
  links: MappedMenuLink[]
  rootWidth: number
  activeId: number
  hasTransition: boolean
  paragraph?: ParagraphTitleLeadLinkFragment
}>()

const { isUntilDesktop } = useViewport()

const duration = computed(() => {
  if (isUntilDesktop.value || props.hasTransition) {
    return 400
  }

  return 0
})

const isOpen = computed(() => props.id === props.activeId)
const router = useRouter()
const { $sup } = useNuxtApp()
const emit = defineEmits(['close'])

const onClick = (e: MouseEvent, url?: string) => {
  if (url && url.startsWith('http')) {
    return
  }

  e.stopPropagation()
  e.preventDefault()
  if (url) {
    router.push(url)
    emit('close')
  }
}
</script>
