<template>
  <div class="layout-grid py-section">
    <div
      class="col-start-2 col-end-[-2] grid grid-cols-1 md:grid-cols-2 gap-24"
    >
      <VuepalLink
        v-for="agency in agencies"
        :key="agency.label"
        class="block group"
        :to="agency.url?.path"
      >
        <div class="overflow-hidden">
          <MediaImage
            v-if="agency.image"
            v-bind="agency.image"
            :image-style="imageStyle"
            class="group-hover:scale-105 transition duration-300"
          />
        </div>
        <h2 class="typo-h2 mt-24">{{ agency.address?.locality }}</h2>
        <div v-if="agency.address" class="typo-lead-2 text-gray-500">
          <div>{{ agency.address.addressLine1 }}</div>
          <div>
            {{ agency.address.postalCode }} {{ agency.address.locality }}
          </div>
        </div>
      </VuepalLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { narrowTypeByProperty } from '#vuepal/helpers/graphql'
import { notNullish } from '@vueuse/core'
import type { NodeAgencyTeaserFragment } from '#build/graphql-operations'

defineBlokkliFragment({
  name: 'agencies',
  label: 'Standorte',
  description: 'Listet die Standorte auf.',
  editor: {
    noPreview: true,
  },
})

const { data: agencies } = await useAsyncData<NodeAgencyTeaserFragment[]>(
  () => {
    return useGraphqlQuery('agenciesList').then((data) => {
      return (data.data.entityQuery.items || [])
        .map((v) => narrowTypeByProperty(v, 'address'))
        .filter(notNullish)
    })
  },
  {
    default: () => [],
  },
)

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    sm: 420,
    lg: 636,
  },
})
</script>
