<template>
  <div class="h-80 relative group/search">
    <div
      class="bg-white shadow-md absolute top-0 left-0 w-full focus-within:shadow-2xl border border-gray-200"
    >
      <div class="relative h-[3em] text-xl lg:text-3xl">
        <input
          ref="inputEl"
          v-model="localValue"
          class="relative size-full focus:outline-none font-bold pl-[2.5em] peer placeholder:font-regular placeholder:text-[0.75em] placeholder:leading-none"
          :placeholder="
            placeholder ||
            $texts('search_default_placeholder', 'Suchbegriff eingeben')
          "
        />
        <SpriteSymbol
          name="search"
          class="absolute top-1/2 left-[0.75em] size-[1em] z-20 -translate-y-1/2 text-gray-400 peer-focus:text-gray-900 pointer-events-none"
        />
        <button
          v-if="localValue"
          class="absolute h-full aspect-square top-0 right-0 z-20 text-gray-400 peer-focus:text-gray-900 flex items-center justify-center hover:text-black"
          title="Suchbegriff löschen"
          @click.prevent="onClear"
        >
          <SpriteSymbol name="cross" class="size-[0.75em]" />
        </button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  placeholder?: string
  modelValue: string
}>()

const { $texts } = useEasyTexts()

const emit = defineEmits<{
  (e: 'update:modelValue', values: string): void
}>()

const localValue = ref(props.modelValue)

const inputEl = ref<HTMLInputElement | null>(null)

let timeout: number | null = null

watch(localValue, function (newValue) {
  if (timeout) {
    window.clearTimeout(timeout)
  }

  timeout = window.setTimeout(() => {
    emit('update:modelValue', newValue)
  }, 300)
})

watch(
  () => props.modelValue,
  function (updatedValue) {
    if (timeout) {
      window.clearTimeout(timeout)
    }

    localValue.value = updatedValue
  },
)

function onClear() {
  localValue.value = ''
  if (inputEl.value) {
    inputEl.value.focus()
  }
}
</script>
