<template>
  <div class="layout-grid">
    <div
      class="grid md:grid-cols-2 lg:grid-cols-3 gap-50 md:gap-24 section-padding col-start-2 col-end-[-2]"
    >
      <div v-for="(person, i) in people" :key="i">
        <NodePersonTeaser v-bind="person">
          <VuepalLink class="button mt-24" :to="person.url?.path">
            Mehr erfahren
          </VuepalLink>
        </NodePersonTeaser>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NodePersonTeaserFragment } from '#graphql-operations'

defineBlokkliFragment({
  name: 'management',
  label: 'Management',
  description: 'Listet die Geschäftsleitung auf',
  editor: {
    noPreview: true,
  },
})

const { data: people } = await useAsyncData<NodePersonTeaserFragment[]>(() =>
  useCachedGraphqlQuery('management').then(
    (v) => v.data.managementPeople || [],
  ),
)
</script>
