<template>
  <SearchProvider
    :index="SearchIndex.Bancomat"
    :facet-fields="{
      features: 'Merkmale',
    }"
    group-by="locality"
    :sort-order="SearchSortOrder.DESC"
    :per-page="1000"
    :map-groups="mapGroups"
    hide-search
    default-all
    :search-white="index !== 0"
  >
    <template #item="{ item }">
      <Accordion class="my-16">
        <template #title>
          <div class="flex">
            <span class="w-[250px]">{{ item.node?.title }}</span>
            <span class="text-gray-500">{{
              item.node?.address?.addressLine1
            }}</span>
          </div>
        </template>
        <BancomatItem v-bind="item.node" />
      </Accordion>
    </template>
  </SearchProvider>
</template>

<script lang="ts" setup>
import BancomatItem from './Item.vue'
import { SearchIndex, SearchSortOrder } from '#graphql-operations'
import type { ResultGroup } from '~/components/Search/Provider.vue'

const { index } = defineBlokkliFragment({
  name: 'bancomat',
  label: 'Bancomat',
  description: 'Listet alle Bancomaten auf.',
  editor: {
    noPreview: true,
  },
})

const { data: weights } = await useAsyncData<Record<string, number>>(
  () => {
    return useGraphqlQuery('agencyWeights').then((v) => {
      return (v.data.weights || []).reduce<Record<string, number>>(
        (acc, item) => {
          if (!item || !('weight' in item)) {
            return acc
          }

          const weight = item.weight
          if (!item.label || typeof weight !== 'number') {
            return acc
          }

          acc[item.label] = weight

          return acc
        },
        {},
      )
    })
  },
  {
    default: () => {
      return {}
    },
  },
)

function mapGroups(groups: ResultGroup<any>[]) {
  return [...groups].sort((a, b) => {
    const wa = weights.value[a.name]
    const wb = weights.value[b.name]

    return wa - wb
  })
}
</script>
