<template>
  <div
    class="p-20 md:w-[740px] md:min-h-[408px] flex flex-col"
    :class="{ 'bg-white shadow-xl': isEditing }"
  >
    <div class="mb-16">
      <h2
        v-if="title"
        v-blokkli-editable:field_title
        class="font-bold text-2xl lg:text-3xl xl:text-4xl"
        v-html="$sup(title)"
      />
      <p
        v-if="lead"
        v-blokkli-editable:field_lead
        class="font-bold text-gray-500 lg:text-lg xl:text-xl"
        v-html="$sup(lead)"
      />
    </div>

    <div class="mt-auto">
      <ParagraphFollowMenuItemNews v-if="options.type === 'news'" />

      <BlokkliField
        name="field_paragraphs"
        :list="paragraphs"
        class="grid md:flex gap-16 w-full"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ParagraphFollowMenuItemFragment } from '#graphql-operations'

defineProps<{
  title?: string
  menuTitle?: string
  lead?: string
  icon?: ParagraphFollowMenuItemFragment['icon']
  paragraphs?: ParagraphFollowMenuItemFragment['paragraphs']
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'follow_menu_item',
  options: {
    type: {
      type: 'radios',
      label: 'Typ',
      default: 'links',
      options: {
        links: 'Links',
        news: 'News',
        contact: 'Kontakt',
      },
    },
  },
})

const { $sup } = useNuxtApp()
</script>
