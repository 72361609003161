<template>
  <footer
    v-if="data"
    class="layout-grid mt-44 border-b-6 border-b-primary-500 footer items-center py-24 border-t border-gray-300"
  >
    <!-- Logo. -->
    <div class="grid-area-l">
      <SpriteSymbol
        name="logo-large"
        width="242"
        height="52"
        class="h-40 w-auto"
      />
    </div>

    <!-- Contact. -->
    <div
      class="lg:leading-none flex lg:flex-col grid-area-c lg:h-40 justify-between"
    >
      <div class="flex flex-col lg:flex-row lg:gap-28 justify-between">
        <span>Vorstadt 53</span>
        <span>8201 Schaffhausen</span>
        <span>+41 52 635 22 22</span>
        <span>info@shkb.ch</span>
      </div>
      <div class="flex flex-col lg:flex-row justify-between mt-auto">
        <span>Banken-Clearing Nr. 782</span>
        <span>BIC/SWIFT SHKBCH2S</span>
      </div>
    </div>

    <hr class="grid-area-h lg:my-24 border-t-gray-300" />

    <!-- Menu. -->
    <div class="grid-area-m print:hidden">
      <div class="flex gap-20 overflow-auto">
        <VuepalLink
          v-for="(link, i) in data.footerMenuLinks"
          :key="i"
          class="link whitespace-nowrap"
          :to="link.url"
        >
          {{ link.label }}
        </VuepalLink>
      </div>
    </div>

    <!-- Social. -->
    <div
      class="grid-area-s flex gap-16 justify-between lg:justify-end print:hidden"
    >
      <VuepalLink
        v-for="link in data.socialMediaLinks"
        :key="link.label"
        class="text-gray-800 hover:text-gray-900"
        :to="link.url"
      >
        <TaxonomyTermIcons v-bind="link.icon" class="size-28" />
      </VuepalLink>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const data = await useInitData()
</script>

<style lang="postcss">
.footer {
  @apply gap-y-20;
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas:
    '. L L L L L L L L L L L L .'
    '. C C C C C C C C C C C C .'
    '. M M M M M M M M M M M M .'
    'H H H H H H H H H H H H H H'
    '. S S S S S S S S S S S S .';

  @screen lg {
    @apply gap-y-0;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      '. L L L C C C C C C C . . .'
      'H H H H H H H H H H H H H H'
      '. M M M M M M M M M S S S .';
  }

  @screen xl {
    grid-template-areas:
      '. L L C C C C C C . . . . .'
      'H H H H H H H H H H H H H H'
      '. M M M M M M M M M S S S .';
  }

  @screen 3xl {
    grid-template-areas:
      'L L L C C C C C C . . . . .'
      'H H H H H H H H H H H H H H'
      'M M M M M M M M M M M S S S';
  }
}
</style>
