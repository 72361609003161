<template>
  <div
    ref="overlay"
    class="button-tooltip-over pointer-events-none"
    :style="style"
  >
    <div v-html="$sup(text)" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  el: HTMLElement
  text: string
}>()

const PADDING = 16

const x = ref(0)
const y = ref(0)
const width = ref(100)
const caretX = ref(0)
const isVisible = ref(false)

const overlay = ref<HTMLDivElement | null>(null)

const { isMobile } = useViewport()
const { $sup } = useNuxtApp()

const style = computed(() => {
  return {
    transform: `translate(${x.value}px, ${y.value}px)`,
    width: width.value + 'px',
    '--caret-x': caretX.value + 'px',
  }
})

useAnimationFrame(() => {
  if (!props.el) {
    return
  }

  if (!overlay.value) {
    return
  }

  width.value = isMobile.value ? window.innerWidth - 2 * PADDING : 400

  const rect = props.el.getBoundingClientRect()

  x.value = isMobile.value
    ? 16
    : Math.min(
        Math.max(rect.x - width.value / 2 + rect.width / 2, PADDING),
        window.innerWidth - width.value - PADDING,
      )
  caretX.value = rect.x - x.value + rect.width / 2 - 7.5
  y.value = rect.y - 20

  if (!isVisible.value) {
    isVisible.value = true
  }
})
</script>

<style lang="postcss">
.button-tooltip-over {
  @apply fixed top-0 left-0;
  z-index: 99999;

  &:after {
    content: '';
    @apply absolute left-0 w-0 h-0 z-50 top-full;
    transform: translateX(var(--caret-x));
    border-style: solid;
    border-width: 0.5rem 0.5rem 0 0.5rem;
    border-color: theme('colors.gray.800') transparent transparent transparent;
  }

  > div {
    @apply absolute bottom-0 left-0 w-full;
    @apply bg-gray-800 text-base p-10 z-50 text-white;
    width: auto;
    white-space: normal;
    text-align: left;
  }
}
</style>
