import type { RouteLocationNormalizedLoaded } from 'vue-router'

type AnalyticsEventSwitchPage = {
  event: 'switchPage'
  pageURL: string
  pagePath: string
  routeName: string
  pageReferrer?: string
  pageTitle?: string
  entityType?: string
  entityBundle?: string
  entityId?: string
  nodeId?: string
}

export default function () {
  const router = useRouter()
  const ssrDataLayer = useState<any[]>('ssrDataLayer', () => [])
  const dataLayerPush = (data: any) => {
    if (import.meta.client) {
      if (!window.dataLayer) {
        window.dataLayer = []
      }
      window.dataLayer.push(data)
    } else {
      ssrDataLayer.value.push(data)
    }
  }

  const requestUrl = useRequestURL()

  function trackSwitchPage(
    route: RouteLocationNormalizedLoaded,
    pageReferrer: string | undefined | null,
    additional: Partial<AnalyticsEventSwitchPage>,
  ) {
    dataLayerPush({
      event: 'switchPage',
      pageURL: requestUrl.origin + route.fullPath,
      pagePath: route.fullPath,
      pageReferrer,
      entityType: '',
      entityBundle: '',
      entityId: '',
      routeName: (router.currentRoute.value.name || '')
        .toString()
        .split('___')[0],
      ...additional,
    })
  }

  return { trackSwitchPage, dataLayerPush }
}

declare global {
  interface Window {
    dataLayer: any[]
  }
}
