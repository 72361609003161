<template>
  <div>
    <div class="md:flex items-center gap-32 mb-16 md:mb-36">
      <div class="text-xl lg:text-4xl font-bold">
        {{ $texts('global_quicksearch_title', 'Suchen Sie etwas Bestimmtes?') }}
      </div>
      <div class="relative h-48 md:h-64 flex-1 mt-16 md:mt-0">
        <SpriteSymbol
          name="search"
          class="absolute top-1/2 -translate-y-1/2 left-16 w-16 md:w-24 aspect-square"
        />
        <input
          v-model="text"
          class="px-20 pl-36 md:pl-50 size-full text-lg lg:text-3xl font-bold outline-none placeholder:text-lg border border-gray-300 pr-50"
          :placeholder="
            $texts(
              'global_quicksearch_placeholder',
              'Produkte, Inhalte und Fragen suchen',
            )
          "
          @focus.once="onFocus"
        />
        <button
          v-if="text"
          class="absolute h-full aspect-square top-0 right-0 z-20 text-gray-400 peer-focus:text-gray-900 flex items-center justify-center hover:text-black"
          title="Suchbegriff löschen"
          @click.prevent="text = ''"
        >
          <SpriteSymbol name="cross" class="size-[0.75em]" />
        </button>
      </div>
    </div>
    <div
      :class="{
        'opacity-55': text && text !== searchedText && text.length > 3,
        'until-md:-mx-16 until-md:pr-16': isRenderedInline,
      }"
    >
      <ul
        class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-7 gap-10"
        :class="{
          'until-md:flex until-md:px-16 until-md:overflow-auto until-md:-mr-16 until-md:pb-12':
            isRenderedInline,
        }"
      >
        <li
          v-for="(item, index) in items"
          :key="item.uuid"
          :class="{
            'xl:hidden': index === 7,
            'until-md:w-[140px] until-md:shrink-0': isRenderedInline,
          }"
        >
          <IconTile
            :icon="{ id: item.icon }"
            :title="item.title"
            :url="item.url"
            @click="onClick(item)"
          />
        </li>
      </ul>

      <div
        v-if="onlineServices.length"
        class="mt-36 border-t border-t-gray-300 pt-24"
      >
        <h3 class="mb-32 typo-h3">
          {{
            $texts(
              'quicksearch_matching_online_services_title',
              'Passende Online-Services',
            )
          }}
        </h3>
        <ul class="grid gap-16 lg:grid-cols-2">
          <li v-for="service in onlineServices" :key="'service' + service.id">
            <TaxonomyTermOnlineService v-bind="service" />
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="searchedText && text && text.length > 3"
      class="mt-24 border-t border-t-gray-300 pt-24"
    >
      <NuxtLink :to="{ name: 'search', query: { text } }" class="button mt-20">
        {{ $texts('global_show_more_results', 'Weitere Resultate anzeigen') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TaxonomyTermOnlineServiceFragment } from '#build/graphql-operations'
import { notNullish } from '@vueuse/core'
import { getLinkedEntityData } from '~/helpers/graphql'

const props = withDefaults(
  defineProps<{
    persistKey?: string
    mobileInline?: boolean
  }>(),
  {
    persistKey: 'default',
  },
)

type QuicksearchItem = {
  uuid: string
  icon?: string
  title: string
  url: string
}

const { dataLayerPush } = useAnalytics()
const { $texts } = useEasyTexts()

const isFocusedOnce = ref(false)
const text = useState('quicksearch_' + props.persistKey, () => '')
const searchedText = ref('')
let timeout: number | null = null

const isRenderedInline = computed(
  () => props.mobileInline && !searchedText.value && !isFocusedOnce.value,
)

function onFocus() {
  isFocusedOnce.value = true
}

const { data } = await useAsyncData(() => {
  return useCachedGraphqlQuery('quicksearchDefaults').then(
    (v) => v.data.quicksearchConfig,
  )
})

const defaultItems = computed<QuicksearchItem[]>(() =>
  (data.value?.paragraphs || [])
    .map((v) => {
      if (
        v &&
        v.props &&
        'link' in v.props &&
        v.props.link &&
        v.props.link.uri?.path
      ) {
        const { title, icon } = getLinkedEntityData(v.props.link)
        if (title) {
          return {
            uuid: v.uuid,
            title,
            icon: v.props.icon?.id || icon?.id,
            url: v.props.link.uri.path,
            type: 'page',
          }
        }
      }
      return null
    })
    .filter(notNullish),
)

const searchResults = ref<QuicksearchItem[]>([])

const items = computed(() => {
  if (text.value.length < 3) {
    return defaultItems.value
  }

  return searchResults.value
})

watch(text, () => loadResults())

const allOnlineServices = ref<TaxonomyTermOnlineServiceFragment[] | null>(null)

const matchingOnlineServiceIds = ref<string[]>([])

const onlineServices = computed<TaxonomyTermOnlineServiceFragment[]>(() => {
  if (!text.value || !allOnlineServices.value) {
    return []
  }

  return allOnlineServices.value
    .filter((v) => v.id && matchingOnlineServiceIds.value.includes(v.id))
    .slice(0, 4)
})

function loadResults(immediately?: boolean) {
  if (timeout) {
    window.clearTimeout(timeout)
  }

  if (text.value.length < 3) {
    return
  }

  timeout = window.setTimeout(
    async () => {
      searchedText.value = text.value
      if (!text.value) {
        searchResults.value = []
        return
      }

      // Load online services if not yet done.
      if (!allOnlineServices.value) {
        allOnlineServices.value = await useCachedGraphqlQuery(
          'allOnlineServices',
        ).then((data) => {
          return (data.data.entityQuery.items || [])
            .map((v) => {
              if (v && 'link' in v) {
                return v
              }

              return null
            })
            .filter(notNullish)
        })
      }
      const result = await useCachedGraphqlQuery('quicksearch', {
        text: text.value,
      })
      searchResults.value =
        (result.data.quicksearch || [])
          .map((v) => {
            if (v.url && v.icon && v.type && v.uuid && v.title) {
              return {
                uuid: v.uuid,
                icon: v.icon,
                title: v.title,
                url: v.url,
              }
            }
            return null
          })
          .filter(notNullish) || []

      matchingOnlineServiceIds.value = result.data.onlineServices || []

      dataLayerPush({
        event: 'quickSearch',
        searchTerm: text.value,
        amountResults: searchResults.value.length,
      })
    },
    immediately ? 0 : 500,
  )
}

onMounted(() => {
  loadResults(true)
})

function onClick(item: QuicksearchItem) {
  dataLayerPush({
    event: 'quickClick',
    searchTerm: text.value,
    path: item.url,
  })
}
</script>
