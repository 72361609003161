<template>
  <SearchInput
    v-model="text"
    class="mb-28"
    :placeholder="
      $texts('online_services_search_placeholder', 'Alle Services durchsuchen')
    "
  >
    <div
      v-if="items.length"
      class="p-20 border-t border-t-gray-200 gap-24 hidden group-focus-within/search:grid"
    >
      <div
        :class="{
          'opacity-50': isLoading,
        }"
      >
        <div v-for="item in items" :key="item.id">
          <TaxonomyTermOnlineService v-bind="item" />
        </div>
      </div>
    </div>
  </SearchInput>
</template>

<script setup lang="ts">
import { notNullish } from '@vueuse/core'
import type { TaxonomyTermOnlineServiceFragment } from '#build/graphql-operations'

const text = ref('')
const { $texts } = useEasyTexts()

const { data: allServices } = await useAsyncData(() => {
  return useCachedGraphqlQuery('allOnlineServices').then((data) => {
    return data.data.entityQuery.items
      ?.map((v) => {
        if ('icon' in v) {
          return v
        }
        return null
      })
      .filter(notNullish)
  })
})

const { data, pending } = await useAsyncData<{
  ids: string[]
  textAtSearchTime: string
}>(
  () => {
    if (!text.value) {
      return Promise.resolve({ ids: [], textAtSearchTime: '' })
    }
    const textAtSearchTime = text.value
    return useCachedGraphqlQuery('quicksearchOnlineServices', {
      text: text.value,
    }).then((v) => {
      return {
        ids: v.data.ids || [],
        textAtSearchTime,
      }
    })
  },
  {
    watch: [text],
    default: () => {
      return {
        ids: [],
        textAtSearchTime: '',
      }
    },
  },
)

const isLoading = computed(() => {
  return pending.value || text.value !== data.value.textAtSearchTime
})

const items = computed<TaxonomyTermOnlineServiceFragment[]>(() => {
  if (!allServices.value || !text.value) {
    return []
  }

  return allServices.value.filter((v) => {
    if (!v.id) {
      return false
    }

    return data.value.ids.includes(v.id)
  })
})
</script>
