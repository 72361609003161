<template>
  <div
    class="inline-block print:hidden paragraph-link"
    :class="{
      'mt-16': parentType !== 'contact_teaser',
    }"
  >
    <FieldItemTypeLink
      v-slot="{ url, label, entityIcon, symbol, attributes }"
      v-bind="link"
    >
      <VuepalLink
        :to="url"
        v-bind="attributes"
        :class="{
          button: options.display === 'button',
          link: options.display === 'link',
          'is-dark':
            options.display === 'button' &&
            options.buttonBackground === 'black',
          'is-primary':
            options.display === 'button' &&
            options.buttonBackground === 'yellow',
        }"
      >
        <template v-if="options.showIcon">
          <TaxonomyTermIcons
            v-if="icon || entityIcon"
            v-bind="icon || entityIcon"
          />
          <SpriteSymbol v-else-if="symbol" :name="symbol" />
        </template>
        <div v-html="$sup(label)" />
      </VuepalLink>
    </FieldItemTypeLink>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphLinkFragment } from '#graphql-operations'

defineProps<{
  link?: ParagraphLinkFragment['link']
  icon?: ParagraphLinkFragment['icon']
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'link',
  globalOptions: ['buttonBackground'],
  options: {
    display: {
      type: 'radios',
      label: 'Darstellung',
      default: 'button',
      options: {
        link: 'Link',
        button: 'Button',
      },
    },
    showIcon: {
      type: 'checkbox',
      label: 'Icon anzeigen',
      default: false,
    },
  },

  editor: {
    determineVisibleOptions(ctx) {
      if (ctx.options.display === 'button') {
        return ['display', 'buttonBackground', 'showIcon']
      }

      return ['display', 'showIcon']
    },
  },
})

const { $sup } = useNuxtApp()
</script>
