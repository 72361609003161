<template>
  <div class="relative">
    <HypoInfo :label="label" :tooltip="help" :for-id="id" />
    <div class="relative group">
      <input
        :id="id"
        v-model.number="localValue"
        class="input w-full"
        :class="[{ 'is-danger': errorRendered }, size, { 'pl-[3em]': prefix }]"
        :disabled="disabled"
        type="number"
        :min="min"
        :max="max"
        :step="step"
        maxlength="7"
      />
      <div
        v-if="inputOverlay"
        class="input absolute top-0 left-0 w-full pointer-events-none group-focus-within:hidden"
        :class="[size, { 'pl-[3em]': prefix }]"
      >
        {{ inputOverlay }}
      </div>
      <span
        v-if="prefix"
        class="input absolute top-0 left-0 h-full border-transparent bg-transparent text-gray-400"
        :class="size"
        >{{ prefix }}</span
      >
    </div>
    <div v-if="errorRendered" class="mt-10 text-red-500">{{ error }}</div>
  </div>
</template>

<script setup lang="ts">
import HypoInfo from './../Info/index.vue'
import { formatSwissNumber } from '~/helpers/numbers'

const props = withDefaults(
  defineProps<{
    id: string
    label: string
    min: number
    max: number
    step: number
    prefix?: string
    help: string
    error?: string
    disabled?: boolean
    size?: string
  }>(),
  {
    prefix: undefined,
    error: undefined,
    size: 'text-xl',
  },
)

const value = defineModel<number>({ default: 0 })

const localValue = computed({
  get() {
    return value.value
  },
  set(v: string | number) {
    if (typeof v === 'string') {
      const num = parseInt(v)
      if (!isNaN(num)) {
        value.value = num
        return
      }
    }

    if (typeof v === 'number' && !isNaN(v)) {
      value.value = v
    }
  },
})

const inputOverlay = computed(() => {
  if (!props.prefix) {
    return ''
  }

  return formatSwissNumber(value.value)
})

const errorRendered = computed(() => {
  return !props.disabled && !!props.error
})

defineOptions({
  name: 'MortgageCalculatorInput',
})
</script>
