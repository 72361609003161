<template>
  <div :id="options.anchorId" class="layout-grid my-section">
    <Webform
      v-if="webformUrl"
      :webform-url="webformUrl"
      class="col-start-2 col-end-[-2]"
      :editing="isEditing"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphWebformFragment } from '#graphql-operations'

const { isEditing, options } = defineBlokkli({
  bundle: 'webform',
  globalOptions: ['anchorId'],
})

const props = defineProps<{
  webform?: ParagraphWebformFragment['webform']
}>()

const webformUrl = computed(() => {
  return props.webform?.formUrl?.path
})
</script>
