<template>
  <div
    :id="options.anchorId"
    :class="classes"
    class="layout-grid paragraph-container"
  >
    <BlokkliField
      :list="paragraphs"
      name="field_paragraphs"
      drop-alignment="vertical"
      class="col-start-2 col-end-[-2]"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphGridFragment } from '#graphql-operations'

defineProps<{
  paragraphs?: ParagraphGridFragment['paragraphs']
}>()

const { options } = defineBlokkli({
  bundle: 'container',
  globalOptions: [
    'sectionBackground',
    'anchorId',
    'hasBorder',
    'sectionPadding',
  ],
  editor: {
    addBehaviour: 'no-form',
  },
})

const { classes } = useParagraphContext(options)

defineOptions({
  name: 'ParagraphContainer',
})
</script>

<style lang="postcss">
.paragraph-container {
  counter-reset: listicle-counter;
}
</style>
