<template>
  <ParagraphCardGrid
    v-if="parentType === 'grid'"
    :title="title"
    :lead="lead"
    :link="link"
    :image="image"
    :text-class="textClass"
    :text-muted-class="textMutedClass"
    :parent-background="parentBackground"
  />
  <ParagraphCardFull
    v-else
    :class="classes"
    :title="title"
    :lead="lead"
    :link="link"
    :image="image"
    :text-class="textClass"
    :text-muted-class="textMutedClass"
    :parent-background="parentBackground"
    :is-reversed="options.isReversed"
    :is-pulled="options.isPulled"
  />
</template>

<script lang="ts" setup>
import type { ParagraphCardFragment } from '#graphql-operations'

const { options, parentType } = defineBlokkli({
  bundle: 'card',
  globalOptions: ['sectionBackground'],
  options: {
    isReversed: {
      type: 'checkbox',
      label: 'Umkehren',
      default: true,
    },
    isPulled: {
      type: 'checkbox',
      label: 'Bis zum Rand',
      default: false,
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
    determineVisibleOptions(ctx) {
      if (ctx.parentType) {
        return []
      }

      return ['sectionBackground', 'isPulled', 'isReversed']
    },
  },
})

defineProps<{
  title?: string
  lead?: string
  link?: ParagraphCardFragment['link']
  image?: ParagraphCardFragment['image']
}>()

const providedParentBackground = inject<ComputedRef<BackgroundColor> | null>(
  'paragraph_parent_background',
  null,
)

const optionsToUse = computed(() => {
  if (parentType.value && providedParentBackground?.value) {
    return {
      sectionBackground: providedParentBackground.value,
    }
  }

  return {
    sectionBackground: options.value.sectionBackground,
  }
})

const { classes, textClass, textMutedClass, parentBackground } =
  useParagraphContext(optionsToUse)
</script>

<style lang="postcss">
.payment-card {
  border-radius: 6.2%/10%;
}
</style>
