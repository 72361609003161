<template>
  <svg v-if="id" class="media-icon">
    <use :xlink:href="url" width="100%" height="100%" />
  </svg>
</template>

<script lang="ts" setup>
const props = defineProps<{
  id?: string
}>()

const url = computed(() => `/api/icon/${props.id}.svg#icon`)

defineOptions({
  name: 'TaxonomyTermIcons',
})
</script>
