<template>
  <div
    class="col-start-1 col-end-[-1] layout-grid items-center paragraph-card--full border-b border-b-gray-300 overflow-hidden"
    :class="[{ 'is-reversed': isReversed, 'is-pulled': isPulled }]"
  >
    <div class="grid-area-l">
      <h3
        v-blokkli-editable:field_title
        class="text-xl xl:text-5xl font-bold leading-none"
        :class="textClass"
        v-html="$sup(title)"
      />
      <p
        v-blokkli-editable:field_lead
        class="text-xl xl:text-2xl font-bold"
        :class="textMutedClass"
        v-html="$sup(lead)"
      />
      <FieldItemTypeLink v-slot="{ url, label }" v-bind="link">
        <VuepalLink
          :to="url"
          class="button mt-24 lg:mt-40"
          :class="{ 'is-dark': parentBackground === 'yellow' }"
        >
          {{ label }}
        </VuepalLink>
      </FieldItemTypeLink>
    </div>
    <aside v-if="image" class="grid-area-r">
      <MediaImage
        v-bind="image"
        :image-style="imageStyle"
        class="payment-card overflow-hidden shadow-xl"
      />
    </aside>
  </div>
</template>

<script setup lang="ts">
import type { ParagraphCardFragment } from '#graphql-operations'

defineProps<{
  title?: string
  lead?: string
  link?: ParagraphCardFragment['link']
  image?: ParagraphCardFragment['image']
  textClass: string
  textMutedClass: string
  parentBackground: BackgroundColor
  isReversed: boolean
  isPulled: boolean
}>()

const { $sup } = useNuxtApp()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 8560 / 5398,
  sizes: {
    sm: 500,
  },
})
</script>

<style lang="postcss">
.paragraph-card--full {
  grid-template-areas: '. L L L L L L L L R R R R .';

  &.is-reversed {
    grid-template-areas: '. R R R R L L L L L L L L .';

    &:not(.is-pulled) {
      .grid-area-l {
        @apply pl-40;
      }
    }

    &.is-pulled {
      grid-template-areas: 'R R R R L L L L L L L L L .';
      background-image: linear-gradient(
        20deg,
        rgba(0, 0, 0, 0.03),
        transparent 40%
      );
      .grid-area-r {
        @apply -translate-x-50;
      }
    }
  }
}
</style>
