<template>
  <button
    class="flex gap-5 font-bold flex-1 items-center px-15 py-8 bg-white text-sm sm:text-base"
    :class="{
      '!bg-gray-100': isActive,
    }"
    @click="toggleMobilePane(pane)"
  >
    <SpriteSymbol :name="icon" class="size-15" />
    <span>{{ title }}</span>
  </button>
</template>

<script setup lang="ts">
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite/runtime'
import type { MobilePane } from '~/composables/useMenu'

const { openMobilePane, toggleMobilePane } = useMenu()

const props = defineProps<{
  pane: MobilePane
  title: string
  icon: NuxtSvgSpriteSymbol
}>()

const isActive = computed(() => props.pane === openMobilePane.value)
</script>
