import provideState_MaX9fQr3GV from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_4sVQNw7RlN from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/bazinga/shkb.next/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import analytics_SVnwK7EMrr from "/builds/bazinga/shkb.next/frontend/plugins/analytics.ts";
import sup_HSLTmtCpDB from "/builds/bazinga/shkb.next/frontend/plugins/sup.ts";
import clientCache_SyeCpR1f31 from "/builds/bazinga/shkb.next/frontend/plugins/clientCache.ts";
import blokkliEditable_4phZLIxAsp from "/builds/bazinga/shkb.next/frontend/node_modules/@blokkli/editor/dist/runtime/plugins/blokkliEditable.js";
import clickaway_ZmIhydr7La from "/builds/bazinga/shkb.next/frontend/plugins/clickaway.ts";
import graphqlMiddleware_2xI7SMabjg from "/builds/bazinga/shkb.next/frontend/plugins/graphqlMiddleware.ts";
import texts_P4dMAr3Ct0 from "/builds/bazinga/shkb.next/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import drupalUser_HxAWH89yLt from "/builds/bazinga/shkb.next/frontend/plugins/drupalUser.ts";
export default [
  provideState_MaX9fQr3GV,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  analytics_SVnwK7EMrr,
  sup_HSLTmtCpDB,
  clientCache_SyeCpR1f31,
  blokkliEditable_4phZLIxAsp,
  clickaway_ZmIhydr7La,
  graphqlMiddleware_2xI7SMabjg,
  texts_P4dMAr3Ct0,
  drupalUser_HxAWH89yLt
]