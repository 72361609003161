
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index5cdCBRywCCMeta } from "/builds/bazinga/shkb.next/frontend/pages/[...slug]/index.vue?macro=true";
import { default as _91id_9319scapHsAeMeta } from "/builds/bazinga/shkb.next/frontend/layers/wizard/pages/consultation/[id].vue?macro=true";
import { default as indexT0RUgT0kSlMeta } from "/builds/bazinga/shkb.next/frontend/layers/customer-authentication/pages/customer-authentication/index.vue?macro=true";
import { default as indexeEOKJ2xfAHMeta } from "/builds/bazinga/shkb.next/frontend/pages/edit/follow-menu/index.vue?macro=true";
import { default as confirmationKB2NzY1LtWMeta } from "/builds/bazinga/shkb.next/frontend/pages/form/[webform_id]/confirmation.vue?macro=true";
import { default as _91id_93cXnl6sThrbMeta } from "/builds/bazinga/shkb.next/frontend/pages/media/[id].vue?macro=true";
import { default as indexB5vVFhIGwFMeta } from "/builds/bazinga/shkb.next/frontend/pages/newsletter/index.vue?macro=true";
import { default as indexRNFkDH2hNHMeta } from "/builds/bazinga/shkb.next/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as _91id_934a6D7CVn7pMeta } from "/builds/bazinga/shkb.next/frontend/layers/wizard/pages/order/[id].vue?macro=true";
import { default as index6pLBr3XoPfMeta } from "/builds/bazinga/shkb.next/frontend/layers/wizard/pages/order/index.vue?macro=true";
import { default as indexxkSzVYhUR2Meta } from "/builds/bazinga/shkb.next/frontend/pages/search/index.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: index5cdCBRywCCMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "wizard-consultation-form",
    path: "/beratungstermin/:id",
    meta: _91id_9319scapHsAeMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/layers/wizard/pages/consultation/[id].vue")
  },
  {
    name: "customer-authentication",
    path: "/customer-authentication/webform/:webform_id/:submission_token/:authentication_token",
    meta: indexT0RUgT0kSlMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/layers/customer-authentication/pages/customer-authentication/index.vue")
  },
  {
    name: "edit-follow-menu",
    path: "/edit/follow-menu",
    meta: indexeEOKJ2xfAHMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/edit/follow-menu/index.vue")
  },
  {
    name: "form-webform_id-confirmation",
    path: "/form/:webform_id()/confirmation",
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/form/[webform_id]/confirmation.vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/media/[id].vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    meta: indexB5vVFhIGwFMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/newsletter/index.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexRNFkDH2hNHMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "wizard-order-form",
    path: "/produktbestellung/:id",
    meta: _91id_934a6D7CVn7pMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/layers/wizard/pages/order/[id].vue")
  },
  {
    name: "wizard-order-start",
    path: "/produktbestellung",
    meta: index6pLBr3XoPfMeta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/layers/wizard/pages/order/index.vue")
  },
  {
    name: "search",
    path: "/suche",
    meta: indexxkSzVYhUR2Meta || {},
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/search/index.vue")
  },
  {
    name: "webform-node-confirmation",
    path: "/node/:nid/webform/confirmation",
    component: () => import("/builds/bazinga/shkb.next/frontend/pages/form/[webform_id]/confirmation.vue")
  }
]