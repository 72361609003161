/**
 * Redirects to the new wizard URLs.
 */
export default defineNuxtRouteMiddleware((to) => {
  const consultation = to.query.consultation
  const order = to.query.order

  // Redirect to the new order wizard.
  if (order && typeof order === 'string') {
    return navigateTo({
      name: 'wizard-order-form',
      params: {
        id: order,
      },
    })
  }

  // Redirect to the new consultation wizard.
  if (consultation && typeof consultation === 'string') {
    return navigateTo({
      name: 'wizard-consultation-form',
      params: {
        id: consultation,
      },
    })
  }
})
