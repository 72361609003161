<template>
  <section
    :id="options.anchorId"
    class="layout-grid section-padding col-start-1 col-end-[-1]"
    :class="classes"
  >
    <BlokkliField
      :list="paragraphsHeader"
      name="field_paragraphs_header"
      field-list-type="header"
      class="col-start-2 col-end-[-2] section-title"
    />
    <BlokkliField
      :list="paragraphs"
      name="field_paragraphs"
      class="col-start-2 col-end-[-2] grid gap-15 md:gap-0 md:flex md:shadow-md"
      :class="{
        'md:border md:border-gray-300': options.sectionBackground === 'white',
      }"
    />
  </section>
</template>

<script lang="ts" setup>
import type { ParagraphGridFragment } from '#graphql-operations'

defineProps<{
  paragraphsHeader?: ParagraphGridFragment['paragraphsHeader']
  paragraphs?: ParagraphGridFragment['paragraphs']
}>()

const { options } = defineBlokkli({
  bundle: 'steps',
  globalOptions: ['sectionBackground', 'anchorId'],
  editor: {
    addBehaviour: 'no-form',
  },
})

const { classes } = useParagraphContext(options)
</script>

<style lang="postcss">
.paragraph-steps-item {
  &:not(:last-child) {
    @screen md {
      @apply border-r border-r-gray-300;
    }
  }
}
</style>
