import { notNullish } from '@vueuse/core'
import type {
  GlobalConfigPageLinkFragment,
  GlobalConfigPageNodeFragment,
  MenuLinkTreeFragment,
  ParagraphTitleLeadLinkFragment,
  TaxonomyTermIconsFragment,
} from '#graphql-operations'
import sup from '~/helpers/sup'

type LinkWithSubtree = MenuLinkTreeFragment & { subtree: LinkWithSubtree[] }

type MenuLinkCounter = {
  count: number
}

export type MappedMenuLink = {
  id: number
  label: string
  url?: string
  attributes?: Record<string, string>
  icon?: TaxonomyTermIconsFragment
  paragraph?: ParagraphTitleLeadLinkFragment
  links: MappedMenuLink[]
}

export type SocialMediaLink = {
  label: string
  url: string
  icon: TaxonomyTermIconsFragment
}

export type FooterLink = {
  label: string
  url: string
}

export interface InitData {
  mainMenuLinks: MappedMenuLink[]
  footerMenuLinks: FooterLink[]
  socialMediaLinks: SocialMediaLink[]
  translations: Record<string, string | [string, string]>
  oidNextEnabled: boolean
  globalConfig: {
    pageOnlineServices?: string
    pageNews?: string
    pageAgb?: string
    pagePrivacy?: string
    pagePeople?: string
  }
}

function getPageLink(
  v:
    | GlobalConfigPageNodeFragment
    | GlobalConfigPageLinkFragment
    | undefined
    | null,
) {
  if (!v) {
    return ''
  }

  if ('url' in v) {
    return v.url?.path
  }

  if ('uri' in v && v.uri) {
    if (
      'entity' in v.uri &&
      v.uri.entity &&
      'mediaFileUrl' in v.uri.entity &&
      v.uri.entity.mediaFileUrl?.path
    ) {
      return v.uri.entity.mediaFileUrl.path
    }

    return v.uri.path
  }
}

function mapLinks(
  links: LinkWithSubtree[],
  counter: MenuLinkCounter,
): MappedMenuLink[] {
  if (!links) {
    return []
  }
  return links.map((v) => {
    counter.count = counter.count + 1
    const url = v.link.url?.path
    const icon =
      v.link.content && 'icon' in v.link.content
        ? v.link.content.icon
        : undefined

    const paragraph =
      v.link.content &&
      'paragraphs' in v.link.content &&
      v.link.content.paragraphs
        ? v.link.content.paragraphs[0]
        : undefined
    return {
      id: counter.count,
      label: sup(v.link.label.replace(/(<([^>]+)>)/gi, '')),
      url,
      attributes: url?.startsWith('http') ? { target: '_blank' } : undefined,
      links: mapLinks(v.subtree, counter),
      icon,
      paragraph,
    }
  })
}

export default async function (): Promise<Ref<InitData>> {
  const config = useRuntimeConfig()
  // Read the host from .env NUXT_HOST to support multisite setup.
  const url = useRequestURL()
  let host = url.hostname
  if (import.meta.server) {
    host = config.requestHost
  }
  const data = useState<InitData>('initData')

  // Let's try to fetch the data from the cache first.
  const event = useRequestEvent()
  const { value, addToCache } = await useDataCache<InitData>('initData', event)
  if (value) {
    data.value = value
    return data
  }

  // If the data is not in the cache, we fetch it from the payload / useState().
  if (data.value) {
    return data
  }

  // Fetch the data from the server.
  data.value = await useGraphqlQuery({
    name: 'initData',
    fetchOptions: {
      query: {
        __server: import.meta.server ? 'true' : undefined,
      },
      headers: {
        host,
        'x-forwarded-host': host,
      },
    },
  }).then((v) => {
    const initData = {
      mainMenuLinks: mapLinks(v.data.mainMenu?.links || [], { count: 0 }),
      footerMenuLinks: (v.data.footerMenu?.links || [])
        .map((link) => {
          if (link.link.url?.path && link.link.label) {
            if (
              'entity' in link.link.url &&
              link.link.url.entity &&
              'mediaFileUrl' in link.link.url.entity &&
              link.link.url.entity.mediaFileUrl?.path
            ) {
              return {
                label: link.link.label,
                url: link.link.url.entity.mediaFileUrl.path,
              }
            }

            return {
              label: link.link.label,
              url: link.link.url.path,
            }
          }

          return null
        })
        .filter(notNullish),
      socialMediaLinks: (v.data.socialMedia.items || [])
        .map((v) => {
          if (v && 'icon' in v && v.label && v.link?.uri?.path && v.icon?.id) {
            return {
              label: v.label,
              url: v.link.uri.path,
              icon: v.icon,
            }
          }

          return null
        })
        .filter(notNullish),
      oidNextEnabled: !!v.data.onlineOrderConfig?.oidEnabled,
      globalConfig: {
        pageOnlineServices: getPageLink(
          v.data.globalConfig?.pageOnlineServices,
        ),
        pagePrivacy: getPageLink(v.data.globalConfig?.linkPrivacy),
        pageNews: getPageLink(v.data.globalConfig?.pageNews),
        pageAgb: getPageLink(v.data.globalConfig?.linkAgb),
        pagePeople: getPageLink(v.data.globalConfig?.pagePeople),
      },
      translations: Object.entries(
        (v.data.translations || {}) as Record<
          string,
          string | { singular?: string; plural?: string }
        >,
      ).reduce<Record<string, string | [string, string]>>(
        (acc, [fullKey, value]) => {
          const keyWithDots = fullKey.replace('__', '.')
          if (typeof value === 'string') {
            acc[keyWithDots] = value
          } else if (
            typeof value === 'object' &&
            value.plural &&
            value.singular
          ) {
            acc[keyWithDots] = [value.singular, value.plural]
          }
          return acc
        },
        {},
      ),
    }

    if (
      import.meta.server &&
      v.__cacheability?.isCacheable &&
      v.__cacheability.tagsNuxt
    ) {
      addToCache(initData, v.__cacheability.tagsNuxt)
    }

    return initData
  })

  return data
}
