<template>
  <section
    :id="options.anchorId || uuid"
    class="layout-grid col-start-1 col-end-[-1] paragraph-two-columns"
    :class="[
      classes,
      {
        'is-reversed': options.isReversed,
        'items-start': options.verticalAlign === 'top',
        'items-center': options.verticalAlign === 'center',
        'items-end': options.verticalAlign === 'bottom',
        '!p-0': options.size === 'pull',
      },
      'is-size-' + options.size,
      'has-padding-' + options.sectionPadding,
    ]"
  >
    <BlokkliField
      :list="paragraphsHeader"
      name="field_paragraphs_header"
      list-class="grid-area-t !min-h-0"
      non-empty-class="section-title"
    />

    <BlokkliField
      :list="paragraphsLeft"
      name="field_paragraphs"
      class="grid-area-l"
    />
    <BlokkliField
      :list="paragraphsRight"
      name="field_paragraphs_two"
      class="grid-area-r mt-24 md:mt-0"
    />
  </section>
</template>

<script lang="ts" setup>
import type { ParagraphTwoColumnsFragment } from '#graphql-operations'

defineProps<{
  paragraphsHeader?: ParagraphTwoColumnsFragment['paragraphsHeader']
  paragraphsLeft?: ParagraphTwoColumnsFragment['paragraphsLeft']
  paragraphsRight?: ParagraphTwoColumnsFragment['paragraphsRight']
}>()

const { options, uuid } = defineBlokkli({
  bundle: 'two_columns',
  globalOptions: [
    'sectionBackground',
    'verticalAlign',
    'sectionPadding',
    'anchorId',
    'hasBorder',
  ],
  options: {
    size: {
      type: 'radios',
      label: 'Grösse',
      default: 'normal',
      options: {
        pull: 'Pull',
        normal: '50%',
        third: '33%',
        small: '25%',
        tiny: '16%',
      },
    },
    isReversed: {
      type: 'checkbox',
      label: 'Umkehren',
      default: false,
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

const { classes } = useParagraphContext(options)
</script>

<style lang="postcss">
.paragraph-two-columns {
  &.is-size-third,
  &.is-size-tiny,
  &.is-size-small {
    .grid-area-r {
      .paragraph-link,
      .paragraph-link .button,
      .ck-content .button {
        @apply w-full;
      }
    }
  }
  --paragraph-two-columns-padding-top: 0;
  --paragraph-two-columns-padding-bottom: 0;
  grid-template-rows: auto auto auto;

  &.has-padding-both,
  &.has-padding-top {
    --paragraph-two-columns-padding-top: var(--section-padding);
  }

  &.has-padding-both,
  &.has-padding-bottom {
    --paragraph-two-columns-padding-bottom: var(--section-padding);
  }
  grid-template-areas:
    '. T T T T T T T T T T T T .'
    '. L L L L L L L L L L L L .'
    '. R R R R R R R R R R R R .';
  @screen md {
    grid-template-rows: auto auto auto;
    &.is-size-normal {
      grid-template-areas:
        '. T T T T T T T T T T T T .'
        '. L L L L L L R R R R R R .';
      &:not(.is-reversed) {
        .grid-area-r {
          @apply pl-32;
        }
      }

      &.is-reversed {
        grid-template-areas:
          '. T T T T T T T T T T T T .'
          '. R R R R R R L L L L L L .';
        .grid-area-l {
          @apply pl-32;
        }
      }
    }
    &.is-size-third {
      grid-template-areas:
        '. T T T T T T T T T T T T .'
        '. L L L L L L L . R R R R .';

      &.is-reversed {
        grid-template-areas:
          '. T T T T T T T T T T T T .'
          '. R R R R L L L L L L L L .';
      }
    }
    &.is-size-small {
      grid-template-areas:
        '. T T T T T T T T T T T T .'
        '. L L L L L L L L L R R R .';

      &.is-reversed {
        grid-template-areas:
          '. T T T T T T T T T T T T .'
          '. R R R L L L L L L L L L .';
      }
    }

    &.is-size-tiny {
      grid-template-areas:
        '. T T T T T T T T T T T T .'
        '. L L L L L L L L L L R R .';

      &.is-reversed {
        grid-template-areas:
          '. T T T T T T T T T T T T .'
          '. R R L L L L L L L L L L .';
      }
    }

    &.is-size-pull {
      grid-template-areas:
        '. T T T T T T . R R R R R R'
        '. L L L L L L . R R R R R R';

      &.is-reversed {
        grid-template-areas:
          'R R R R R R . T T T T T T .'
          'R R R R R R . L L L L L L .';
      }
    }
  }
}
</style>
