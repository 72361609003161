<template>
  <Component
    :is="to ? NuxtLink : 'div'"
    :to="to"
    class="flex py-8 gap-5 leading-none group lg:text-lg"
    :class="{
      'pointer-events-none text-gray-400': !to,
    }"
  >
    <div
      class="size-20 border border-gray-400 flex items-center justify-center shrink-0 -mt-3 lg:mt-2"
      :class="{
        'bg-primary-600': isSelected,
        '!border-gray-200': !to,
      }"
    >
      <SpriteSymbol
        name="check"
        class="size-[0.7em] text-white group-hover:text-gray-300"
        :class="{
          '!text-black': isSelected,
        }"
      />
    </div>
    <span>{{ label }}</span>
    <span class="ml-auto">{{ count }}</span>
  </Component>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'

const props = defineProps<{
  facetName: string
  id: string
  count: number
  selected: string[]
}>()

const route = useRoute()

const queryKey = computed(() => props.facetName + ':' + props.id)

const isSelected = computed(() => props.selected.includes(queryKey.value))

const to = computed(() => {
  if (!props.count) {
    return
  }
  if (isSelected.value) {
    const after = props.selected.filter((v) => v !== queryKey.value).join('--')
    return {
      query: {
        ...route.query,
        f: after || undefined,
      },
    }
  }

  return {
    query: {
      ...route.query,
      f: [...props.selected, queryKey.value].filter(Boolean).join('--'),
    },
  }
})

const LABEL_MAP: Record<string, string> = {
  service: 'Produkt / Dienstleistung',
  page: 'Seite',
  file: 'Dokument',
  news: 'News',
  webform: 'Online-Formular',
  agency: 'Filiale',
  event: 'Veranstaltung',
  financial_data: 'Finanzdaten',
  person: 'Person',
}

const label = computed(() => {
  return LABEL_MAP[props.id] || props.id
})
</script>
