
export const DPR = ["","1.5","2"]

export const VIEWPORTS = {
  'sm': 420,
  'md': 768,
  'lg': 1024,
  'xl': 1216,
  '2xl': 1440,
  '3xl': 1680,
  '4xl': 2560
} as const

export type Viewport = keyof typeof VIEWPORTS