<template>
  <div class="grid grid-cols-2">
    <div>
      <template v-if="featuresMapped?.length">
        <h4 class="typo-h4">Merkmale</h4>
        <FeatureList :features="featuresMapped" class="mt-15" />
      </template>
    </div>
    <div>
      <template v-if="openingHoursInside?.length">
        <h3 class="typo-h4">Selbstbedienungszone Innenbereich</h3>
        <h4 class="font-bold mb-10">Öffnungszeiten</h4>
        <OpeningHours :days="openingHoursInside" />
      </template>

      <template v-if="openingHoursOutside?.length">
        <h3 class="typo-h4">Selbstbedienungszone Aussenbereich</h3>
        <h4 class="font-bold">Öffnungszeiten</h4>
        <OpeningHours :days="openingHoursOutside" />
      </template>

      <a
        v-if="googleMapsLink"
        class="button mt-20"
        :href="googleMapsLink"
        target="_blank"
        rel="noopener"
      >
        {{ $texts('show_on_google_maps', 'In Google Maps anzeigen') }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { notNullish } from '@vueuse/core'
import type { NodeBancomatFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  address?: NodeBancomatFragment['address']
  features?: NodeBancomatFragment['features']
  mapsId?: NodeBancomatFragment['mapsId']
  mapsUrl?: NodeBancomatFragment['mapsUrl']
  openingHoursInside?: NodeBancomatFragment['openingHoursInside']
  openingHoursOutside?: NodeBancomatFragment['openingHoursOutside']
}>()

const { $texts } = useEasyTexts()

const googleMapsLink = computed(() => {
  if (props.mapsUrl?.uri?.path) {
    return props.mapsUrl.uri.path
  }

  if (!props.mapsId) {
    return
  }
  return 'https://goo.gl/maps/' + props.mapsId
})

const featuresMapped = computed(() =>
  props.features?.map((v) => v.label).filter(notNullish),
)
</script>
