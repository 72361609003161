<template>
  <div
    :id="options.anchorId"
    class="layout-grid col-start-1 col-end-[-1] layout-grid"
    :class="classes"
  >
    <BlokkliField
      :list="paragraphsHeader"
      name="field_paragraphs_header"
      class="col-start-2 col-end-[-2]"
      non-empty-class="section-title"
    />
    <div
      class="grid md:grid-cols-3 gap-24 col-start-2 col-end-[-2]"
      :class="{
        'items-start': options.verticalAlign === 'top',
        'items-center': options.verticalAlign === 'center',
        'items-end': options.verticalAlign === 'bottom',
      }"
    >
      <BlokkliField :list="paragraphsLeft" name="field_paragraphs" />
      <BlokkliField :list="paragraphsCenter" name="field_paragraphs_two" />
      <BlokkliField :list="paragraphsRight" name="field_paragraphs_three" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphThreeColumnsFragment } from '#graphql-operations'

defineProps<{
  paragraphsHeader?: ParagraphThreeColumnsFragment['paragraphsHeader']
  paragraphsLeft?: ParagraphThreeColumnsFragment['paragraphsLeft']
  paragraphsCenter?: ParagraphThreeColumnsFragment['paragraphsCenter']
  paragraphsRight?: ParagraphThreeColumnsFragment['paragraphsRight']
}>()

const { options } = defineBlokkli({
  bundle: 'three_columns',
  globalOptions: [
    'sectionBackground',
    'verticalAlign',
    'sectionPadding',
    'anchorId',
    'hasBorder',
  ],
  editor: {
    addBehaviour: 'no-form',
  },
})

const { classes } = useParagraphContext(options)
</script>
