<template>
  <svg
    class="pointer-events-none block eyecatcher-svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 100 100"
    :alt="alt"
  >
    <g>
      <circle
        class="transition duration-300"
        :class="inverted ? 'fill-white' : 'fill-primary-500'"
        cx="50"
        cy="50"
        r="50"
      />

      <text
        class="font-bold"
        :font-size="fontSizeValue"
        :y="textY"
        transform="rotate(-10)"
        transform-origin="center"
      >
        <tspan
          v-for="(line, i) in lines"
          :key="i"
          text-anchor="middle"
          :dy="fontSizeValue * lineHeight"
          x="50"
          v-html="line"
        />
      </text>
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { SUP_RGX } from '~/helpers/sup'

const props = defineProps<{
  text: string
  fontSize: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
  inverted?: boolean
}>()

const alt = computed(() => props.text.replaceAll('$', ' '))

const lines = computed(() =>
  props.text
    .split('$')
    .map((v) =>
      v.replace(SUP_RGX, '$1 $2<tspan alignment-baseline="top">$3</tspan>'),
    ),
)

const fontSizeValue = computed(() => {
  switch (props.fontSize) {
    case 'xs':
      return 7
    case 's':
      return 9
    case 'm':
      return 11
    case 'l':
      return 13
    case 'xxl':
      return 18
  }
  return 15
})

const lineHeight = computed(() => 1.1)
const singleLineHeight = computed(() => fontSizeValue.value * lineHeight.value)
const totalTextHeight = computed(
  () => singleLineHeight.value * lines.value.length,
)

const textY = computed(
  () => 50 - totalTextHeight.value / 2 - fontSizeValue.value * 0.2,
)
</script>

<style lang="postcss">
.eyecatcher-svg {
  tspan > tspan {
    font-size: 0.5em;
    baseline-shift: 0.75em;
    @apply font-heavy;
  }
}
</style>
