<template>
  <slot
    v-if="url"
    :url="url"
    :label="label"
    :entity-icon="icon"
    :symbol="symbol"
    :attributes="attributes"
  />
</template>

<script lang="ts" setup>
import type {
  FieldItemTypeLinkWithEntityFragment,
  FieldItemTypeLinkWithEntityLinkedEntityFragment,
  TaxonomyTermIconsFragment,
} from '#build/graphql-operations'
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite/runtime'

const props = defineProps<{
  title?: string
  uri?: FieldItemTypeLinkWithEntityFragment['uri']
}>()

const entity = computed<
  FieldItemTypeLinkWithEntityLinkedEntityFragment | undefined
>(() => {
  if (props.uri && 'entity' in props.uri) {
    return props.uri.entity
  }
  return undefined
})

const label = computed(() => {
  if (props.title) {
    return props.title
  }

  if (entity.value?.label) {
    return entity.value.label
  }
  return ''
})

const icon = computed<TaxonomyTermIconsFragment | undefined>(() => {
  if (entity.value && 'icon' in entity.value) {
    return entity.value.icon
  }
  return undefined
})

const url = computed(() => {
  if (
    entity.value &&
    'mediaFileUrl' in entity.value &&
    entity.value.mediaFileUrl?.path
  ) {
    return entity.value.mediaFileUrl.path
  }

  return props.uri?.path
})

const symbol = computed<NuxtSvgSpriteSymbol | undefined>(() => {
  if (url.value && url.value.includes('.pdf')) {
    return 'pdf'
  }
  return undefined
})

const attributes = computed<Partial<HTMLAnchorElement['attributes']>>(() => {
  if (url.value && url.value.startsWith('http')) {
    return {
      rel: 'nofollow',
      target: '_blank',
    }
  }

  return {}
})
</script>
