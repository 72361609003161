import type {
  NodeAgencyFragment,
  NodeEventFragment,
  NodeFinancialDataFragment,
  NodeNewsFragment,
  NodePageFragment,
  NodePublicationFragment,
  NodeServiceFragment,
  NodeWebformFragment,
} from '#build/graphql-operations'

type AnyNode =
  | NodePageFragment
  | NodeServiceFragment
  | NodeAgencyFragment
  | NodeEventFragment
  | NodeNewsFragment
  | NodeWebformFragment
  | NodeFinancialDataFragment
  | NodePublicationFragment

export default function (node?: AnyNode): ComputedRef<AnyNode | null> {
  if (node) {
    const currentNode = computed(() => node)
    provide('current_node', currentNode)
    return currentNode
  }

  const injected = inject<ComputedRef<AnyNode | null> | null>(
    'current_node',
    null,
  )

  return computed(() => injected?.value || null)
}
