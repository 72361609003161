<template>
  <NodePersonTeaser
    v-if="parentType === 'grid' && (person || isEditing)"
    v-bind="person"
    :is-boxed="parentBackground !== 'white'"
    :show-consultancy="showConsultancy"
  />
  <div v-else-if="person || isEditing" class="not-first:mt-24">
    <NodePersonBusinessCard
      v-bind="person"
      :show-consultancy="showConsultancy"
    />
  </div>
</template>

<script lang="ts" setup>
import type { NodePersonTeaserFragment } from '#graphql-operations'

const { options, isEditing, parentType } = defineBlokkli({
  bundle: 'person',
  options: {
    showCta: {
      type: 'checkbox',
      label: 'CTA anzeigen',
      default: true,
    },
  },
  editor: {
    editTitle: (el) => el.textContent,
    getDraggableElement: (el) => el.querySelector('.ck-content'),
  },
})

const { parentBackground } = useParagraphContext()

const props = defineProps<{
  person?: NodePersonTeaserFragment
}>()

const showConsultancy = computed(
  () => props.person?.showConsultancy && options.value.showCta,
)
</script>
