<template>
  <div class="flex flex-col justify-between flex-[2]">
    <p v-blokkli-editable:field_title v-html="$sup(title)" />
    <div v-if="to" class="mt-15">
      <VuepalLink :to="to" class="button is-primary is-small">{{
        linkTitle
      }}</VuepalLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTitleLeadLinkFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'title_lead_link',
  renderFor: [
    {
      parentBundle: 'follow_menu_item',
    },
  ],
  editor: {
    addBehaviour: 'no-form',
  },
})

const props = defineProps<{
  title?: string
  lead?: string
  link?: ParagraphTitleLeadLinkFragment['link']
}>()

const linkedEntity = computed(() => {
  if (props.link?.uri && 'entity' in props.link.uri) {
    return props.link.uri.entity
  }
  return undefined
})

const to = computed(() => props.link?.uri?.path)
const linkTitle = computed(() => props.link?.title || linkedEntity.value?.label)
const { $sup } = useNuxtApp()
</script>
