<template>
  <VuepalLink
    :to="url"
    class="p-20 border teaser-default block print:hidden"
    :class="{
      'bg-gray-50 border-gray-200 hover:bg-white hover:border-gray-300 focus-visible:outline-primary-500 focus:outline-none':
        parentBackground === 'white',
      'bg-white border-gray-400 hover:bg-white hover:border-gray-600':
        parentBackground === 'grayLight' || parentBackground === 'grayDark',
      'bg-white border-primary-700 hover:bg-white hover:border-primary-800 shadow-md':
        parentBackground === 'yellow',
      'bg-gray-900 border-gray-700 hover:bg-gray-800 hover:border-gray-600 text-white':
        parentBackground === 'black',
    }"
  >
    <TaxonomyTermIcons
      v-if="icon"
      class="float-right size-44 ml-8"
      v-bind="icon"
    />
    <h3 class="font-bold text-xl" v-html="$sup(title)" />
    <p v-if="text" class="mt-8 lg:text-lg" v-html="$sup(text)" />
  </VuepalLink>
</template>

<script lang="ts" setup>
import type { TaxonomyTermIconsFragment } from '#graphql-operations'

defineProps<{
  title: string
  url: string
  text?: string
  icon?: TaxonomyTermIconsFragment
  parentBackground?: BackgroundColor
}>()

defineOptions({
  name: 'TeaserDefault',
})
const { $sup } = useNuxtApp()
</script>
