<template>
  <div>
    <div class="business-card" itemscope itemtype="https://schema.org/Person">
      <div v-if="image" class="float-right ml-10">
        <MediaImage v-bind="image" :image-style="imageStyle" />
      </div>
      <h3 itemprop="name" class="font-bold text-xl">
        {{ firstName }}
        {{ lastName }}
      </h3>
      <div v-if="details?.length" itemprop="jobTitle" class="lg:text-lg">
        <div v-for="(detail, index) in details" :key="index">
          {{ detail }}
        </div>
      </div>
      <div class="grid mt-15">
        <FieldItemTypeTelephone
          v-slot="{ label }"
          v-bind="phone"
          class="link lg:text-lg"
        >
          <SpriteSymbol name="phone" />
          <span>{{ label }}</span>
        </FieldItemTypeTelephone>
        <a
          v-if="emailLink"
          itemprop="email"
          :href="emailLink"
          class="link is-grey lg:text-lg"
          rel="noopener"
        >
          <SpriteSymbol name="email" />
          <span>{{ email }}</span>
        </a>
      </div>
    </div>

    <NuxtLink
      v-if="consultationLink"
      :to="consultationLink"
      class="button is-dark w-full mt-28 print:hidden"
    >
      <SpriteSymbol name="contactperson" />
      <span>Beratungstermin vereinbaren</span>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { NodePersonTeaserFragment } from '#graphql-operations'

const props = defineProps<{
  nid?: string
  firstName?: string
  lastName?: string
  url?: NodePersonTeaserFragment['url']
  email?: string
  phone?: NodePersonTeaserFragment['phone']
  details?: string[]
  image?: NodePersonTeaserFragment['image']
  showConsultancy?: boolean
}>()

const node = useCurrentNode()

const imageStyle = defineImageStyle({
  type: 'pictures',
  aspectRatio: 1,
  pictures: {
    sm: {
      width: 90,
      height: 90,
    },
    xl: {
      width: 110,
      height: 110,
    },
    '2xl': {
      width: 130,
      height: 130,
    },
  },
})

const emailLink = computed(() => (props.email ? `mailto:${props.email}` : null))

const consultationLink = computed(() => {
  if (props.showConsultancy && node.value?.id) {
    return {
      name: 'wizard-consultation-form',
      params: {
        id: node.value.id,
      },
    }
  }
  return undefined
})
</script>
