export type MobilePane = 'menu' | 'contact' | 'search'

export default function () {
  const openMobilePaneState = useState<MobilePane | null>(
    'open_mobile_pane',
    () => null,
  )

  const hasMobilePaneTransition = useState<boolean>(
    'has_mobile_pane_transition',
    () => true,
  )

  const setMobilePane = (pane: MobilePane | null) => {
    // Closing has a transition.
    if (!pane) {
      hasMobilePaneTransition.value = true
    } else if (!openMobilePaneState.value) {
      hasMobilePaneTransition.value = true
    } else {
      hasMobilePaneTransition.value = false
    }

    openMobilePaneState.value = pane
  }

  const closeMobilePane = () => {
    setMobilePane(null)
  }

  const toggleMobilePane = (pane: MobilePane) => {
    if (openMobilePaneState.value === pane) {
      closeMobilePane()
    } else {
      setMobilePane(pane)
    }
  }

  const openMobilePane = computed<MobilePane | null>(
    () => openMobilePaneState.value,
  )

  const hasTransition = computed(() => hasMobilePaneTransition.value)

  return {
    openMobilePane,
    closeMobilePane,
    toggleMobilePane,
    hasTransition,
  }
}
