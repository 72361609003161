<template>
  <a
    v-if="isGallery"
    class="block"
    :href="imageFull?.mediaFileUrl?.path"
    :data-pswp-width="sourceWidth"
    :data-pswp-height="sourceHeight"
    data-cropped="true"
    target="_blank"
  >
    <MediaImage
      v-bind="image"
      :image-style="imageStyle"
      :show-caption="options.showCaption"
      img-class="aspect-[4/3] object-cover"
    />
  </a>
  <div
    v-else
    class="relative"
    :class="[
      isHero ? 'col-start-1 col-end-[-1]' : 'col-start-2 col-end-[-2]',
      {
        'my-24 last:mb-0 first:mt-0': parentType,
        'float-left w-112 md:w-auto mr-16 mb-10 md:mr-32':
          isInline && options.float === 'left',
        'float-right w-112 md:w-auto ml-10 mb-10':
          isInline && options.float === 'right',
      },
    ]"
  >
    <MediaImage
      v-blokkli-droppable:field_media_image
      v-bind="image"
      :image-style="imageStyle"
      :inline="isInline"
      :show-caption="options.showCaption"
      :caption-class="isHero ? 'px-16' : ''"
      :class="{
        'mt-16': isInline && options.float === 'none',
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type { ParagraphImageFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphImage',
})

const props = defineProps<{
  image?: ParagraphImageFragment['image']
  imageFull?: ParagraphImageFragment['imageFull']
}>()

const { parentType, options } = defineBlokkli({
  bundle: 'image',
  options: {
    isInline: {
      type: 'checkbox',
      label: 'Inline',
      default: false,
    },
    float: {
      type: 'radios',
      label: 'Float',
      default: 'none',
      displayAs: 'icons',
      options: {
        none: { label: 'Nein', icon: 'icon-blokkli-option-float-none' },
        left: { label: 'Links', icon: 'icon-blokkli-option-float-left' },
        right: { label: 'Rechts', icon: 'icon-blokkli-option-float-right' },
      },
    },
    isHero: {
      type: 'checkbox',
      label: 'Als Hero anzeigen',
      default: false,
    },
    showCaption: {
      type: 'checkbox',
      label: 'Bildunterschrift anzeigen',
      default: false,
    },
    aspectRatio: {
      type: 'radios',
      label: 'Bildverhältnis',
      default: 'original',
      options: {
        original: 'Original',
        '1_1': '1:1',
        '4_3': '4:3',
        '3_2': '3:2',
        '16_9': '16:9',
        '2_1': '2:1',
        '3_1': '3:1',
      },
    },
    width: {
      type: 'radios',
      label: 'Breite',
      default: '3',
      options: {
        '2': '2',
        '3': '3',
        '6': '6',
        '12': '12',
      },
    },
  },
  editor: {
    editTitle: (el) => {
      const img = el.querySelector('img')
      return img?.alt || img?.title
    },
    getDraggableElement: (el) => el.querySelector('figure'),
    determineVisibleOptions(ctx) {
      if (ctx.parentType === 'gallery') {
        return ['showCaption']
      }
      if (ctx.options.isInline) {
        return ['isInline', 'float', 'width', 'showCaption']
      }

      if (ctx.parentType) {
        return ['isInline', 'aspectRatio', 'showCaption']
      }

      return ['isInline', 'isHero', 'aspectRatio', 'showCaption']
    },
  },
})

const isGallery = computed(() => parentType.value === 'gallery')
const isInline = computed(() => !isGallery.value && options.value.isInline)
const isHero = computed(() => options.value.isHero && !isInline.value)

const aspectRatio = computed<number | undefined>(() => {
  if (!options.value.aspectRatio || options.value.aspectRatio === 'original') {
    return undefined
  }

  const [w, h] = options.value.aspectRatio
    .split('_')
    .map((v) => parseInt(v))
    .filter(notNullish)
  if (w && h && !isNaN(w) && !isNaN(h)) {
    return w / h
  }
  return undefined
})

const sourceWidth = computed(
  () => props.image?.image?.file?.rokka?.sourceWidth || 200,
)

const sourceHeight = computed(
  () => props.image?.image?.file?.rokka?.sourceHeight || 200,
)

const inlineWidth = computed(() => {
  if (options.value.width) {
    const numeric = parseInt(options.value.width)
    if (!isNaN(numeric)) {
      return numeric
    }
  }

  return 3
})

const imageStyle = computed(() => {
  if (parentType.value === 'gallery') {
    return defineImageStyle({
      type: 'sizes',
      sizes: {
        sm: 420,
      },
    })
  }
  if (options.value.isInline) {
    return defineImageStyle({
      type: 'single',
      width: Math.min(sourceWidth.value, inlineWidth.value * 86 + 24),
    })
  }

  return defineImageStyle({
    type: 'sizes',
    aspectRatio: aspectRatio.value,
    sizes: {
      sm: 984,
      md: 1380,
      lg: 1380,
    },
  })
})
</script>
