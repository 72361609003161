<template>
  <div
    class="layout-grid bg-primary-500 paragraph-wrapper-background print:hidden"
    :class="{ 'sticky bottom-0 z-50': options.sticky && !isEditing }"
  >
    <div
      class="col-start-2 col-end-[-2] py-16 md:py-32 lg:py-48 md:flex justify-between items-center md:gap-24"
    >
      <h3
        class="text-xl lg:text-3xl font-bold mb-20 md:mb-0 text-balance"
        v-html="$sup(title)"
      />
      <div>
        <VuepalLink
          :to="url"
          class="w-full md:w-auto button is-dark whitespace-nowrap justify-between"
        >
          <div v-html="$sup(linkTitle)" />
          <SpriteSymbol name="cta-circle" />
        </VuepalLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  CallToActionFragment,
  ParagraphCtaFragment,
} from '#graphql-operations'

const props = defineProps<{
  title?: string
  linkText?: string
  link?: ParagraphCtaFragment['link']
  product?: ParagraphCtaFragment['product']
}>()

const { $texts } = useEasyTexts()
const { $sup } = useNuxtApp()

const { options, isEditing } = defineBlokkli({
  bundle: 'cta',
  options: {
    sticky: {
      type: 'checkbox',
      label: 'Sticky (unten)',
      default: false,
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('a')?.innerText,
    previewWidth: 1680,
  },
})

const cta = computed<CallToActionFragment | undefined>(() => props.product?.cta)

const url = computed(() => props.link?.uri?.path || cta.value?.url.path)

const linkedEntity = computed(() => {
  if (props.link?.uri && 'entity' in props.link.uri && props.link.uri.entity) {
    return props.link.uri.entity
  }
  return undefined
})

const linkTitle = computed(() => {
  if (props.linkText) {
    return props.linkText
  } else if (cta.value?.label) {
    return cta.value.label
  } else if (
    props.link?.uri &&
    'routeName' in props.link.uri &&
    linkedEntity.value?.label
  ) {
    const label = linkedEntity.value.label
    const drupalRouteName = props.link.uri.routeName
    if (drupalRouteName === 'shkb_frontend.wizard_order') {
      return $texts('ctaButton.checkout', 'Jetzt @title bestellen').replace(
        '@title',
        label,
      )
    } else if (drupalRouteName === 'shkb_frontend.wizard_consultancy') {
      return $texts(
        'ctaButton.consultancy',
        'Jetzt Beratungstermin vereinbaren',
      )
    }
  }

  return 'Mehr erfahren'
})

defineOptions({
  name: 'ParagraphCta',
})
</script>
