<template>
  <div
    :id="options.anchorId"
    :class="classes"
    class="layout-grid col-start-1 col-end-[-1]"
  >
    <BlokkliField
      :list="paragraphsHeader"
      name="field_paragraphs_header"
      list-class="col-start-2 col-end-[-2] section-title"
    />
    <div
      class="col-start-2 col-end-[-2]"
      :class="{
        'until-md:col-start-1 until-md:col-end-[-1]': options.mobileScroll,
      }"
    >
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="grid gap-10 md:gap-24 grid-cols-1 paragraph-grid"
        :class="{
          'paragraph-grid-scroll': options.mobileScroll,
          'md:grid-cols-2':
            options.columns === 'two' || options.columns === 'three',
          'lg:grid-cols-3': options.columns === 'three',
          'has-some-visible': options.showMore && !showEverything && !isEditing,
        }"
        :drop-alignment="options.columns === 'one' ? 'vertical' : 'horizontal'"
      >
        <template #after="{ items }">
          <div
            v-if="options.showMore && !showEverything && items.length > 6"
            class="flex mt-24"
          >
            <button class="button" @click="showEverything = true">
              {{ options.showMoreText }}
            </button>
          </div>
        </template>
      </BlokkliField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphGridFragment } from '#graphql-operations'

defineProps<{
  paragraphsHeader?: ParagraphGridFragment['paragraphsHeader']
  paragraphs?: ParagraphGridFragment['paragraphs']
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'grid',
  globalOptions: ['sectionBackground', 'anchorId'],
  options: {
    columns: {
      type: 'radios',
      label: 'Spalten',
      default: 'three',
      displayAs: 'grid',
      options: {
        one: { label: 'Eine', columns: [1] },
        two: { label: 'Zwei', columns: [1, 1] },
        three: { label: 'Drei', columns: [1, 1, 1] },
      },
    },
    mobileScroll: {
      type: 'checkbox',
      label: 'Scrollbar auf Mobile',
      default: false,
    },
    showMore: {
      type: 'checkbox',
      label: '«Mehr anzeigen» ab 6',
      default: false,
    },
    showMoreText: {
      type: 'text',
      label: 'Text für «Mehr anzeigen»',
      default: 'Mehr anzeigen',
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

const { classes } = useParagraphContext(options)

const showEverything = ref(false)
</script>

<style lang="postcss">
.paragraph-grid-scroll {
  @apply until-md:flex until-md:overflow-auto until-md:gap-0 until-md:snap-x until-md:snap-proximity;

  @media screen and (max-width: theme('screens.md')) {
    > * {
      @apply shrink-0 snap-center;
      width: calc(100vw - 16px);
      margin-left: 16px;
      &:last-child {
        margin-right: 16px;
      }
      &.teaser-default,
      &.teaser-product {
        max-width: 320px;
      }
      &.teaser-recommendation {
        max-width: 250px;
      }
    }
  }
}

.paragraph-grid.has-some-visible {
  > *:nth-child(n + 7) {
    @apply !hidden;
  }
}
</style>
