<template>
  <div
    ref="root"
    class="absolute left-0 w-full bottom-0 md:bottom-auto top-50 md:top-full bg-white layout-grid shadow border-t-primary-500 border-t-6 md:mt-20 lg:mt-32 overflow-auto pb-80 md:pb-0 transition duration-500 md:overflow-visible"
    :class="{
      'mobile-only:!bg-gray-50': activeId,
    }"
    @keydown.left="goTo(-1)"
    @keydown.right="goTo(+1)"
  >
    <ul
      class="md:flex col-start-1 col-end-[-1] md:col-start-2 md:col-end-[-2] justify-between md:h-48 lg:h-[58px]"
    >
      <li
        v-for="(link, index) in links"
        :key="link.id"
        class="px-16 md:px-0 transition duration-500"
        :class="{
          'bg-white': activeId === link.id,
        }"
      >
        <button
          class="font-bold text-3xl md:text-sm lg:text-base xl:text-lg size-full flex items-center py-16 md:py-0 cursor-pointer focusable"
          :data-menu-id="link.id"
          :class="{
            'text-gray-400': activeId && activeId !== link.id,
          }"
          @mouseenter="setActive(link.id)"
          @mouseleave="cancelTimeout"
          @click.capture.prevent="onClick(link.id)"
        >
          <div v-html="$sup(link.label)" />
          <SpriteSymbol
            name="plus"
            class="size-20 md:hidden ml-auto text-gray-500"
            :class="{ 'rotate-45': activeId === link.id }"
          />
        </button>
        <AppHeaderSecondLevel
          :id="link.id"
          :label="link.label"
          :total="links.length"
          :links="link.links"
          :index="index"
          :active-id="activeId"
          :root-width="width"
          :has-transition="hasTransition || !activeId"
          :paragraph="link.paragraph"
          @close="setActive"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { onKeyStroke, useResizeObserver } from '@vueuse/core'
import type { MappedMenuLink } from '~/composables/useInitData'
import { modulo } from '~/helpers/numbers'

const props = defineProps<{
  links: MappedMenuLink[]
}>()

const { isMobile } = useViewport()
const { $sup } = useNuxtApp()

const activeId = defineModel<number>({ default: 0 })
const hasTransition = ref(false)
let timeout: any = null

const root = ref<HTMLDivElement | null>(null)
const width = ref(1024)

function getIndex(id: number): number {
  return props.links.findIndex((v) => v.id === id)
}

function onClick(id = 0) {
  if (isMobile.value && activeId.value === id) {
    hasTransition.value = true
    activeId.value = 0
    return
  }
  hasTransition.value = !activeId.value
  activeId.value = id
}

function goTo(indexMod: number) {
  if (!activeId.value) {
    return
  }
  const index = getIndex(activeId.value)
  if (index === -1) {
    return
  }

  const nextId = props.links[modulo(index + indexMod, props.links.length)]?.id
  if (nextId) {
    onClick(nextId)
    focusButton(nextId)
  }
}

function setActive(id = 0) {
  if (isMobile.value) {
    return
  }
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    hasTransition.value = !activeId.value
    activeId.value = id
  }, 300)
}

function cancelTimeout() {
  window.clearTimeout(timeout)
}

useResizeObserver(root, (entries) => {
  const entry = entries[0]

  if (!entry) {
    return
  }

  width.value = entry.borderBoxSize?.[0].inlineSize || 1024
})

function focusButton(id: number) {
  if (!root.value) {
    return
  }
  const el = root.value.querySelector(`[data-menu-id="${id}"]`)
  if (el instanceof HTMLButtonElement) {
    el.focus()
  }
}

onKeyStroke('Escape', function (e) {
  if (activeId.value && root.value) {
    focusButton(activeId.value)
    activeId.value = 0
    e.stopPropagation()
  }
})
</script>
