<template>
  <div class="features-list">
    <ul class="grid gap-10">
      <li v-for="(feature, i) in features" :key="i" class="flex gap-8">
        <div
          class="shrink-0 size-[18px] bg-primary-200 text-primary-800 rounded-full flex items-center justify-center"
        >
          <SpriteSymbol name="check" class="size-10" />
        </div>
        <div class="text-balance font-bold" v-html="$sup(feature)" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  features: string[]
}>()

const { $sup } = useNuxtApp()
</script>

<style lang="postcss">
.features-list {
  container-type: inline-size;

  @container (min-width: 700px) {
    > ul {
      @apply grid-cols-2 gap-x-24;
    }
  }
}
</style>
