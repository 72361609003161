<template>
  <div
    class="layout-grid items-center py-section paragraph-contact-teaser"
    :class="'is-' + display"
  >
    <div class="grid-area-l">
      <h2
        v-blokkli-editable:field_title
        class="font-bold typo-h2"
        v-html="$sup(title)"
      />
      <div
        v-blokkli-editable:field_lead
        class="font-bold text-gray-500 typo-lead-2"
        v-html="$sup(lead)"
      />
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="flex gap-16 flex-wrap mt-28"
      />
    </div>
    <div v-if="image" class="grid-area-r mt-24 md:mt-0">
      <MediaImage v-bind="image" :image-style="imageStyle" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContactTeaserFragment } from '#build/graphql-operations'

defineBlokkli({
  bundle: 'contact_teaser',
  editor: {
    editTitle: (el) => el.textContent,
    previewWidth: 1000,
  },
})

defineProps<{
  title?: string
  lead?: string
  display?: 'single' | 'multiple'
  image?: ParagraphContactTeaserFragment['image']
  paragraphs?: ParagraphContactTeaserFragment['paragraphs']
}>()

const { $sup } = useNuxtApp()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 15 / 12,
  sizes: {
    sm: 335,
    lg: 380,
    xl: 463,
    '2xl': 560,
    '3xl': 612,
  },
})
</script>

<style lang="postcss">
.paragraph-contact-teaser {
  grid-template-areas:
    '. L L L L L L L L L L L L .'
    '. R R R R R R R R R R R R .';

  @screen md {
    grid-template-areas: '. L L L L L L L L R R R R .';
  }

  &.is-multiple {
    @screen lg {
      grid-template-areas: '. L L L L L L L R R R R R .';
    }

    @screen 3xl {
      grid-template-areas: 'L L L L L L L L R R R R R R';
    }
  }

  &.is-single {
    @screen lg {
      grid-template-areas: '. L L L L L L L . R R R R .';
    }

    @screen 3xl {
      grid-template-areas: 'L L L L L L L L . R R R R .';
    }
  }
}
</style>
