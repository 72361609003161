<template>
  <figure
    v-if="structuredTable || isEditing"
    :id="options.anchorId"
    class="mt-16 lg:mt-36"
    @keydown.enter.capture="onClick"
  >
    <figcaption
      v-if="structuredTable?.caption"
      class="font-bold text-lg lg:text-2xl mb-10 lg:mb-32"
      v-html="$sup(structuredTable.caption)"
    />
    <StructuredTable
      v-if="structuredTable"
      v-bind="structuredTable"
      :id="uuid"
      :mobile-style="options.mobileStyle"
      :sticky="options.sticky"
      :equalize-widths="options.equalizeWidths"
    />
  </figure>
</template>

<script lang="ts" setup>
import type { ParagraphTableFragment } from '#graphql-operations'

const { onClick } = useClickTriggerProxy()

const { options, uuid, isEditing } = defineBlokkli({
  bundle: 'table',
  globalOptions: ['anchorId'],
  options: {
    equalizeWidths: {
      type: 'checkbox',
      label: 'Alle Spalten gleich breit',
      default: true,
    },
    mobileStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'horizontal',
      displayAs: 'icons',
      options: {
        vertical: {
          label: 'Vertikal',
          icon: 'icon-blokkli-option-table-vertical',
        },
        horizontal: {
          label: 'Horizontal',
          icon: 'icon-blokkli-option-table-horizontal',
        },
      },
    },
    sticky: {
      type: 'radios',
      label: 'Sticky',
      default: 'left',
      options: {
        none: '-',
        left: 'Links',
        top: 'Oben',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('figcaption')?.innerText,
    determineVisibleOptions: (ctx) => {
      if (ctx.options.mobileStyle === 'vertical') {
        return ['equalizeWidths', 'mobileStyle', 'anchorId']
      }

      return ['equalizeWidths', 'mobileStyle', 'sticky', 'anchorId']
    },
  },
})

defineProps<{
  structuredTable: ParagraphTableFragment['structuredTable']
}>()

defineOptions({
  name: 'ParagraphTable',
})

const { $sup } = useNuxtApp()
</script>
