<template>
  <div
    class="fixed bottom-16 left-16 md:left-[300px] right-[90px] shadow-lg rounded overflow-hidden z-[500] md:hidden print:hidden bg-white"
  >
    <div id="mobile-bar-before" />
    <div class="flex gap-1 bg-gray-100">
      <AppMobileBarButton pane="contact" icon="chat" title="Kontakt" />
      <AppMobileBarButton pane="menu" icon="menu" title="Menü" />
      <AppMobileBarButton pane="search" icon="search" title="Suche" />
    </div>
  </div>
</template>
