<template>
  <section
    class="layout-grid !p-0 border-b border-b-gray-300 paragraph-app overflow-hidden md:overflow-visible"
    :class="[
      classes,
      {
        'is-reversed': options.isReversed,
      },
      'is-' + style,
    ]"
  >
    <div class="grid-area-l">
      <h3
        v-blokkli-editable:field_title
        class="typo-h2 text-balance"
        :class="textClass"
        v-html="$sup(title)"
      />
      <p
        v-blokkli-editable:field_lead
        class="typo-lead-2"
        :class="textMutedClass"
        v-html="$sup(lead)"
      />
      <div class="grid md:flex gap-16 mt-36">
        <a
          v-if="urlApple"
          :href="urlApple"
          target="_blank"
          class="bg-gray-950 hover:bg-gray-900"
        >
          <img src="./app-apple.svg" class="h-56" />
        </a>

        <a
          v-if="urlGoogle"
          :href="urlGoogle"
          target="_blank"
          class="bg-gray-950 hover:bg-gray-900"
        >
          <img src="./app-google.svg" class="h-56" />
        </a>

        <FieldItemTypeLink v-slot="{ url, label }" v-bind="link">
          <VuepalLink :to="url" class="button">
            <div v-html="$sup(label)" />
          </VuepalLink>
        </FieldItemTypeLink>
      </div>
    </div>
    <aside
      v-if="style === 'screenshot'"
      class="grid-area-r relative overflow-hidden md:-mt-56 mb-[-300px] md:mb-0"
    >
      <div
        class="relative md:absolute md:right-0 md:max-w-[400px] mt-32 md:mt-0 md:top-[4%]"
      >
        <img
          src="./phone.png"
          height="1474"
          width="862"
          class="w-full relative z-40 pointer-events-none"
        />
        <MediaImage
          v-bind="imageScreenshot"
          :image-style="imageStyleScreenshot"
          class="absolute"
          :style="{
            width: (562 / WIDTH_BASE) * 100 + '%',
            top: (26 / WIDTH_BASE) * 100 + '%',
            left: (149 / WIDTH_BASE) * 100 + '%',
          }"
        />
      </div>
    </aside>
    <aside v-else-if="style === 'icon'" class="grid-area-r">
      <div class="app-icon max-w-[250px]">
        <MediaImage v-bind="imageIcon" :image-style="imageStyleIcon" />
      </div>
    </aside>
  </section>
</template>

<script lang="ts" setup>
import type { ParagraphAppFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  name?: string
  lead?: string
  link?: ParagraphAppFragment['link']
  imageIcon?: ParagraphAppFragment['imageIcon']
  imageScreenshot?: ParagraphAppFragment['imageScreenshot']
  appIdApple?: string
  appIdGoogle?: string
}>()

const { $sup } = useNuxtApp()

const { options } = defineBlokkli({
  bundle: 'app',
  globalOptions: ['sectionBackground'],
  options: {
    isReversed: {
      type: 'checkbox',
      default: false,
      label: 'Umkehren',
    },
    style: {
      type: 'radios',
      label: 'Darstellung',
      default: 'screenshot',
      options: {
        screenshot: 'Screenshot',
        icon: 'Icon',
      },
    },
  },
  editor: {
    editTitle: (el) => el.textContent,
    previewWidth: 1280,
    determineVisibleOptions(ctx) {
      if (!ctx.props.imageIcon || !ctx.props.imageScreenshot) {
        return ['sectionBackground', 'isReversed']
      }

      return ['sectionBackground', 'style', 'isReversed']
    },
  },
})

const { classes, textClass, textMutedClass } = useParagraphContext(options)

const urlApple = computed(() => {
  if (props.appIdApple) {
    return 'https://apps.apple.com/ch/app/' + props.appIdApple
  }
  return ''
})

const urlGoogle = computed(() => {
  if (props.appIdGoogle) {
    return `https://play.google.com/store/apps/details?id=${props.appIdGoogle.trim()}&hl=de_CH`
  }
  return ''
})

const imageStyleScreenshot = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 94,
    md: 240,
    lg: 260,
  },
})

const imageStyleIcon = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 94,
    md: 240,
    xl: 320,
  },
})

const WIDTH_BASE = 862

const style = computed(() => {
  if (props.imageScreenshot && options.value.style === 'screenshot') {
    return 'screenshot'
  } else if (props.imageIcon) {
    return 'icon'
  } else if (props.imageScreenshot) {
    return 'screenshot'
  }

  return 'empty'
})
</script>

<style lang="postcss">
@keyframes app-icon {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.app-icon {
  @apply relative aspect-square;
  figure {
    @apply relative z-20;
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.2));
  }
  img {
    mask-image: url('./app-icon-mask.svg');
    mask-size: 100% 100%;
  }

  &:before,
  &:after {
    content: '';
    @apply absolute top-0 left-0 w-full h-full pointer-events-none;
    border-radius: 25%;
    @apply border-2 border-gray-100;
    animation: 10.6s theme('transitionTimingFunction.swing') app-icon infinite;
    box-shadow: inset 0 0 3px 0px theme('colors.gray.400');
  }

  &:after {
    animation-delay: 0.3s;
  }
}

.paragraph-app {
  grid-template-rows: var(--section-padding) auto auto var(--section-padding);

  &.is-empty {
    grid-template-areas:
      '. . . . . . . . . . . . . .'
      '. L L L L L . . . . . . . .'
      '. L L L L L . . . . . . . .'
      '. . . . . . . . . . . . . .';

    &.is-reversed {
      grid-template-areas:
        'R R R R . . . . . . . . . .'
        'R R R R L L L L L L L . . .'
        'R R R R L L L L L L L . . .'
        'R R R R . . . . . . . . . .';
    }
  }

  &.is-screenshot {
    grid-template-areas:
      '. . . . . . . . . . . . . .'
      '. L L L L L L L L L L L L .'
      '. R R R R R R R R R R R R .'
      '. R R R R R R R R R R R R .';
    @screen md {
      grid-template-areas:
        '. . . . . . . . . R R R R R'
        '. L L L L L L L L R R R R R'
        '. L L L L L L L L R R R R R'
        '. . . . . . . . . R R R R R';

      &.is-reversed {
        grid-template-areas:
          'R R R R . . . . . . . . . .'
          'R R R R L L L L L L L . . .'
          'R R R R L L L L L L L . . .'
          'R R R R . . . . . . . . . .';
      }
    }

    @screen lg {
      grid-template-areas:
        '. . . . . . . . . R R R R .'
        '. L L L L L L L . R R R R .'
        '. L L L L L L L . R R R R .'
        '. . . . . . . . . R R R R .';

      &.is-reversed {
        grid-template-areas:
          'R R R R . . . . . . . . . .'
          'R R R R L L L L L L L . . .'
          'R R R R L L L L L L L . . .'
          'R R R R . . . . . . . . . .';
      }
    }
  }

  &.is-icon {
    @apply items-center overflow-hidden;
    grid-template-areas:
      '. . . . . . . . . . . . . .'
      '. L L L L L L L . . . R R .'
      '. L L L L L L L . . . R R .'
      '. . . . . . . . . . . . . .';

    &.is-reversed {
      grid-template-areas:
        '. . . . . . . . . . . . . .'
        '. R R R L L L L L L L . . .'
        '. R R R L L L L L L L . . .'
        '. . . . . . . . . . . . . .';
    }
  }
}
</style>
