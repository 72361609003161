<template>
  <figure v-if="src" class="video image">
    <video :autoplay="autoplay" muted loop>
      <source :src="src" :type="type" />
    </video>
  </figure>
</template>

<script setup lang="ts">
import type { MediaVideoFragment } from '#graphql-operations'

const props = defineProps<{
  url?: MediaVideoFragment['url']
  file?: MediaVideoFragment['file']
  autoplay?: boolean
}>()

const src = computed(() => {
  if (props.url?.path) {
    return props.url.path
  }
  return ''
})

const type = computed(() => props.file?.entity?.mime)
</script>
