<template>
  <VuepalLink
    class="layout-grid py-28 print:hidden"
    :to="url"
    :class="{
      'bg-primary-500': background === 'yellow',
      'bg-white border-b border-b-gray-200': background === 'white',
      'bg-gray-100': background === 'grayLight',
      'bg-gray-300': background === 'grayDark',
      'bg-gray-900': background === 'black',
    }"
  >
    <div class="col-start-2 col-end-[-2] flex justify-between items-center">
      <div>
        <h3
          class="font-bold text-xl"
          :class="{
            'text-black': background === 'yellow',
            'text-white': background === 'black',
          }"
          v-html="$sup(title)"
        />
        <div
          v-if="lead"
          :class="{
            'text-primary-900': background === 'yellow',
            'text-gray-300': background === 'black',
          }"
          v-html="$sup(lead)"
        />
      </div>

      <div class="button whitespace-nowrap" :class="buttonClass">
        {{ linkText }}
      </div>
    </div>
  </VuepalLink>
</template>

<script lang="ts" setup>
import type { BackgroundColor } from '~/composables/useParagraphContext'

const props = defineProps<{
  title: string
  lead?: string
  linkText?: string
  url: string
  background?: BackgroundColor
}>()

const { $sup } = useNuxtApp()

const buttonClass = computed(() => {
  if (props.background === 'yellow') {
    return 'is-dark'
  } else if (props.background === 'black') {
    return 'is-white'
  }

  return 'is-primary'
})

defineOptions({
  name: 'TeaserBanner',
})
</script>
