<template>
  <div>
    <div class="layout-grid border-b">
      <div
        class="grid md:grid-cols-3 gap-24 col-start-2 col-end-[-2] section-padding"
      >
        <MortgageInput
          id="purchasePrice"
          v-model.number="purchasePrice"
          :label="$texts('mortgage_label_buy_price', 'Kaufpreis der Immobilie')"
          prefix="CHF"
          :min="purchasePriceMin"
          :max="purchasePriceMax"
          :step="10000"
          :error="purchasePriceError"
          :help="
            $texts(
              'mortgage_label_buy_price_info',
              'Anschaffungspreis zuzüglich allfälliger Investitionen für Umbau, etc.',
            )
          "
          @input.once="hasEnteredPurchasePrice = true"
        />
        <MortgageInput
          id="ownFunds"
          v-model.number="ownFunds"
          :label="
            $texts('mortgage_label_equity_capital', 'Verfügbare Eigenmittel')
          "
          :min="ownFundsMin"
          :max="ownFundsMax"
          prefix="CHF"
          :step="ownFundsStep"
          :disabled="!hasEnteredPurchasePrice || !!purchasePriceError"
          :error="ownFundsError"
          :help="
            $texts(
              'mortgage_label_equity_capital_info',
              `Mindestens 20% des Kaufpreises müssen durch Eigenmittel abgedeckt sein`,
            )
          "
          @input.once="hasEnteredOwnFunds = true"
        />
        <MortgageInput
          id="income"
          v-model.number="income"
          :min="incomeMin"
          :max="incomeMax"
          prefix="CHF"
          :step="1000"
          :disabled="
            !purchasePrice ||
            !ownFunds ||
            !!purchasePriceError ||
            !!ownFundsError
          "
          :error="hasEnteredIncome ? incomeError : undefined"
          :label="
            $texts('mortgage_label_earnings', 'Jährliches Nettoeinkommen')
          "
          :help="
            $texts(
              'mortgage_label_earnings_info',
              'Erfassen Sie das jährliche Nettoeinkommen des Hauptverdieners.',
            )
          "
          @input.once="hasEnteredIncome = true"
        />
      </div>
    </div>

    <div
      v-if="showResults"
      :class="{ 'pointer-events-none opacity-30 select-none': !isValid }"
    >
      <MortgageResults
        :purchase-price="purchasePrice"
        :own-funds="ownFunds"
        :income="income"
        :rates="rates"
        :is-valid="isValid"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import MortgageInput from './Input/index.vue'
import MortgageResults from './Results/index.vue'
import { formatCurrency } from '~/helpers/numbers'

const { $texts } = useEasyTexts()

defineBlokkliFragment({
  name: 'mortgage_calculator',
  label: 'Hypothekenrechner',
  description: 'Zeigt den Hypothekenrechner an.',
  editor: {
    noPreview: true,
  },
})

const LOCAL_STORAGE_KEY = 'shkb_mortgage_input'

const { data: rates } = await useAsyncData<{ key: string; value: number }[]>(
  () =>
    useCachedGraphqlQuery('mortgageRates').then((v) =>
      Object.entries(v.data.globalConfig || {}).map(([key, value]) => {
        return { key, value }
      }),
    ),
  { default: () => [] },
)

let timeout: any = null

const hasEnteredPurchasePrice = ref(false)
const hasEnteredOwnFunds = ref(false)
const hasEnteredIncome = ref(false)

const purchasePrice = ref(0)
const ownFunds = ref(0)
const income = ref(0)

const purchasePriceMin = 30000
const purchasePriceMax = 5000000

const incomeMin = 0
const incomeMax = 250000

const ownFundsMin = computed(() => {
  const price = purchasePrice.value || 30000
  return Math.min(Math.round(price * 0.2 || 0), Math.round(price * 0.8))
})

const ownFundsMax = computed(() => {
  const price = purchasePrice.value || 30000
  return Math.min(price, purchasePriceMax)
})

const ownFundsStep = computed(() => {
  if (ownFundsMax.value < 100000) {
    return 1000
  }
  return 10000
})

const purchasePriceError = computed(() => {
  if (!hasEnteredPurchasePrice.value) {
    return ''
  }

  if (isNaN(purchasePrice.value)) {
    return $texts(
      'mortgage_validation_invalid_value',
      'Bitte geben Sie einen gültigen Wert ein.',
    )
  } else if (purchasePrice.value < purchasePriceMin) {
    return $texts(
      'mortgage_validation_buy_price_min',
      'Der Kaufpreis muss mindestens @amount betragen.',
    ).replace('@amount', formatCurrency(purchasePriceMin))
  } else if (purchasePrice.value > purchasePriceMax) {
    return $texts(
      'mortgage_validation_buy_price_max',
      'Der Kaufpreis muss weniger als @amount betragen.',
    ).replace('@amount', formatCurrency(purchasePriceMax))
  }

  return ''
})

const ownFundsError = computed(() => {
  if (isNaN(ownFunds.value)) {
    return $texts(
      'mortgage_validation_invalid_value',
      'Bitte geben Sie einen gültigen Wert ein.',
    )
  } else if (ownFunds.value < ownFundsMin.value) {
    const amount = formatCurrency(ownFundsMin.value)
    return $texts(
      'mortgage_validation_equity_min',
      'Die Eigenmittel müssen mindestens 20% @amount des Kaufpreises betragen.',
    ).replace('@amount', amount)
  } else if (ownFunds.value > ownFundsMax.value) {
    const amount = formatCurrency(purchasePriceMax)
    return $texts(
      'mortgage_validation_equity_max',
      'Die Eigenmittel können den Kaufpreis @amount nicht übersteigen.',
    ).replace('@amount', amount)
  }

  return ''
})

const incomeError = computed(() => {
  if (isNaN(income.value)) {
    return $texts(
      'mortgage_validation_invalid_value',
      'Bitte geben Sie einen gültigen Wert ein.',
    )
  } else if (income.value < incomeMin) {
    const amount = formatCurrency(incomeMin)
    return $texts(
      'mortgage_validation_earnings_min',
      'Das jährliche Nettoeinkommen muss mindestens @amount betragen.',
    ).replace('@amount', amount)
  }

  return ''
})

const isValid = computed(() => {
  return (
    !!purchasePrice.value &&
    !!ownFunds.value &&
    !!income.value &&
    !purchasePriceError.value &&
    !ownFundsError.value &&
    !incomeError.value
  )
})

const showResults = computed(() => {
  return (
    hasEnteredPurchasePrice.value &&
    hasEnteredOwnFunds.value &&
    hasEnteredIncome.value
  )
})

const state = computed(() => {
  return {
    hasEnteredPurchasePrice: hasEnteredPurchasePrice.value,
    hasEnteredOwnFunds: hasEnteredOwnFunds.value,
    hasEnteredIncome: hasEnteredIncome.value,
    purchasePrice: purchasePrice.value,
    ownFunds: ownFunds.value,
    income: income.value,
  }
})

type MortgageState = (typeof state)['value']

function restoreState() {
  const data = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!data) {
    return
  }

  const stateData: Partial<MortgageState> = JSON.parse(data)

  hasEnteredPurchasePrice.value = stateData.hasEnteredPurchasePrice || false
  hasEnteredOwnFunds.value = stateData.hasEnteredOwnFunds || false
  hasEnteredIncome.value = stateData.hasEnteredIncome || false
  purchasePrice.value = stateData.purchasePrice || 0
  ownFunds.value = stateData.ownFunds || 0
  income.value = stateData.income || 0
}

watch(state, (newState) => {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState))
  }, 1000)
})

watch(purchasePrice, () => {
  if (ownFunds.value < ownFundsMin.value) {
    ownFunds.value = ownFundsMin.value
    hasEnteredOwnFunds.value = true
  } else if (ownFunds.value > ownFundsMax.value) {
    ownFunds.value = ownFundsMax.value
    hasEnteredOwnFunds.value = true
  }
})

onMounted(() => {
  restoreState()
})

defineOptions({
  name: 'MortgageCalculator',
})
</script>
