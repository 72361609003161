<template>
  <div>
    <NuxtErrorBoundary>
      <AppHeader />
    </NuxtErrorBoundary>

    <div class="pt-header">
      <PageHeader :title="title" :lead="lead" />
    </div>

    <div class="layout-grid pb-112">
      <div class="col-start-2 col-end-[-2]">
        <div class="flex gap-24 flex-wrap">
          <NuxtLink class="button" to="/">
            <SpriteSymbol name="home" />
            Zur Startseite
          </NuxtLink>
          <NuxtLink class="button" to="/suche">
            <SpriteSymbol name="search" />
            Inhalte durchsuchen
          </NuxtLink>
        </div>
      </div>
    </div>

    <NuxtErrorBoundary>
      <AppFooter />
      <AppMobileBar />
      <ClientOnly>
        <AppFollowMenu />
      </ClientOnly>
    </NuxtErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
  error?: NuxtError
}>()

type ErrorData = {
  title: string
  lead?: string
}

function defineError(title: string, lead?: string): ErrorData {
  return {
    title,
    lead,
  }
}

const ERRORS: Record<number, ErrorData> = {
  400: defineError(
    'Ungültige Anfrage',
    'Die Anfrage konnte aufgrund fehlerhafter Syntax nicht verarbeitet werden.',
  ),
  403: defineError(
    'Zugriff verweigert',
    'Sie haben keine Berechtigung, auf diese Ressource zuzugreifen.',
  ),
  404: defineError(
    'Seite nicht gefunden',
    'Die gewünschte Seite wurde leider nicht gefunden. Unsere Suchfunktion kann Ihnen dabei helfen, die passende Seite zu finden.',
  ),
}

const code = computed(() => props.error?.statusCode || 500)

const errorData = computed<ErrorData | undefined>(() => {
  return ERRORS[code.value]
})

const title = computed(() => {
  return code.value + ': ' + (errorData.value?.title || 'Interner Serverfehler')
})

const lead = computed(() => {
  return errorData.value?.lead || ''
})
</script>
