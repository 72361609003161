<template>
  <a v-if="href && label" :href="href">
    <slot :label="label">
      {{ label }}
    </slot>
  </a>
</template>

<script lang="ts" setup>
import type { FieldItemTypeTelephoneFragment } from '#graphql-operations'

const props = defineProps<{
  first?: FieldItemTypeTelephoneFragment
}>()

const href = computed(() => props.first?.parsed?.url)
const label = computed(() => props.first?.parsed?.label)
</script>
